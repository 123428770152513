const USERPREFIX = '/donuser/';
const TEAMPREFIX = '/donprovider/';

// ** all  **// 
export const URL_LOG_IN = USERPREFIX+'api/loginUser';
export const URL_LOG_IN_GOOGLE_USER = USERPREFIX+'api/loginGoogleUser';

export const URL_IS_EMAIL_EXIST = USERPREFIX+'api/isEmailExist';
export const URL_PUBLIC_TENANT_TOKEN = '/donprovider/public/api/tenantToken';
export const URL_PUBLIC_CONFIGURATION = '/donprovider/public/api/configuration';


export const URL_REFRESH_TOKEN = USERPREFIX+'api/refreshToken';
export const URL_ADMIN_GET_USER = USERPREFIX+'api/getUser';
export const URL_SIGN_UP_USER = USERPREFIX+'api/createUser';
export const URL_SIGN_UP_GUEST = USERPREFIX+'api/createGuest';


export const URL_RESET_LINK = USERPREFIX+'api/resetLink';
export const URL_RESET_PASSWORD = USERPREFIX+'api/resetPassword';
export const URL_CONFIRM_EMAIL = USERPREFIX+'api/confirmEmail';
export const URL_CHANGE_PASSWORD = USERPREFIX+'api/changePassword';
export const URL_CHANGE_EMAIL = USERPREFIX+'api/changeEmail';
export const URL_USER_NAME = USERPREFIX+'api/name';
export const URL_USER_LINKED_ACCOUNTS = USERPREFIX+'api/linkedAccounts';
export const URL_USER_LINK_GOOGLE_USER = USERPREFIX+'api/linkGoogleUser';
export const URL_USER_UNLINK_GOOGLE = USERPREFIX+'api/unlink/google';
export const URL_CONFIRM_EMAIL_PASSWORD = USERPREFIX+'api/confirmEmailPassword';
export const URL_USER_STORE = USERPREFIX+'api/store';
export const URL_USER_LANGUAGE_PREF = USERPREFIX+'api/languagePref';
export const URL_USER_STOREPREF_LANGUAGEPREF = USERPREFIX+'api/storePrefLanguagePref';
export const URL_SECRETKEY_TOTP = USERPREFIX+'/api/secretKeyTOTP';
export const URL_VERIFY_TOTP = USERPREFIX+'/api/verifyTOTP';

export const URL_RESEND_CHANGE_EMAIL_TOKEN = USERPREFIX+'api/resendChangeEmailToken';



export const URL_CATEGORIES = USERPREFIX+'api/categories'; 
export const URL_CAMCAT = USERPREFIX+'api/camcat'; 
export const URL_CONTENT = USERPREFIX+'api/content'; 

export const URL_USER_PAYPAL_ONBOARD_LINK = USERPREFIX+'api/payPalOnboardLink';
export const URL_USER_PAYPAL_MERCHANTID = USERPREFIX+'api/paypal/merchantId';

export const URL_USER_STRIPE_ONBOARD_LINK = USERPREFIX+'api/stripe/onboardLink/user';
export const URL_USER_STRIPE_LOGIN_LINK = USERPREFIX+'api/stripe/loginLink/user';
export const URL_USER_STRIPE_ACCOUNT = USERPREFIX+'api/stripe/account/user';
export const URL_BUYER_STRIPE_PAYMENT_INTENT = USERPREFIX+'api/stripe/paymentIntent';
export const URL_BUYER_STRIPE_VERIFY_PAYMENT_INTENT = USERPREFIX+'api/stripe/verifyPaymentIntent';

export const URL_BUYER_STRIPE_DONATION_PAYMENT_INTENT = USERPREFIX+'api/stripe/donation/paymentIntent';
export const URL_BUYER_STRIPE_DONATION_VERIFY_PAYMENT_INTENT = USERPREFIX+'api/stripe/donation/verifyPaymentIntent';


export const URL_ORG_STRIPE_ONBOARD_LINK = USERPREFIX+'api/stripe/onboardLink/org';
export const URL_ORG_STRIPE_ACCOUNT = USERPREFIX+'api/stripe/account/org';
export const URL_ORG_STRIPE_LOGIN_LINK = USERPREFIX+'api/stripe/loginLink/org';



// **admin  **
export const URL_EMAILTEMPLATE = TEAMPREFIX+'api/emailTemplate';
export const URL_EXPORT_EMAIL_TEMPLATES = TEAMPREFIX+'api/exportEmailTemplates';
export const URL_IMPORT_EMAIL_TEMPLATES = TEAMPREFIX+'api/importEmailTemplates';
export const URL_TEAM_CAMCAT = TEAMPREFIX+'api/team/camcat';
export const URL_TEAM_CATEGORY = TEAMPREFIX+'api/team/category';
export const URL_TEAM_PARENTCATEGORY = TEAMPREFIX+'api/team/parentCategory';
export const URL_TEAM_CONTENT = TEAMPREFIX+'api/team/content';
export const URL_TEAM_CONTENT_CATEGORY = TEAMPREFIX+'api/team/content/category';
export const URL_TEAM_CONTENT_SECTION = TEAMPREFIX+'api/team/content/section';
export const URL_TEAM_CONTENT_VERSION = TEAMPREFIX+'api/team/content/version';
export const URL_PDFTEMPLATE = TEAMPREFIX+'api/pdfTemplate';
export const URL_PDFTEMPLATE_LETTER_HEAD = TEAMPREFIX+'api/pdfTemplate/letterHead';
export const URL_SMSTEMPLATE = TEAMPREFIX+'api/smsTemplate';
export const URL_EXPORT_SMS_TEMPLATES = TEAMPREFIX+'api/exportSMSTemplates';
export const URL_IMPORT_SMS_TEMPLATES = TEAMPREFIX+'api/importSMSTemplates';
export const URL_EXPORT_PDF_TEMPLATES = TEAMPREFIX+'api/exportPDFTemplates';
export const URL_IMPORT_PDF_TEMPLATES = TEAMPREFIX+'api/importPDFTemplates';
export const URL_IMPORT_CSV_CHARITY_IDENTIFICATION = TEAMPREFIX+'api/charity/importIdentification';
export const URL_IMPORT_CSV_CHARITY_FINANCIAL = TEAMPREFIX+'api/charity/importFinancial';
export const URL_IMPORT_CSV_CHARITY_PROGRAM = TEAMPREFIX+'api/charity/importProgram';
export const URL_CHARITY_SEARCH =TEAMPREFIX+'api/charity/search';
export const URL_CHARITY_OVERRIDE_SEARCHABLE =TEAMPREFIX+'api/charity/overrideSearchable';
export const URL_CHARITY_CREATE_DEFAULT_CAMPAIGNS =TEAMPREFIX+'api/charity/createDefaultCampaigns';
export const URL_USER_SEARCH =TEAMPREFIX+'api/user/search';
export const URL_USER_UPDATE =TEAMPREFIX+'api/user/update';
export const URL_ADMINUSER_SWITCHUSER =TEAMPREFIX+'api/adminUser/switchUser';
export const URL_ADMINUSER_SWITCHBACKUSER =TEAMPREFIX+'api/adminUser/switchBackUser';
export const URL_ADMINUSER_UPDATEROLE =TEAMPREFIX+'api/adminUser/updateRole';
export const URL_ADMIN_CAMPAIGN_SEARCH =TEAMPREFIX+'api/campaign/search';
export const URL_ADMIN_CAMPAIGN_SWITCH_TO_OWNER =TEAMPREFIX+'api/campaign/switchToOwner';
export const URL_ADMIN_ORG_SEARCH =TEAMPREFIX+'api/org/search';
export const URL_ADMIN_ORG_SWITCH_TO_OWNER =TEAMPREFIX+'api/org/switchToOwner';


export const URL_ADMIN_ITEM_SEARCH =TEAMPREFIX+'api/item/search';
export const URL_ADMIN_ITEM_SWITCH_TO_OWNER =TEAMPREFIX+'api/item/switchToOwner';

export const URL_ADMIN_ORDER_SEARCH =TEAMPREFIX+'api/order/search';
export const URL_ADMIN_ORDER_SWITCH_TO_OWNER =TEAMPREFIX+'api/order/switchToOwner';
export const URL_ADMIN_ORDER_STRIPE_REFUND =USERPREFIX+'api/stripe/refund';


export const URL_ADMIN_CAMPAIGN_REVIEWSTATUS =USERPREFIX+'api/raiser/campaign/reviewStatus';
export const URL_ADMIN_CAMPAIGN_REVIEWREQUIREMENT =USERPREFIX+'api/raiser/campaign/reviewRequirement';
export const URL_ADMIN_ITEM_REVIEWSTATUS =USERPREFIX+'api/seller/item/reviewStatus';
export const URL_ADMIN_ITEM_REVIEWREQUIREMENT =USERPREFIX+'api/seller/item/reviewRequirement';
export const URL_ADMIN_ORG_REVIEWSTATUS =USERPREFIX+'api/org/reviewStatus';
export const URL_ADMIN_ORG_REVIEWREQUIREMENT =USERPREFIX+'api/org/reviewRequirement';
export const URL_ADMIN_ORG_REGISTERED_CHARITY =USERPREFIX+'api/org/registeredCharity';
export const URL_ADMIN_NOTE =USERPREFIX+'api/note';


// raiser
export const URL_RAISER_CAMPAIGNS =USERPREFIX+'api/raiser/campaigns';
//export const URL_RAISER_POPULAR_CAMPAIGNS =USERPREFIX+'api/raiser/campaigns/popular';
export const URL_RAISER_CAMPAIGN =USERPREFIX+'api/raiser/campaign';
export const URL_RAISER_CAMPAIGN_BENEFICIARY =USERPREFIX+'api/raiser/campaign/beneficiary';
export const URL_RAISER_CAMPAIGN_BENEFICIARY_USER_ID =USERPREFIX+'api/raiser/campaign/beneficiaryUserId';
export const URL_RAISER_MYCAMPAIGNS =USERPREFIX+'api/raiser/myCampaigns';
export const URL_RAISER_MYBENEFICIARY_CAMPAIGNS =USERPREFIX+'api/raiser/myBeneficiaryCampaigns';


export const URL_RAISER_CAMPAIGN_PHOTO =USERPREFIX+'api/raiser/campaign/photo';
export const URL_RAISER_CAMPAIGN_STORY_UPDATE =USERPREFIX+'api/raiser/campaign/storyUpdate';
export const URL_RAISER_CAMPAIGN_STORY_UPDATES =USERPREFIX+'api/raiser/campaign/storyUpdates';
export const URL_RAISER_UPLOAD =USERPREFIX+'api/raiser/upload';
export const URL_RAISER_PAYPAL_ONBOARD_LINK = USERPREFIX+'api/raiser/payPalOnboardLink';
export const URL_RAISER_PAYPAL_MERCHANTID = USERPREFIX+'api/raiser/paypal/merchantId';
export const URL_RAISER_SETTINGS =USERPREFIX+'api/raiser/settings';
export const URL_RAISER_CAMPAIGN_USER =USERPREFIX+'api/raiser/campaign/user';

export const URL_RAISER_CAMPAIGN_INVITATION =USERPREFIX+'api/raiser/campaign/invitation';
export const URL_RAISER_INVITED = USERPREFIX+'api/raiser/campaign/invited';
export const URL_RAISER_CAMPAIGN_BENEFICIARY_INVITED = USERPREFIX+'api/raiser/campaign/beneficiary/invited';
export const URL_RAISER_CAMPAIGN_USER_STATUS = USERPREFIX+'api/raiser/campaign/user/status';
export const URL_RAISER_CAMPAIGN_VIEW = USERPREFIX+'api/raiser/campaign/view';
export const URL_RAISER_CAMPAIGN_BENEFICIARY_STATUS = USERPREFIX+'api/raiser/campaign/beneficiary/status';
export const URL_RAISER_ORG_CAMPAIGN_BENEFICIARY_STATUS = USERPREFIX+'api/raiser/org/campaign/beneficiary/status';
export const URL_RAISER_LANGUAGE_PREF = USERPREFIX+'api/raiser/languagePref';
export const URL_RAISER_CAMPAIGN_TRANSLATE = USERPREFIX+'api/raiser/campaign/translate';

export const URL_BUYER_SEARCH_CHARITY = USERPREFIX+'api/buyer/charity/search';
export const URL_BUYER_CHARITY = USERPREFIX+'api/buyer/charity';
export const URL_BUYER_SEARCH_TEXT_CAMPAIGN = USERPREFIX+'api/buyer/campaign/textSearch';


//seller

export const URL_SELLER_PREFERENCES = USERPREFIX+'api/seller/preferences';
export const URL_SELLER_ITEM = USERPREFIX+'api/seller/item';
export const URL_SELLER_ITEM_OPTION = USERPREFIX+'api/seller/item/option';
export const URL_SELLER_ITEMS =USERPREFIX+'api/seller/items';
export const URL_SELLER_UPLOAD =USERPREFIX+'api/seller/upload';
export const URL_SELLER_UPLOAD_FILE =USERPREFIX+'api/seller/upload/file';
export const URL_SELLER_ITEM_PHOTO =USERPREFIX+'api/seller/item/photo';
export const URL_SELLER_ITEM_FILE =USERPREFIX+'api/seller/item/file';
export const URL_SELLER_ORGS = USERPREFIX+'api/seller/orgs';
export const URL_SELLER_FILE_SIGNEDURL = USERPREFIX+'api/seller/file/signedURL';
export const URL_SELLER_ITEM_STORE = USERPREFIX+'api/seller/itemStore';
export const URL_SELLER_ITEM_AUTOTRANSLATE = USERPREFIX+'api/seller/item/translate';
export const URL_SELLER_ITEM_TRANSLATE = USERPREFIX+'api/seller/item/translate';
export const URL_SELLER_VOUCHER = USERPREFIX+'api/seller/voucher';



//exchangeRate
export const URL_EXCHANGE_RATE = USERPREFIX+'api/xr/rate';
export const URL_EXCHANGE_QUOTERATE = USERPREFIX+'api/xr/quoteRate';

// buyer
export const URL_BUYER_ITEMS =USERPREFIX+'api/buyer/items';
export const URL_BUYER_POPULAR_ITEMS =USERPREFIX+'api/buyer/items/popular';
export const URL_BUYER_CATEGORIES =USERPREFIX+'api/buyer/categories';
export const URL_BUYER_CART_ITEM = USERPREFIX+'api/buyer/cart/item';
export const URL_BUYER_CART_CAMPAIGN = USERPREFIX+'api/buyer/cart/campaign';
export const URL_BUYER_CART_CHARITY = USERPREFIX+'api/buyer/cart/charity';
export const URL_BUYER_CART_COUPON = USERPREFIX+'api/buyer/cart/coupon';
export const URL_BUYER_CART_ITEM_QUANTITY = USERPREFIX+'api/buyer/cart/item/quantity';
export const URL_BUYER_CART = USERPREFIX+'api/buyer/cart';
export const URL_BUYER_ORDER = USERPREFIX+'api/buyer/order';
export const URL_BUYER_PAYPAL_ORDER_CAPTURE = USERPREFIX+'api/buyer/order/capture';
export const URL_BUYER_PAYPAL_ORDER_CREATE = USERPREFIX+'api/buyer/order/create';
export const URL_BUYER_PAYPAL_CART_CREATE_ORDER = USERPREFIX+'api/buyer/cart/createOrder';
export const URL_BUYER_BillING_ADDRESS =USERPREFIX+'api/buyer/billingAddress';
export const URL_MOBILE =USERPREFIX+'api/sms/mobile';
export const URL_2FAINFO =USERPREFIX+'api/2FAInfo';
export const URL_CART_BillING_INFORMATION =USERPREFIX+'api/buyer/cart/billingInformation';
export const URL_BUYER_DELIVERY_ADDRESS =USERPREFIX+'api/buyer/deliveryAddress';
export const URL_BUYER_ORGS = USERPREFIX+'api/buyer/orgs';
export const URL_BUYER_CART_DONATION = USERPREFIX+'api/buyer/cart/donation';
export const URL_BUYER_ORDER_DETAIL = USERPREFIX+'api/buyer/order/detail';
export const URL_BUYER_ORDER_GUEST = USERPREFIX+'api/buyer/order/guest';
export const URL_BUYER_ORDER_REVIEW = USERPREFIX+'api/buyer/order/review';
export const URL_BUYER_ORDER_REVIEW_DONATION = USERPREFIX+'api/buyer/order/review/donation';
export const URL_BUYER_PAYPAL_ORDER_CAPTURE_REVIEW = USERPREFIX+'api/buyer/order/guest/captureReview';
export const URL_BUYER_ORDERS = USERPREFIX+'api/buyer/orders';
export const URL_BUYER_ORDER_FILES = USERPREFIX+'api/buyer/order/files';
export const URL_BUYER_ORDER_VOUCHER = USERPREFIX+'api/buyer/voucher';
export const URL_BUYER_ORDER_RECEIPT = USERPREFIX+'api/buyer/receipt';
export const URL_BUYER_ORDER_TAX_RECEIPT = USERPREFIX+'api/buyer/taxReceipt';

export const URL_BUYER_PAYPAL_CLIENT_TOKEN = USERPREFIX+'api/buyer/paypal/clientToken';
export const URL_BUYER_PAYPAL_MERCHANT_IDS = USERPREFIX+'api/buyer/paypal/merchantIds';
export const URL_BUYER_PAYPAL_CAMPAIGN_MERCHANT_IDS = USERPREFIX+'api/buyer/paypal/campaign/merchantIds';
export const URL_BUYER_PAYPAL_CART_MERCHANT_IDS = USERPREFIX+'api/buyer/paypal/cart/merchantIds';
export const URL_BUYER_CAMPAIGN =USERPREFIX+'api/buyer/campaign';
export const URL_BUYER_ORG =USERPREFIX+'api/buyer/org';
export const URL_BUYER_ORG_ITEMS =USERPREFIX+'api/buyer/org/items';
export const URL_BUYER_CAMPAIGN_SHORTSUPPORT =USERPREFIX+'api/buyer/campaign/shortSupport';
export const URL_BUYER_CAMPAIGN_FULLSUPPORT =USERPREFIX+'api/buyer/campaign/fullSupport';
export const URL_BUYER_CAMPAIGN_TEAM =USERPREFIX+'api/buyer/campaign/team';
export const URL_BUYER_CAMPAIGN_SHORTDONATIONLIST =USERPREFIX+'api/buyer/campaign/shortDonationList';
export const URL_BUYER_CAMPAIGN_FULL_DONATOR =USERPREFIX+'api/buyer/campaign/fullDonator';
export const URL_BUYER_CAMPAIGN_STORY_UPDATES =USERPREFIX+'api/buyer/campaign/storyUpdates';
export const URL_BUYER_POPULAR_CAMPAIGNS =USERPREFIX+'api/buyer/campaigns/popular';
export const URL_BUYER_SEARCH_CAMPAIGNS =USERPREFIX+'api/buyer/campaigns/search';
export const URL_BUYER_POPULAR_ORG =USERPREFIX+'api/buyer/org/popular';
export const URL_BUYER_SEARCH_ORG =USERPREFIX+'api/buyer/org/search';
export const URL_BUYER_SUPPORT_MESSAGE =USERPREFIX+'api/buyer/supportMessage';
export const URL_BUYER_CHECKOUT_DONATION = USERPREFIX+'api/buyer/donation/checkout';
export const URL_BUYER_PAYPAL_CREATE_ORDER_GUEST = USERPREFIX+'api/buyer/paypal/createOrderGuest';
export const URL_BUYER_ADD_ORDER_TO_USER =USERPREFIX+'api/buyer/addOrderToUser';

export const URL_BUYER_PAYPAL_DONATION_ORDER_CAPTURE = USERPREFIX+'api/buyer/order/donation/capture';
export const URL_BUYER_PAYPAL_ORDER_GUEST_CAPTURE = USERPREFIX+'api/buyer/order/guest/capture';
export const URL_BUYER_PAYPAL_CALCULATE_TAX = USERPREFIX+'api/buyer/order/guest/calculateTax';
export const URL_BUYER_PAYPAL_ORDER_CANCEL_CAPTURE = USERPREFIX+'api/buyer/order/capture/cancel';
export const URL_BUYER_ORG_CAMPAIGN =USERPREFIX+'api/buyer/org/campaign';

export const URL_BUYER_CREATE_NO_PAYMENT_ORDER = USERPREFIX+'api/buyer/order/noPayment';
export const URL_BUYER_CREATE_DONATION_ORDER = USERPREFIX+'api/buyer/order/donation';

export const URL_VERIFY_MOBILE_NUMBER = USERPREFIX+'api/sms/verifyNumber';
export const URL_SMS_RESEND_OTP = USERPREFIX+'api/sms/resendOTP';
export const URL_VERIFY_OTP = USERPREFIX+'api/sms/verifyOTP';
export const URL_VERIFY_EMAIL_OTP = USERPREFIX+'api/email/verifyOTP';

export const URL_PAYPAL_CHARITY_CHECKOUT = USERPREFIX+'api/paypal/charity/checkout';
export const URL_PAYPAL_CHARITY_CHECKOUT_EXISTING_ORDER = USERPREFIX+'api/paypal/charity/checkout/order';
export const URL_PAYPAL_CHARITY_CLIENTID = USERPREFIX+'api/paypal/charity/clientId';
export const URL_PAYPAL_CHARITY_CAPTURE = USERPREFIX+'api/paypal/charity/capture';
export const URL_PAYPAL_CHARITY_CREATE_ORDER_GUEST = USERPREFIX+'api/paypal/charity/createOrderGuest';
export const URL_PAYPAL_CHARITY_CAPTURE_GUEST = USERPREFIX+'api/paypal/charity/capture/guest';
export const URL_PAYPAL_CHARITY_CANCEL = USERPREFIX+'api/paypal/charity/cancel';


// **org  **
export const URL_ORG_SAVE_MSG = USERPREFIX+'api/org/saveMsg';
export const URL_ORG_MSG = USERPREFIX+'api/org/msg';
export const URL_ORG_PREFERENCES = USERPREFIX+'api/org/preferences';
export const URL_ORG_PAYPAL_ONBOARD_LINK = USERPREFIX+'api/org/payPalOnboardLink';
export const URL_ORG_PAYPAL_MERCHANTID = USERPREFIX+'api/org/paypal/merchantId';
export const URL_ORG = USERPREFIX+'api/org/createUpdate';
export const URL_ORG_LIST = USERPREFIX+'api/org/list';
export const URL_ORG_VIEW = USERPREFIX+'api/org/view';
export const URL_ORG_UPLOAD =USERPREFIX+'api/org/upload';
export const URL_ORG_PHOTO =USERPREFIX+'api/org/photo';
export const URL_ORG_CAMPAIGNS =USERPREFIX+'api/org/campaigns';
export const URL_ORG_USER =USERPREFIX+'api/org/user';
export const URL_ORG_INVITED = USERPREFIX+'api/org/invited';
export const URL_ORG_USER_STATUS = USERPREFIX+'api/org/user/status';
export const URL_ORG_LANGUAGE_PREF = USERPREFIX+'api/org/languagePref';
export const URL_ORG_TRANSLATE = USERPREFIX+'api/org/translate';
export const URL_ORG_DELETE = USERPREFIX+'api/org/delete';
export const URL_ORG_CONTACT = USERPREFIX+'api/org/contact';
export const URL_ORG_ORDER_SEARCH =USERPREFIX+'api/org/order/search';
export const URL_ORG_UPLOAD_DONATION_RECEIPT =USERPREFIX+'api/org/upload/donationReceipt';
export const URL_ORG_CAMPAIGN_BENEFICIARY_INVITED = USERPREFIX+'api/org/campaign/beneficiary/invited';
export const URL_ORG_CAMPAIGN_BENEFICIARY_STATUS = USERPREFIX+'api/org/campaign/beneficiary/status';