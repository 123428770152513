<div class="grid  grid-nogutter overlay" *ngIf="showBanner">
    <div class="banner grid-nogutter">
  
  
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12  innerbanner">
        <br /><br />
        <p>
          <ng-container *transloco="let t;">{{t('byClickingAccept')}}</ng-container>
        </p>
  
        <br />
  
      
  
      </div>
      <div class="col-12 innerbutton">
  
        <button pButton type="button" (click)="letmeChoose()" [label]="'noLetMeChose' | transloco | uppercase " class="sameHeight"></button>
        &nbsp;
  
        <button pButton type="button" (click)="acceptAllCookies()" class="sameHeight"
          [label]="'acceptAllCookies' | transloco | uppercase"></button>
        <br /><br /> <br />
      </div>
    </div>
  </div>
  
  
  
  
  <p-dialog [header]="'cookieSettings' | transloco" [modal]="true" [closable]="false" closeIcon=""
    [(visible)]="cookiePreferenceDialog" [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden', 'max-width': '650px',
           'max-height': '80vh'}">
  
  
    <div class="grid">
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
        <ng-container *transloco="let t;">{{t('whenYouVisitAnySite')}}</ng-container>
      </div>
  
    </div>
    <div class="grid">
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">&nbsp;</div>
    </div>
    <div class="grid">
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">&nbsp;</div>
    </div>
  
    <div class="grid">
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">&nbsp;</div>
    </div>
    <div class="grid">
      <div class="col-6 sm:col-6 md:col-6 lg:col-6 xl:col-6 bold-text">
        <ng-container *transloco="let t;">{{t('functionalCookies')}}</ng-container>
      </div>
      <div class="col-6 sm:col-6 md:col-6 lg:col-6 xl:col-6 right-align">
        <p-inputSwitch [(ngModel)]="functionalCookies" (onChange)="updateFunctionalCookie()"></p-inputSwitch>
      </div>
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
        <ng-container *transloco="let t;">{{t('functionCookiesDescription')}}</ng-container>
  
  
      </div>
    </div>
    <div class="grid">
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">&nbsp;</div>
    </div>
    <div class="grid">
      <div class="col-6 sm:col-6 md:col-6 lg:col-6 xl:col-6 bold-text">
        <ng-container *transloco="let t;">{{t('AnalyticsCookies')}}</ng-container>
      </div>
      <div class="col-6 sm:col-6 md:col-6 lg:col-6 xl:col-6 right-align">
        <p-inputSwitch [(ngModel)]="analyticsCookies" (onChange)="updateAnalyticsCookie()"></p-inputSwitch>
      </div>
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
        <ng-container *transloco="let t;">{{t('AnalyticalCookiesTrack')}}</ng-container><br><br>
        <ng-container *transloco="let t;">{{t('googleAnalyticsAreClassified')}}</ng-container>
        <a href="https://support.google.com/analytics/answer/6004245"><ng-container
            *transloco="let t;">{{t('googleAnalyticsPrivacyPolicy')}}</ng-container></a>.
        <br><br>
        <ng-container *transloco="let t;">{{t('forInformationOnHowGoogleUsesDataFromCookies')}}</ng-container>
        <a href="http://www.google.com/policies/privacy/partners/"><ng-container
            *transloco="let t;">{{t('googlePrivacyPolicy')}}</ng-container></a>.
        <br /><br />
  
  
        We use Hotjar cookies. Hotjar technology may record sessions of how much time you spend on certain pages; which
        links you choose to click; where you click, scroll your mouse, and move in between pages; what you do and don’t
        like; and the like.
        For more information, please visit <a [routerLink]="[]" (click)="openPrivacy()">our Privacy Policy</a>.
  
  
      </div>
  
    </div>
    <div class="grid">
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">&nbsp;</div>
    </div>
    <div class="grid">
      <div class="col-6 sm:col-6 md:col-6 lg:col-6 xl:col-6 bold-text">
  
        <ng-container *transloco="let t;">{{t('essentialCookies')}}</ng-container>
      </div>
      <div class="col-6 sm:col-6 md:col-6 lg:col-6 xl:col-6 right-align">
        <i>
          <ng-container *transloco="let t;">{{t('alwaysActive')}}</ng-container>
        </i>
      </div>
      <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
        <ng-container *transloco="let t;">{{t('thereAreCookiesWhichAreStrictlyNecessary')}}</ng-container>
      </div>
    </div>
  
    <br><br>
  
  
  
    <ng-template pTemplate="footer">

      <div class="grid">
        <div class="col-6 text-left ">
          <button type="button" pButton (click)="close()" [label]="'acceptSelection' | transloco | uppercase" class="sameHeight"></button>
        </div>
        <div class="col-6 text-right" >
          <button pButton type="button" (click)="acceptAllCookies()" class="sameHeight"
            [label]="'acceptAllCookies' | transloco | uppercase"></button>
        </div>
  
      </div>
    </ng-template>
  </p-dialog>