import { AddressModel, BuyerDownloadOrderFileModel, BuyerItemModel, BuyerItemOptionModel, BuyerViewCartModel, BuyerViewOrderModel, BuyerViewOrgModel, BuyerViewPublicFileModel, CamcatModel, CampaignInvitedModel, CampaignUpdateModel, CampaignUserModel, CharityRaiserModel, DonateBillingInfoModel, EndDateModel, FileModel, FileTranslationModel, GeoLocationModel, ItemModel, LangDetailModel, LangModel, ListBoxModel, LoginUserModel, NavigatorShareModel, NewLangModel, OrgContactModel, OrgFileModel, OrgInvitationTokenModel, OrgInvitedModel, OrgListModel, OrgModel, OrgUserModel, RaiserCampaignModel, RaiserFileModel, ReportOrderModel, SellerFileModel, SellerItemModel, SellerNewItemModel, SellerViewVoucherModel, StoryUpdateTranslationModel, TokenModel, ViewCampaignModel, ViewFileModel, ViewOptionInventoryModel, ViewOrgModel } from "./model/shared.model";

export class Globals {


  static readonly MSG_SEVERITY_SUCCESS = 'success';
  static readonly MSG_SEVERITY_INFO = 'info';
  static readonly MSG_SEVERITY_WARN = 'warn';
  static readonly MSG_SEVERITY_ERROR = 'error';

  static readonly EMAIL_TEMPLATE_NEW_ACCOUNT = 'newAccount';
  static readonly EMAIL_TEMPLATE_CONFIRM_EMAIL = 'confirmEmail';
  static readonly EMAIL_TEMPLATE_RESET_LINK = 'resetLink';
  static readonly EMAIL_TEMPLATE_CHANGE_EMAIL_ALERT = 'changeEmailAlert';
  static readonly EMAIL_TEMPLATE_CONFIRM_CHANGE_EMAIL = 'confirmChangeEmail';
  static readonly EMAIL_TEMPLATE_UNLINK_GOOGLE = 'unlinkGoogle'; // verified email at the same time
  static readonly EMAIL_TEMPLATE_JOIN_ORG_INVITATION = 'joinOrgInvitation';
  static readonly EMAIL_TEMPLATE_JOIN_CAMPAIGN_INVITATION = 'joinCampaignInvitation';
  static readonly EMAIL_TEMPLATE_ORDER_CONFIRMATION = 'orderConfirmation';


  static readonly PDF_TEMPLATE_VOUCHER = 'voucher';
  static readonly PDF_TEMPLATE_DONATION_RECEIPT = 'donationReceipt';
  static readonly PDF_TEMPLATE_FOUNDATION_RECEIPT = 'foundationReceipt';
  static readonly PDF_TEMPLATE_FOUNDATION_TAX_RECEIPT = 'foundationTaxReceipt';
  

  static readonly SMS_TEMPLATE_OTP = 'otp';
  


  static readonly ORG_MSG_ABOUT = 'about';
  static readonly DEFAULT_NO_IMAGEOLD='assets/images/noimage.svg';
  static readonly DEFAULT_NO_IMAGE='assets/images/charity_generic.svg';
  
  static config =
    {
      'defaultLang': 'en',
      'defaultCurrency': 'CAD',
      'defaultRegion': 'CA',
      'availableLangs': ['en'],
      'gtmContainerId': '',
      'googleTagManagerAuth': '',
      'googleTagManagerPreview': '',
      'reCAPTCHASiteKey': '',
      'reCAPTCHAActive': false,
      'reCAPTCHARequired': false,
      'paypalClientId': '',
      'environmentTest': false,
      'paypalOption':false,
      'stripeOption':true,
    }

  static featureFlags= {
    personalBankProvider: true,
    orgBankProvider: true,
    charityOnly: false, 
  };

  static functionalCookies = true;
  static analyticsCookies = false;
  static hotjarLoaded = false;
  static domainAPI = '';
  static websiteURL='';
  static site='';

  static location = '';
  static publicToken = '';
  static tenantId = '';


  static loggedUser: LoginUserModel;
  //static selectedLanguage = 'en';

  static webSiteLang = 'en';
  static webSiteLangModel: keyof LangModel = 'en';

  static currency = 'CAD';
  static region = 'CA';
  static xrates: any = {};

  static getEmptyUser(): LoginUserModel {
    return {
      userId: '',
      lastName: '',
      firstName: '',
      email: '',
      tenantId: '',
      accessToken: '',
      refreshToken: '',
      pictureURL: '',
      roles: [],
      lang:'',
      deviceToken:'',
      mobileVerified: false

    };
  }


  static getEmptyBuyerViewPublicFile(): BuyerViewPublicFileModel {
    return {

      link: 'assets/images/imgnotfound.svg'

    };
  }


  static getEmptyViewCampaign(): ViewCampaignModel {
    return {

      campaignId: '',
      cuid: '',
      title: '',
      summary: '',
      story: '',
      ownerName: '',
      beneficiaryName: '',
      currency: '',
      goal: 0,
      raised: 0,
      donation: 0,
      photo: this.getEmptyViewSellerFile(),
      support: [],
      donationList: [],
      updates: [],
      nbUpdates: 0,
      beneficiaryType: '',
      beneficiaryProcessor:'',
      beneficiaryCharityId:'',
      beneficiaryOrgId:''

    };
  }

  static getEmptyNavigatorShare(): NavigatorShareModel {
    return {
      title: '',
      url: '',
      text: '',

    };
  }


  static getEmptyListBox(): ListBoxModel {
    return {

      name: '',
      code: '',

    };
  }

  static getEmptyViewFile(): ViewFileModel {
    return {

      description: '',
      fileName: '',
      objectName: '',
      mode: ''


    };
  }

  static getEmptyViewOption(): ViewOptionInventoryModel {
    return {

      title: '',
      regularPrice: 0,
      regularPrices: [],
      discountedPrice:0,
      mode: '',
      optionId: '',
      discountPercent: 0,
      unlMonCap:false,
      unlQty:false,
      quantity:0,
      sold:0,
      monthlyCap:0,
      inventoryId:'',
      

    };
  }


  static getEmptyFileTranslation(): FileTranslationModel {
    return {

      description: '',
      fileName: '',
      objectName: '',
      mode: '',
      autoDescription: '',
      origMode: '',
      origDescription: ''


    };
  }

  static getEmptyStoryUpdateTranslation(): StoryUpdateTranslationModel {
    return {
      campaignUpdateId: '',
      storyUpdate: '',
      mode: '',
      autoStoryUpdate: '',
      origMode: '',
      origStoryUpdate: ''


    };
  }

  static getEmptyCamcat(): CamcatModel {
    return {

      title: this.getEmptyLang(),
      order: 0,
      camcatId: ''

    };
  }

  static getEmptyRaiserFile(): RaiserFileModel {
    return {

      description: this.getEmptyLang(),
      fileName: '',
      objectName: '',
      link: '',
      signedURL: ''


    };
  }

  static getEmptyOrgFile(): OrgFileModel {
    return {

      description: this.getEmptyNewLang(),
      fileName: '',
      objectName: '',
      link: '',
      signedURL: ''


    };
  }

  static getEmptySellerFile(): SellerFileModel {
    return {

      description: this.getEmptyLang(),
      fileName: '',
      objectName: '',
      //link: 'assets/images/imgnotfound.svg',
      link: Globals.DEFAULT_NO_IMAGE,
      signedURL: ''
    };
  }

  static getEmptyCampaignFile(): SellerFileModel {
    return {

      description: this.getEmptyLang(),
      fileName: '',
      objectName: '',
      link: Globals.DEFAULT_NO_IMAGE,
      signedURL: ''
    };
  }

  static getEmptyFile(): FileModel {
    return {

      description: this.getEmptyNewLang(),
      fileName: '',
      objectName: '',
      //link: 'assets/images/imgnotfound.svg',
      link: Globals.DEFAULT_NO_IMAGE,
      signedURL: ''


    };
  }

  static getEmptyViewSellerFile(): SellerFileModel {
    return {

      description: this.getEmptyLang(),
      fileName: '',
      objectName: '',
      link: '',
      signedURL: ''


    };
  }



  static getEmptyCampaignUpdate(): CampaignUpdateModel {
    return {
      campaignUpdateId: '',
      campaignId: '',
      storyUpdate: this.getEmptyNewLang(),
      createdAt: 0,
      modifiedAt: 0
    }

  }
  static getEmptyRaiserCampaign(): RaiserCampaignModel {
    return {
      campaignId: '',
      cuid: '',
      title: this.getEmptyNewLang(),
      summary: this.getEmptyNewLang(),
      story: this.getEmptyNewLang(),
      goal: 0,
      currency: '',
      ownerName: '',
      ownerOrgId: '',
      ownerUserId: '',
      ownerType: '',
      beneficiaryType: '',
      beneficiaryName: '',
      beneficiaryStatus: '',
      beneficiaryEmail: '',
      beneficiaryRole: '',
      beneficiaryId: '',
      beneficiaryOrgId: '',
      beneficiaryCharityId: '',
      registeredCharity: false,
      beneficiaryUserId: '',
      camcat: this.getEmptyLang(),
      camcatId: '',
      endDateAt: 0,
      endDate: this.getEmptyEndDate(),
      photo: this.getEmptyRaiserFile(),
      updates: [],
      nbUpdates: 0,
      beneficiaryAnOrgUser: false,
      status: '',
      pendingReasons:[],
      reviewRequirement: '',
      reviewStatus:''
    };
  }


  static getEmptyOrg(): OrgModel {
    return {
      orgId: '',
      orgName: this.getEmptyNewLang(),
      summary: this.getEmptyNewLang(),
      mission: this.getEmptyNewLang(),
      currency: '',
      orgEmail: '',
      mainAddress: this.getEmptyAddress(),
      photo: this.getEmptyOrgFile(),
      ouid: '',
      status:'',
      charityBN:'',
      orgContact: this.getEmptyOrgContact(),
      pendingReasons:[],
      reviewRequirement: '',
      reviewStatus:'',
      orgEmitDonationTaxReceipt: false,
      registeredCharity: false,

    };
  }

  static getEmptyOrgContact(): OrgContactModel {
    return {
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      phone: '',

    };
  }

  static getEmptyOrgList(): OrgListModel {
    return {
      role: '',
      org: this.getEmptyOrg(),

    };
  }

  static getEmptyOrgInvited(): OrgInvitedModel {
    return {
      orgId: '',
      orgName: this.getEmptyLang(),
      summary: this.getEmptyLang(),
      mission: this.getEmptyLang(),
      photo: this.getEmptyOrgFile(),
      ouid: '',
      firstName: '',
      lastName: ''

    };
  }

  static getEmptyCampaignInvited(): CampaignInvitedModel {
    return {
      campaignId: '',
      title: this.getEmptyLang(),
      summary: this.getEmptyLang(),
      story: this.getEmptyLang(),
      photo: this.getEmptyOrgFile(),
      cuid: '',
      firstName: '',
      lastName: ''
      

    };
  }


  static getEmptyViewOrg(): ViewOrgModel {
    return {
      orgId: '',
      orgName: this.getEmptyLang(),
      summary: this.getEmptyLang(),
      mission: this.getEmptyLang(),
      currency: '',
      orgEmail: '',
      mainAddress: this.getEmptyAddress(),
      photo: this.getEmptyOrgFile(),
      raised: 0,
      ouid: '',
      registeredCharity: false,
      charityBN: ''

    };
  }

  
  static getEmptyGeoLocation(): GeoLocationModel {
    return {
    
      longitude: 0,
      latitude:0,
      placeId:''

    };
  }


  static getEmptyBuyerViewOrg(): BuyerViewOrgModel {
    return {
      orgId: '',
      orgName: '',
      summary:'',
      mission: '',
      currency: '',
      orgEmail: '',
      mainAddress: this.getEmptyAddress(),
      geoLocation: this.getEmptyGeoLocation(),
      photo: this.getEmptyOrgFile(),
      raised: 0,
      ouid: ''

    };
  }

  

  static getEmptyDonateBillingInfo():  DonateBillingInfoModel {
    return {
      firstName: '',
      lastName: '',
      email: '',
      address:  this.getEmptyAddress(),
      

    };
  }


  static getEmptyAddress(): AddressModel {
    return {
      address: '',
      addressL2: '',
      city: '',
      state: '',
      zip: '',
      country: ''

    };
  }
  static getEmptySellerItem(): SellerItemModel {
    return {
      itemId: '',
      title: this.getEmptyNewLang(),
      description: this.getEmptyNewLang(),
      price: 0,
      currency: '',
      mainPhoto: this.getEmptySellerFile(),
      photos: [],
      categoryIds: [],
      toOrgId: '',
      toOrgPercent: 0,
      freeable: false,
      taxable: false,
      files: [],
      options: [],
      inventory:[],
      forType: '',
      forCampaignIds: [],
      forCharityIds:[],
      forOrgIds: [],
      ownerOrgId: '',
      ownerUserId: '',
      ownerType: '',
      ownerName: '',
      prices: {},
      status: 'draft',
      nature:'',
      pendingReasons:[],
      reviewRequirement: '',
      reviewStatus:''      

    };
  }
  static getEmptySellerNewItem(): SellerNewItemModel {
    return {
      itemId: '',
      title: '',
      description: '',
      price: 0,
      mainPhoto: this.getEmptySellerFile(),
      photos: [],
      categoryIds: [],
      toOrgId: '',
      toOrgPercent: 0,
      freeable: false,
      files: []

    };
  }

  static getEmptySelleVoucher(): SellerViewVoucherModel {
    return {
      title: '',
      optionTitle: '',
      quantity: 0,
      code: '',
      mainPhoto: '',
      createdAt: 0,
      orgName: '',
      address: this.getEmptyAddress(),
      redeemAt: 0,
      redeemBy: '',
      status:''
    }

  }

  static getEmptyItem(): ItemModel {
    return {
      itemId: '',
      title: this.getEmptyLang(),
      description: this.getEmptyLang(),
      price: 0,
      mainPhoto: this.getEmptyBuyerViewPublicFile(),
      photos: [],
      categoryIds: [],
      toOrgId: '',
      toOrgPercent: 0,
      freeable: false,
      files: []

    };
  }


  static getEmptyOrgUser(): OrgUserModel {
    return {
      orgId: '',
      role: '',
      userId: '',
      firstName: '',
      lastName: '',
      status: '',
      email: '',
      orgUserId: ''
    };
  }


  static getEmptyCampaignUser(): CampaignUserModel {
    return {
      campaignId: '',
      role: '',
      firstName: '',
      lastName: '',
      status: '',
      email: '',
      userId: '',
      campaignUserId: ''
    };
  }

  static getEmptyOrder(): BuyerViewOrderModel {
    return {
      orderId: '',
      cart: this.getEmptyCart(),
      campaign:this.getEmptyViewCampaign(),
      createdAt: 0,
      supportMessage: '',
      hideDonorName: false,
      email: '',
      donorName: '',
      lastName: '',
      firstName: '',
      ouid:''

    };
  }

  static getEmptyReportOrder(): ReportOrderModel {
    return {
      orderId: '',
      cart: this.getEmptyCart(),
      //campaign:this.getEmptyViewCampaign(),
      createdAt: 0,
      supportMessage: '',
      hideDonorName: false,
      email: '',
      donorName: '',
      lastName: '',
      firstName: '',
      ouid:'',
      refundedAt: 0,
      donationTaxReceipt: Globals.getEmptyFile(),
      processorFee:0
      


    };
  }
  static getEmptyToken(): TokenModel {
    return {
      email: '',
      exp: 0


    };
  }

  static getEmptyOrgInvitationToken(): OrgInvitationTokenModel {
    return {
      email: '',
      orgName: '',
      exp: 0
    };
  }




  static getEmptyDownloadOrderFile(): BuyerDownloadOrderFileModel {
    return {
      orderId: '',
      itemId: '',
      files: []

    };
  }



  static getEmptyCart(): BuyerViewCartModel {
    return {
      cartId: '',
      items: [],
      totalQuantity: 0,
      subTotal: 0,
      currency: '',
      coupons: [],
      tax1: 0,
      tax2: 0,
      grandSubTotal: 0,
      total: 0,
      platformAmount: 0,
      donations: [],
      toOrgIdSelectable: false,
      taxRate1: 0,
      taxRate2: 0,
      files: [],
      cuid: '',
      eligibleAmount: 0,
      ineligibleAmount: 0,
      billingAddress: this.getEmptyAddress(),
      taxable: true,
      firstName: '',
      lastName: '',
      email: '',
      campaignType:'',
      charityId:'',
      hideDonorName: false,
      extraDonationAmount: 0



    };
  }

  static getEmptyLang(): LangModel {
    return {
      fr: '',
      en: '',
      es: '',

    };
  }

  static getEmptyNewLang(): NewLangModel {
    return {
      fr: this.getEmptyLangDetail(),
      en: this.getEmptyLangDetail(),
      es: this.getEmptyLangDetail(),

    };
  }

  static getEmptyLangDetail(): LangDetailModel {
    return {
      text: '',
      mode: '',
    };
  }


  static getEmptyEndDate(): EndDateModel {
    return {
      name: '',
      value: '',

    };
  }
  


  static getEmptyBuyerItemOption(): BuyerItemOptionModel {
    return {
      optionId: '',
      inventoryId:'',
      title: '',
      regularPrices:{},
      discountPercent: 0,
       discountedPrice: 0,
       regularPrice: 0
      
    }
  }
  static getEmptyCharityRaiser(): CharityRaiserModel {
    return {
      charityId: '',
      legalName: '',
      status:'',
      bn:'',
      op:'',
      np:'',
      city:'',
      state: ''
    }
  }

  static getEmptyBuyerItem(): BuyerItemModel {
    return {
      itemId: '',
      title: '',
      description: '',
      price: 0,
      currency: '',
      currencyConversion: '',
      prices: {},
      mainPhoto: this.getEmptyBuyerViewPublicFile(),
      photos: [],
      categoryIds: [],
      finalOrgPercent: 0,
      finalOrgName: '',
      freeable: false,
      options: [],
      ownerOrgId:'',
      forType:'',
      forCampaignIds:[],
      forCharityIds:[]

  




    };
  }
}

