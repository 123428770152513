import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuService } from './app.menu.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AppComponent } from './app.component';
import { EventData } from './service/event.class';
import { BuyerViewCartModel, LoginUserModel, QueryResultModel } from './model/shared.model';
import { URL_BUYER_CART } from './service/urls';
import { Globals } from './globals';
import { EventBusService } from './service/event-bus,service';
import { HttpService } from './service/http.service';
import { Subscription } from 'rxjs';
import { TranslocoService, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { SessionStorage } from './service/session-storage';

@Component({
    selector: 'app-main',
    styleUrls: ['./app-main.component.scss'],
    templateUrl: './app.main.component.html',

    animations: [
        trigger('mask-anim', [
            state('void', style({
                opacity: 0
            })),
            state('visible', style({
                opacity: 0.8
            })),
            transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)'))
        ])
    ]
})
export class AppMainComponent implements OnInit, OnDestroy {

    menuClick: boolean = false;

    userMenuClick: boolean = false;

    topbarUserMenuActive: boolean = false;

    menuActive: boolean = false;

    menuHoverActive: boolean = false;

    configDialogActive: boolean = false;

    loggedUser: LoginUserModel = Globals.loggedUser;

    private subscription: Subscription = new Subscription();

    constructor(private menuService: MenuService, private primengConfig: PrimeNGConfig, public app: AppComponent,
        private eventBusService: EventBusService,
        private httpSvc: HttpService,
        private route: ActivatedRoute,
        private location: Location,
        private messageService: MessageService,
        private translocoService: TranslocoService,
        private sessionStorage: SessionStorage,
        @Inject(TRANSLOCO_SCOPE) private scope: string,
    ) { }
    ngOnInit(): void {


        this.subscription.add(this.translocoService.selectTranslate('translationLoaded', {}, this.scope).subscribe(() => {

            this.showMsg();

            this.getCartId();



        }));





    }

    showMsg(): void {
        this.route.queryParams.subscribe(params => {
            const [path, query] = this.location.path().split('?');
            const newParams = new HttpParams({ fromString: query });
            const msg = params['msg'];
            console.log('msg1', msg);
            if (msg != undefined) {
                //this.messageService.add({ key:'msgTop', severity: 'info', summary:'', detail: this.translocoService.translate('expiredSession') });
                //this.messageService.add({ key:'toastMain', severity: 'error', summary: this.translocoService.translate(msg), detail: '' });
                setTimeout(() => {
                    //this.messageService.add({ key:'msgTop', severity: 'info', summary:'', detail: this.translocoService.translate('expiredSession'), life:3000 });
                    this.messageService.add({ key: 'toastMain', severity: 'error', summary: this.translocoService.translate(msg), detail: '' });
                }, 0);
                console.log('msg2', msg);
                this.location.replaceState(path, newParams.delete('msg').toString());
            }
            const msgTop = params['msgTop'];
            console.log('msg2', msgTop);
            if (msgTop != undefined) {
                setTimeout(() => {
                    this.messageService.add({ key: 'msgTop', severity: 'info', summary: '', detail: this.translocoService.translate(msgTop) });

                }, 0);
                console.log('msg2', msg);
                this.location.replaceState(path, newParams.delete('msgTop').toString());
            }


        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    onWrapperClick() {
        if (!this.menuClick) {
            this.menuActive = false;

            if (this.app.horizontal) {
                this.menuService.reset();
            }

            this.menuHoverActive = false;
            this.unblockBodyScroll();
        }

        if (!this.userMenuClick) {
            this.topbarUserMenuActive = false;
        }

        this.userMenuClick = false;
        this.menuClick = false;
    }

    onMenuButtonClick(event: Event) {
        this.menuClick = true;

        if (!this.app.horizontal || this.isMobile()) {
            this.menuActive = !this.menuActive;

            if (this.menuActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onTopbarUserMenuButtonClick(event: Event) {
        this.userMenuClick = true;
        this.topbarUserMenuActive = !this.topbarUserMenuActive;

        event.preventDefault();
    }

    onTopbarUserMenuClick(event: any) {
        this.userMenuClick = true;

        if (event.target.nodeName === 'A' || event.target.parentNode.nodeName === 'A') {
            this.topbarUserMenuActive = false;
        }
    }

    onTopbarSubItemClick(event: Event) {
        event.preventDefault();
    }

    onSidebarClick(event: Event) {
        this.menuClick = true;
    }

    isMobile() {
        return window.innerWidth <= 1024;
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    onRippleChange(event: any) {
        this.app.ripple = event.checked;
        this.primengConfig.ripple = event.checked;
    }

    getCartId(): void {
        const params: any = {};

        params.lang = Globals.webSiteLang;
        params.cartId = this.sessionStorage.getItem('cartId');
        if (params.cartId == null) {
            this.eventBusService.emit(new EventData('itemsInCart', 0));
            return;
        }
        params.region = Globals.region;

        
        this.subscription.add(this.httpSvc.get<QueryResultModel>(URL_BUYER_CART, params).subscribe({
            next:
                (data) => {

                    console.log('cardata', data);
                    //this.eventBusService.emit(new EventData('itemsInCart',data.totalQuantity));

                    for (let i = 0; i < data.objects.length; i++) {
                        const cart: BuyerViewCartModel = data.objects[i];

                        this.sessionStorage.setItem('cartId', cart.cartId);
                        this.eventBusService.emit(new EventData('itemsInCart', cart.totalQuantity));

                    }


                },
            error:
                err => {

                    console.log('data err', err);


                }
        }
        ));
    }
}
