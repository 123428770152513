import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { FooterComponent } from 'src/app/footer/footer.component';
import { AddressAutocompleteComponent } from '../address-autocomplete/address-autocomplete.component';
import { ShareSignInComponent } from '../signin/share-signin.component';
import { ShareSignUpComponent } from '../signup/share-signup.component';
import { ViewGalleryComponent } from '../view-gallery/view-gallery.component';
import { DialogModule } from 'primeng/dialog';
import { CategoryBannerComponent } from 'src/app/buyer/category-banner/category-banners.component';


@NgModule({
   imports: [CommonModule,
      RouterModule,
      FormsModule,
      DropdownModule,
      MessageModule,
      MessagesModule,
      ReactiveFormsModule,
      CheckboxModule,
      InputTextModule,
      ButtonModule,
      A11yModule,
      DialogModule,
      TranslocoModule],
   declarations: [ ShareSignUpComponent, 
        
       
      
   ],
   exports: [ ShareSignUpComponent,   
      
       
      CommonModule
   ]
})
export class SharedModule { }