import { Component, ElementRef, OnDestroy, OnInit, ViewChild, signal } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Message } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Globals } from 'src/app/globals';
import { ChangeEmailModel, MobileModel, OTPModel, VerifyOTPModel } from 'src/app/model/shared.model';
import { AuthService } from 'src/app/service/auth.service';
import { EventBusService } from 'src/app/service/event-bus,service';
import { HttpService } from 'src/app/service/http.service';
import { ShareUtils } from 'src/app/service/shareutils';
import { TokenStorageService } from 'src/app/service/token-storage.service';
import { URL_CHANGE_EMAIL, URL_MOBILE, URL_SMS_RESEND_OTP, URL_VERIFY_MOBILE_NUMBER, URL_VERIFY_OTP } from 'src/app/service/urls';
import { BreadcrumbService } from '../breadcrumb/app.breadcrumb.service';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { IntlPhoneInputComponent } from '../intl-phone-input/intl-phone-input.component';
import { HttpParams } from '@angular/common/http';


@Component({
  standalone: true,
  imports: [CommonModule, TranslocoModule, DropdownModule,
    MessagesModule,
    InputTextModule, FormsModule, ReactiveFormsModule, 
    RouterModule,
    ButtonModule, DialogModule, IntlPhoneInputComponent],
  selector: 'app-user-edit-phone',
  templateUrl: './user-edit-phone.component.html',
  styleUrls: ['./user-edit-phone.component.scss']
})
export class UserEditPhoneComponent implements OnInit, OnDestroy {
  inputPhone: PhoneNumber | undefined = undefined;
  inputPhoneInvalid = false;
  defaultIsoCountry: string = 'CA';
  currentMobile=signal('');
  mobileVerified= signal(false);
  mobileAlreadyVerifiedDialog=false;
  readonly  MAX_SMS_RETRIES = 99;
  loaded =signal(false);
  backURL = '/profile';
  form: FormGroup;
  otpForm: FormGroup;
  private subscription: Subscription = new Subscription();
  signInPageMsgs: Message[] = [];
  email = Globals.loggedUser.email;
  validateEmailDialog = false;
  confirmPhoneDialog = false;
  verifyPhone = '';
  smsTries = 0;
  cantValidatedSMSDialog = false;
  smsVerificationDialog = false;
  isResentSMSBtnDisabled = true;
  time = 5;
  SMSinterval: ReturnType<typeof setInterval> | undefined = undefined;
  abortController: AbortController | undefined;
  @ViewChild('addressL2') addressL2!: ElementRef;
  @ViewChild('otpRef') otpElement!: ElementRef;


  constructor(
    private tokenStorage: TokenStorageService,
    fb: UntypedFormBuilder,
    private authService: AuthService,
    private httpSvc: HttpService,
    public shareUtils: ShareUtils,
    private route: ActivatedRoute,
    private eventBusService: EventBusService,
    private breadcrumbService: BreadcrumbService,
    private translocoService: TranslocoService,
    private router: Router
  ) {


    this.otpForm = fb.group({

      otp: ['', Validators.required],
    });

    this.form = fb.group({
      phone: [null, [
        Validators.required,
        shareUtils.removeSpaces,
        shareUtils.emailValidator,
      ]],
    
    });

  }

  ngOnInit(): void {

    this.subscription.add(this.route.queryParams.subscribe(params => {
      const backURL = params['backURL'];
      if (backURL != null) {
        this.backURL = backURL;

      }
    }));

    this.breadcrumbService.setItems([
      { label: this.translocoService.translate('profile'), routerLink: ['/profile'], },
      { label: this.translocoService.translate('phone') }
    ]);

    this.getMobile();
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  validateEmail(): boolean {

    if (this.form.controls['email1'].errors && this.form.controls['email1'].errors?.['required']) {
      this.form.controls['email1'].markAsTouched();
      this.form.controls['email1'].markAsDirty();
      this.form.controls['email1'].setErrors({ 'required': true });
      return false;
    } else if (this.form.controls['email2'].errors && this.form.controls['email2'].invalid) {
      this.form.controls['email2'].markAsTouched();
      this.form.controls['email2'].markAsDirty();
      this.form.controls['email2'].setErrors({ 'invalid': true });
      return false;
    }

    if (this.form.value.email1 != this.form.value.email2) {
      this.form.controls['email1'].markAsTouched();
      this.form.controls['email1'].markAsDirty();
      this.form.controls['email1'].setErrors({ 'mustMatch': true });
      this.form.controls['email2'].markAsTouched();
      this.form.controls['email2'].markAsDirty();
      this.form.controls['email2'].setErrors({ 'mustMatch': true });
      this.showClearMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('emailMustMatch'));
      return false;
    }

    if (this.form.value.email1.trim().toLowerCase == this.email.trim().toLowerCase()) {

      this.showClearMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('emailMustMatch'));
      return false;
    }


    return true;
  }

  closeValidateEmailDialog(): void {
    this.validateEmailDialog = false;
    this.router.navigate([this.backURL], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });

  }




  updateEmail(): boolean {

    if (!this.validateEmail()) {
      return false;
    }




    const params: any = {};
    params.email = this.form.value.email1.trim().toLowerCase();

    this.subscription.add(this.httpSvc.post<ChangeEmailModel>(URL_CHANGE_EMAIL, params).subscribe(
      {
        next:
          (data) => {
            console.log('data', data);
            this.authService.updateUser(data.user);
            this.email = this.form.value.email1.trim().toLowerCase();
            if (!data.emailConfirmed) {
              this.validateEmailDialog = true;
            } else {
              this.router.navigate([this.backURL], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
            }

            //     this.router.navigate([ this.backURL], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
            return true;
          },
        error:
          (err) => {

            console.log('data err', err);
            if (err.status === 400 && err.error.messages[0] !== undefined) {

              this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate(err.error.messages[0]));

            }
            this.clearMessage();
            return false;

          }
      }
    ));

    return true;
  }

  showMessage(msgType: string, msgSummary: string = '', msgDetail: string = ''): void {
    this.signInPageMsgs = [];
    this.signInPageMsgs.push(
      {
        severity: msgType,
        summary: msgSummary,
        detail: msgDetail
      });
  }

  clearMessage(vTimeOut: number = 2000): void {
    setTimeout(() => {
      this.signInPageMsgs = [];
    }, vTimeOut);
  }


  showClearMessage(msgType: string, msgSummary: string = '', msgDetail: string = '', vTimeOut: number = 2000): void {

    this.signInPageMsgs = [];
    this.signInPageMsgs.push(
      {
        severity: msgType,
        summary: msgSummary,
        detail: msgDetail
      });
    setTimeout(() => {
      this.signInPageMsgs = [];
    }, vTimeOut);
  }

  phoneChangeEmitter(phoneNumber: PhoneNumber): void {
    console.log("PhoneNumber emmited ", phoneNumber);
    if (phoneNumber == undefined) {
      this.inputPhone = undefined;
    }
    this.inputPhone = phoneNumber;
    if (this.inputPhone !== undefined && this.inputPhone.isValid()) {
      console.log('valid');
      this.form.controls['phone'].setValue(this.inputPhone.formatInternational());
    }
    else {
      console.log('notvalid');
      this.form.controls['phone'].setValue('');
    }
  }


  closeConfirmPhoneDialog(): void {
    this.confirmPhoneDialog = false;
  }



  verifyNumber(): void {

    if (this.smsTries > this.MAX_SMS_RETRIES) {
      this.confirmPhoneDialog = false;

      this.cantValidatedSMSDialog = true;
      return;
    }

    const params: any = {};

    let e164Phone = '';

    


    if (this.inputPhone===undefined || !this.inputPhone.isValid()) {
      this.inputPhoneInvalid = true;
      return;
    }

    e164Phone = this.inputPhone!.formatInternational();

    this.verifyPhone = this.inputPhone.formatInternational();
    params.phone = e164Phone;

    if(this.inputPhone.number===this.currentMobile() && this.mobileVerified()) {

      this.mobileAlreadyVerifiedDialog=true;
      return;
    }

    this.confirmPhoneDialog = false;
    params.lang = Globals.webSiteLang;
    this.subscription.add(this.httpSvc.makeHttpPost(URL_VERIFY_MOBILE_NUMBER, params).subscribe(
      data => {
        this.otpRequest();
        this.smsTries += 1;
        this.smsVerificationDialog = true;

        setTimeout(() => { this.otpElement.nativeElement.focus(); }, 0);
        this.startResentSMSTimer();
      },
      err => {
        console.log('err', err);
        this.confirmPhoneDialog = false;
        this.smsVerificationDialog = false;
        this.cantValidatedSMSDialog = true;
      }));


  }

  closeMobileAlreadyVerifiedDialog(): void {
    this.mobileAlreadyVerifiedDialog=false;
    this.router.navigate([this.backURL], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });

  }
  
  async otpRequest():Promise<void> {
    
    if ('OTPCredential' in window) {
      this.abortController = new AbortController();
      
      const o = {
        otp: { transport: ['sms'] },
        signal: this.abortController.signal
      };

      
      try{
         await window.navigator['credentials'].get(o).then(content =>
          {
            if(content!=null) {
          this.otpForm.controls['otp'].setValue(content.id );
          this.verifyOTPCode();
            }
        }
        );
        
      }catch(err){
        console.log(err);
      } 
    }
  }

  startResentSMSTimer(): void {
    this.isResentSMSBtnDisabled = true;
    this.SMSinterval = setInterval(() => {
      this.time--;
      if (this.time <= 0) {
        this.isResentSMSBtnDisabled = false;

        clearInterval(this.SMSinterval);
      }
    }, 1000)
  }

  getMobile(): void {
    const params = new HttpParams();
    this.subscription.add(this.httpSvc.get<MobileModel>(URL_MOBILE, params).subscribe({
      next:
        (data) => {
          console.log('mobile', data);
          if (data.mobile != undefined) {
            this.currentMobile.set(data.mobile);
            
            this.inputPhone=parsePhoneNumber(data.mobile);

            if(data.mobileVerifiedAt>0) {
              this.mobileVerified.set(true);
            } else {
              this.mobileVerified.set(false);
            }
            this.loaded.set(true);
          } 
          else {
            this.loaded.set(true);
          }
        },
      error:
        err => {
          console.log('data err', err);
          this.currentMobile.set('');
          this.loaded.set(true);
        }
    }
    ));
  }
  
  verifyOTPCode(): void {

    const params: any = {};

    const otp = this.otpForm.get('otp')!.value.trim();
    if (otp === '') {
      this.otpForm.controls['otp'].markAsTouched();
      this.otpForm.controls['otp'].markAsDirty();
      this.otpForm.controls['otp'].setErrors({ 'invalid': true });
      return;
    }
    params.otp = otp;
    params.text = false;
    this.subscription.add(this.httpSvc.post<VerifyOTPModel>(URL_VERIFY_OTP, params).subscribe(

      data => {
        if (data.verified == true) {
          this.smsVerificationDialog = false;
    
          this.router.navigate([this.backURL], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
          
        }
        else {
          this.otpForm.controls['otp'].markAsTouched();
          this.otpForm.controls['otp'].markAsDirty();
          this.otpForm.controls['otp'].setErrors({ 'invalid': true });
        }
      },
      err => {
        console.log('err', err);
        this.confirmPhoneDialog = false;
        this.smsVerificationDialog = false;
        this.cantValidatedSMSDialog = true;
      }));

  }

  closeSmsVerificationDialog(): void {

    this.otpForm.controls['otp'].setValue('');
    this.otpForm.controls['otp'].setErrors(null);
    this.smsVerificationDialog = false;
    clearInterval(this.SMSinterval);
    if (this.smsTries >= this.MAX_SMS_RETRIES) {
      this.confirmPhoneDialog = false;

      this.cantValidatedSMSDialog = true;
    }
  }

  otpValidation(event: any): boolean {
    return this.shareUtils.keyPressNumbers(6, event);
  }

  resentSMSCode(): void {
    this.isResentSMSBtnDisabled = true;
    this.time = 5;
    this.resentOTP();

  }

  

  resentOTP(): void {

    if (this.smsTries > this.MAX_SMS_RETRIES) {
      this.confirmPhoneDialog = false;

      this.cantValidatedSMSDialog = true;
    }

    const params: any = {};



    this.subscription.add(this.httpSvc.makeHttpPost(URL_SMS_RESEND_OTP, params).subscribe(
      data => {
        this.smsTries += 1;

        setTimeout(() => { this.otpElement.nativeElement.focus(); }, 0);
        this.startResentSMSTimer();
      },
      err => {
        console.log('err', err);
        this.confirmPhoneDialog = false;
        this.smsVerificationDialog = false;
        this.cantValidatedSMSDialog = true;
      }));


  }

  cantVerifiedSMS(): void {

    this.cantValidatedSMSDialog = false;
   this.confirmPhoneDialog=false;
    this.smsTries=this.MAX_SMS_RETRIES+1;
   

  }
}

