import {ActivatedRouteSnapshot, DetachedRouteHandle, BaseRouteReuseStrategy} from '@angular/router';

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {

  public override  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    
   // console.log('future current', curr);
    //console.log('future', future);
    return false;
    //return (future.routeConfig === curr.routeConfig) || future.data['reuseComponent'];
  }
}