<div class="layout-topbar">

    <a href="#" class="layout-menubutton" [ngClass]="{'layout-menubutton-active': app.menuActive}"
        (click)="app.onMenuButtonClick($event)">
        <div class="layout-menubutton-icon"></div>
    </a>

    <div class="layout-topbar-grid">
        <div class="layout-topbar-grid-column layout-topbar-grid-column-fixed">
            <a href="/" (click)="goToHome()" class="layout-logo">
                <img src="assets/layout/images/kmdcompath.svg" alt="sapphire-layout" />
            
            </a>
        </div>

        <div class="layout-topbar-grid-column">
            <app-menu></app-menu>
        </div>
        <!--
        <p-dropdown [options]="languageList" [(ngModel)]="selectedLanguage" (onChange)="changeLanguage()">
        </p-dropdown>
       -->
        <!--
        <div class="layout-topbar-grid-column layout-topbar-grid-column-fixed">
            <span class="layout-topbar-search">
                <span class="p-input-icon-right">
                    <input type="text" pInputText placeholder="Search"/>
                    <i class="pi pi-search"></i>
                </span>
            </span>
        </div>
-->
    
        <div class="layout-topbar-grid-column layout-topbar-grid-column-fixed" *ngIf="!authService.isLoggedIn()">
            <i class="text-3xl pi pi-shopping-cart topbar-text cursor-pointer mx-3" pBadge style="font-size: 2rem" [value]="itemsInCart" severity="warning" (click)="goToCart($event)" *ngIf="itemsInCart && itemsInCart!=='0'"></i>            
            <i class="text-3xl pi pi-shopping-cart topbar-text cursor-pointer mx-3"   (click)="goToCart($event)" *ngIf="itemsInCart==='0' || itemsInCart===''"></i>
            <span class="topbar-text cursor-pointer" (click)="signIn()">
                <ng-container *transloco="let t;">{{t('signIn')}}</ng-container>
              </span>
         
        </div>
        <div class="layout-topbar-grid-column layout-topbar-grid-column-fixed " *ngIf="authService.isLoggedIn()">
            
            <i class="text-4xl pi pi-shopping-cart topbar-text cursor-pointer" pBadge style="font-size: 2rem" [value]="itemsInCart" severity="warning" (click)="goToCart($event)" *ngIf="itemsInCart && itemsInCart!=='0'"></i>
            <i class="text-4xl pi pi-shopping-cart topbar-text cursor-pointer"   (click)="goToCart($event)" *ngIf="itemsInCart==='0' || itemsInCart===''"></i>
            
            <a href="#" class="profile-menu-button" (click)="app.onTopbarUserMenuButtonClick($event)">
                <span *ngIf="pictureURL===''">
                    <img src="assets/images/avatar-white.png" alt="sapphire-layout"  />
                </span>
                <span *ngIf="pictureURL!==''">
                    <img [src]="pictureURL" class="roungImage" />
                </span>

            </a>
            <ul class="layout-profile-menu fadeInDown"
                [ngClass]="{'layout-profile-menu-active': app.topbarUserMenuActive}"
                (click)="app.onTopbarUserMenuClick($event)">
                <b>{{firstName}} {{lastName}}</b>
                <!--
                <li class="layout-profile-menu-search">
                    <span class="md-inputfield">
                        <input type="text" pInputText />
                        <label>Search</label>
                    </span>
                </li>
                -->
                <li role="menuitem">

                    <a href="#" pRipple (click)="goToProfile($event)">
                        <i class="pi pi-user"></i>
                        <span><ng-container *transloco="let t;">{{t('profile')}}</ng-container></span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" pRipple (click)="goToOrders($event)">
                        <i class="pi pi-cart-plus"></i>
                        <span><ng-container *transloco="let t;">{{t('myOrders')}}</ng-container></span>
                    </a>
                </li>
                <li role="menuitem">

                    <a href="#" pRipple (click)="goToCampaigns($event)">
                        <i class="pi pi-crown"></i>
                        <span><ng-container *transloco="let t;">{{t('myCampaigns')}}</ng-container></span>
                    </a>
                </li>

       

         
                <li role="menuitem">

                    <a href="#" pRipple (click)="goToItems($event)">
                        <i class="pi pi-gift"></i>
                        <span><ng-container *transloco="let t;">{{t('myItems')}}</ng-container></span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" pRipple (click)="goToVoucher($event)">
                        <i class="pi pi-barcode"></i>
                        <span><ng-container *transloco="let t;">{{t('voucherValidation')}}</ng-container></span>
                    </a>
                </li>
                <li role="menuitem">

                    <a href="#" pRipple (click)="goToOrganizations($event)">
                        <i class="pi pi-users"></i>
                        <span><ng-container *transloco="let t;">{{t('myOrganizations')}}</ng-container></span>
                    </a>
                </li>

         

                <li role="menuitem">
                    <a href="#" pRipple (click)="logout()">
                        <i class="pi pi-times"></i>
                        <span><ng-container *transloco="let t;">{{t('logout')}}</ng-container></span>
                    </a>
                </li>
                <li role="menuitem" *ngIf="loggedUser?.previousUserId">
                    <a href="#" pRipple (click)="switchBackUser()">
                        <i class="pi pi-times"></i>
                        <span><ng-container *transloco="let t;">{{t('switchBackUser')}}</ng-container></span>
                    </a>
                </li>                
            </ul>
        </div>
    </div>

</div>
<p-dialog [modal]="true" [closable]="true" [(visible)]="signInDialog" [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden', 'max-width': '99vw','width': '400px',
         'max-height': '80vh'}" [styleClass]="'customDialog'">


    <app-share-sign-in (action)="action($event)" role="buyer" *ngIf="signInDialog"></app-share-sign-in>



</p-dialog>