import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Globals } from '../globals';
import { URL_LOG_IN, URL_LOG_IN_GOOGLE_USER, URL_PUBLIC_TENANT_TOKEN, URL_REFRESH_TOKEN } from './urls';
import { TokenStorageService } from './token-storage.service';
import { EventData } from './event.class';
import { EventBusService } from './event-bus,service';
import { LoginUserModel } from '../model/shared.model';
import { ConfigObject } from './startup.service';
import { LocalStorage } from './local-storage';
import { HttpService } from './http.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private currentUserSubject!: BehaviorSubject<LoginUserModel | null>;
  public currentUser!: Observable<LoginUserModel | null>;
  

  private http: HttpClient;

  constructor(private handler: HttpBackend,
    private httpSvc: HttpService,
    private tokenStorageService:TokenStorageService,
        
    private localStorage:  LocalStorage,
    private eventBusService: EventBusService
    ) {
      //this.http = new HttpClient(httpBackend);
      this.http = new HttpClient(handler);
    //const currentUserStr = sessionStorage.getItem('currentUser');

    const user = this.tokenStorageService.getUser();
    console.log('constr', user);
    if (user) {
      this.currentUserSubject = new BehaviorSubject<LoginUserModel | null>(user);

      this.currentUser = this.currentUserSubject.asObservable();
    }
  }


  public get currentUserValue(): LoginUserModel | null {
    return this.currentUserSubject.value;
  }


  login(params: any) {

    const httpOptionsPublicToken = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': Globals.publicToken
      })
    };
  
   
   
    return this.httpSvc.post<LoginUserModel>(Globals.domainAPI + URL_LOG_IN, params)
      .pipe(map((user: LoginUserModel) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log('user', user);
        //sessionStorage.setItem('currentUser', JSON.stringify(user));
      this.updateUser(user);

        return user;
      }));
   
  }


  loginGoogleUser(params: any) {

    const httpOptionsPublicToken = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': Globals.publicToken
      })
    };
  
   
    return this.http.post<any>(Globals.domainAPI + URL_LOG_IN_GOOGLE_USER, params, httpOptionsPublicToken)
      .pipe(map((user: LoginUserModel) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log('user', user);
        //sessionStorage.setItem('currentUser', JSON.stringify(user));
      this.updateUser(user);

        return user;
      }));
   
  }

  updateUser(user:LoginUserModel): void {
    this.tokenStorageService.saveToken(user.accessToken as string);
    this.tokenStorageService.saveRefreshToken(user.refreshToken as string);
    this.tokenStorageService.saveUser(user);

    this.tokenStorageService.saveDeviceToken(user.userId as string,user.deviceToken);
    Globals.loggedUser = Globals.getEmptyUser();
    Globals.loggedUser.email = user.email;
    Globals.loggedUser.firstName = user.firstName;
    Globals.loggedUser.lastName = user.lastName;
    //Globals.loggedUser.accessToken = user.accessToken;
    //Globals.loggedUser.refreshToken =  user.refreshToken;
    Globals.loggedUser.tenantId =  user.tenantId;
    Globals.loggedUser.roles = user.roles;
    Globals.loggedUser.pictureURL = user.pictureURL;
    Globals.loggedUser.userId = user.userId;
    Globals.loggedUser.lang = user.lang;
    Globals.loggedUser.previousFirstName = user.previousFirstName;
    Globals.loggedUser.previousLastName = user.previousLastName;
    Globals.loggedUser.previousUserId = user.previousUserId;
    Globals.loggedUser.previousRoles = user.previousRoles;


    this.currentUserSubject.next(user);
  }

  refreshToken(token: string) {
    const httpOptionsPublicToken = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': Globals.publicToken
      })
    };
   
    return this.http.post(Globals.domainAPI + URL_REFRESH_TOKEN, {
      refreshToken: token
    }, httpOptionsPublicToken);
   
  }

  
  testToken(): Observable<any> {
    return new Observable(subscriber => {
      
      subscriber.next(null)
      subscriber.complete()
    });
  }

  refreshPublicToken() {
    const httpPublicOptions = {
      headers: new HttpHeaders({

        'Content-Type': 'application/json',
        'Authorization': ''

      })

    };

    const params: any = {};
    params.domain = Globals.domainAPI;
    params.tenantId = Globals.tenantId;
    params.url = window.location.href;
    params.publicToken=this.tokenStorageService.getPublicToken();
    params.stagingToken = this.localStorage.getItem('stagingToken');
  
  

    return this.http.post<ConfigObject>(Globals.domainAPI + URL_PUBLIC_TENANT_TOKEN, JSON.stringify(params)
    , httpPublicOptions);

    
  }
  
  logout(): void {
    // remove user from local storage and set current user to null
    Globals.loggedUser= Globals.getEmptyUser();
    this.eventBusService.emit(new EventData('itemsInCart',0));
    const eventData:EventData= new EventData('roles',[]);
    this.eventBusService.emit(eventData);
    this.tokenStorageService.signOut();
    this.currentUserSubject.next(null);
    
}

isLoggedIn(): boolean {
  
  const loggedIn = this.tokenStorageService.getToken();
  let isLogin = false;
  if (loggedIn!==null && loggedIn!='')
  {
    isLogin = true;
 
  }
  
  return isLogin;
}

getRoles():string[] {
  if(this.currentUserValue!=undefined) {
  return this.currentUserValue.roles;  
  }
  else return [];
}


}