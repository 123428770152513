import { CommonModule, CurrencyPipe, IMAGE_LOADER, ImageLoaderConfig, LocationStrategy, NgOptimizedImage, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { AppRoutingModule } from './app-routing.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';

// PrimeNG Components for demos
import { QuillModule } from 'ngx-quill';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';

// Application Components
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
//import {AppConfigComponent} from './app.config.component';
import { BuyerTopBarComponent } from './buyer/top-bar/buyer-topbar.component';

// Demo services

import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouteReuseStrategy } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BlockUIModule } from 'primeng/blockui';
import { ImageModule } from 'primeng/image';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressBarModule } from 'primeng/progressbar';
import { SliderModule } from 'primeng/slider';
import { AdminBoardComponent } from './admin/admin-board/admin-board.component';
import { MenuService } from './app.menu.service';
import { AppBreadcrumbComponent } from './breadcrumb/app.breadcrumb.component';
import { BreadcrumbService } from './breadcrumb/app.breadcrumb.service';
import { AfterPaymentGuestComponent } from './buyer/after-payment-guest/after-payment-guest.component';
import { BuyerDonateComponent } from './buyer/buyer-donate-cart/buyer-donate-cart.component';
import { BuyerMainComponent } from './buyer/buyer-main/buyer-main.component';
import { BuyerViewOrgComponent } from './buyer/buyer-view-org/buyer-view-org.component';
import { CartBillingAddressComponent } from './buyer/cart-billing-address/cart-billing-address.component';
import { CheckOutAddressComponent } from './buyer/checkout-address/checkout-address.component';
import { CheckOutGuestComponent } from './buyer/checkout-guest/checkout-guest.component';
import { ViewCheckoutComponent } from './buyer/checkout/view-checkout.component';
import { ExploreCampaignsComponent } from './buyer/explore-campaigns/explore-campaigns.component';
import { HomeComponent } from './buyer/home/home.component';
import { ItemDetailComponent } from './buyer/item-detail/item-detail.component';
import { OldCheckOutComponent } from './buyer/oldcheckout/checkout.component';
import { OrderDetailComponent } from './buyer/order-detail/order-detail.component';
import { ReviewOrderComponent } from './buyer/review-order/review-order.component';
import { ViewCartComponent } from './buyer/view-cart/view-cart.component';
import { ViewOrdersComponent } from './buyer/view-orders/view-orders.component';
import { ConfirmEmailPasswordComponent } from './confirm-email-password/confirm-email-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { CookieSettingsComponent } from './cookie-settings/cookie-settings.component';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { NotFoundomponent } from './not-found/not-found.component';
import { ResetLinkComponent } from './reset-link/reset-link.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HttpService } from './service/http.service';
import { AppRouteReuseStrategy } from './service/route-reuse=strategy';
import { StartupService } from './service/startup.service';
import { SharedModule } from './share/share-module/share.module';
import { SignInComponent } from './signin/signin.component';
import { SignUpComponent } from './signup/signup.component';
import { UserEditAddressComponent } from './user-edit-address/user-edit-address.component';
import { UserEditEmailComponent } from './user-edit-email/user-edit-email.component';
import { UserEditLanguagePrefComponent } from './user-edit-language-pref/user-edit-language-pref.component';
import { UserEditNameComponent } from './user-edit-name/user-edit-name.component';
import { UserEditPasswordComponent } from './user-edit-password/user-edit-password.component';
import { UserEditRegionComponent } from './user-edit-region/user-edit-region.component';
import { UserPaymentProviderComponent } from './user-payment-provider/user-payment-provider.component';
import { UserPaypalOnboardedComponent } from './user-paypal-onboarded/user-paypal-onboarded.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { StagingBannerComponent } from './buyer/staging-banner/staging-banners.component';
import { provideClientHydration } from '@angular/platform-browser';
import { FooterComponent } from './footer/footer.component';
import { ShareSignInComponent } from './share/signin/share-signin.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        FooterComponent,
        CookieSettingsComponent,
        ShareSignInComponent,
        MessagesModule,
        ToastModule,
        BadgeModule,
        DialogModule,
      
        NgOptimizedImage,
        
        MenuModule,
        
        CheckboxModule,
        TranslocoRootModule,
        TranslocoModule,
        BrowserAnimationsModule,
        /*
        
        PanelModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        RadioButtonModule,
        InputSwitchModule,
        CheckboxModule,
        ButtonModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        ReactiveFormsModule,
        FormsModule,
        MessageModule,
        
        ,
        BadgeModule,
        ProgressBarModule,
        ToastModule,
        ConfirmDialogModule,
        DropdownModule,
        BlockUIModule,
        SliderModule,
        InputNumberModule,
        CarouselModule,
        ImageModule,
        AccordionModule,
        GoogleMapsModule,
        FontAwesomeModule,
        MatSlideToggleModule,
        MatIconModule,
        AutoCompleteModule,
        */
        //QuillModule.forRoot(),
        //QuillModule
        /*        
                FormsModule,
                AppRoutingModule,
                HttpClientModule,
                BrowserAnimationsModule,
                AccordionModule,
                AutoCompleteModule,
                AvatarModule,
                AvatarGroupModule,
             
                BreadcrumbModule,
                ButtonModule,
                CalendarModule,
                CardModule,
                
                CascadeSelectModule,
                ChartModule,
           
                ChipModule,
                ChipsModule,
                CodeHighlighterModule,
           
                ConfirmPopupModule,
                ColorPickerModule,
                ContextMenuModule,
                
                      DataViewModule,
                DividerModule,
                DropdownModule,
                FieldsetModule,
                FileUploadModule,
                GalleriaModule,
                ImageModule,
                InplaceModule,
                
                InputMaskModule,
                InputSwitchModule,
                InputTextModule,
                InputTextareaModule,
                KnobModule,
                LightboxModule,
                ListboxModule,
                MegaMenuModule,
                MenuModule,
                MenubarModule,

                MultiSelectModule,
                OrderListModule,
                OrganizationChartModule,
                OverlayPanelModule,
                PaginatorModule,
                
                PanelMenuModule,
                PasswordModule,
                PickListModule,
                ProgressBarModule,
                RadioButtonModule,
                RatingModule,
                RippleModule,
                ScrollPanelModule,
                ScrollTopModule,
                SelectButtonModule,
                SidebarModule,
                SkeletonModule,
                SlideMenuModule,
                SliderModule,
                SplitButtonModule,
                SplitterModule,
                StepsModule,
                TableModule,
                TabMenuModule,
                TabViewModule,
                TagModule,
                TerminalModule,
                TimelineModule,
                TieredMenuModule,
                
                ToggleButtonModule,
                ToolbarModule,
                TooltipModule,
                TreeModule,
                TreeTableModule,
                VirtualScrollerModule
        */
    ],

    declarations: [
        
        AppComponent,
        
        AppMainComponent,
        BuyerTopBarComponent,        
        AppMenuComponent,
        AppMenuitemComponent,
        AppBreadcrumbComponent,
        /*
        StagingBannerComponent,
        
        
        ExploreCampaignsComponent,
   
        SignInComponent,
        ConfirmEmailComponent,
        ConfirmEmailPasswordComponent,
        ResetLinkComponent,
        SignUpComponent,
        BuyerMainComponent,
        BuyerViewOrgComponent,
        BuyerDonateComponent,
        ItemDetailComponent,
        ViewCartComponent,
        ViewCheckoutComponent,
        CartBillingAddressComponent,
        ReviewOrderComponent,
        ViewOrdersComponent,
        OrderDetailComponent,
        AfterPaymentGuestComponent,
        OldCheckOutComponent,
        CheckOutGuestComponent,
        CheckOutAddressComponent,
        ResetPasswordComponent,
        NotFoundomponent,
        UserProfileComponent,
        UserPaymentProviderComponent,
        UserEditAddressComponent,
        UserEditPasswordComponent,
        UserEditNameComponent,
        UserEditEmailComponent,
        UserEditRegionComponent,
        UserEditLanguagePrefComponent,
        UserPaypalOnboardedComponent,
        AdminBoardComponent,
        CookieSettingsComponent,
*/


    ],
    providers: [
        
        provideHttpClient(withFetch()),
        StartupService,
        { provide: 'googleTagManagerId', useValue: 'GTM-NC3GJ5ZL' },
        HttpService,
        { provide: TRANSLOCO_SCOPE, useValue: '' },
        
        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig) => {
              return `assets/images/${config.src}-w_${config.width}.jpg`;
            }
         },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: StartupService) =>
                () => configService.loadConfigs(),
            deps: [StartupService],
            multi: true
        },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
        MenuService, BreadcrumbService, ConfirmationService, MessageService,
        CurrencyPipe,
        provideClientHydration()
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
