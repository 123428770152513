import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { fromEvent, merge, Subject, Subscription, tap, throttleTime } from 'rxjs';

import { AuthService } from './service/auth.service';
import { EventBusService } from './service/event-bus,service';
import { TokenStorageService } from './service/token-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'donaqo-app';

  topbarColor = 'layout-topbar-royal';

  menuColor = 'layout-menu-light';

  themeColor = 'orange';

  layoutColor = 'orange';

  topbarSize = 'medium';

  horizontal = true;

  inputStyle = 'outlined';

  ripple = true;

  compactMode = false;

  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;

  eventBusSub?: Subscription;

  userActivity: string | number | NodeJS.Timeout | undefined;
  userInactive: Subject<unknown> = new Subject();

  private subscription: Subscription = new Subscription();

  fromEventSub?: Subscription;


  constructor(private primengConfig: PrimeNGConfig, private tokenStorageService: TokenStorageService,
    private eventBusService: EventBusService,
    private authService: AuthService,
    private messageService: MessageService,
    private translocoService: TranslocoService,
    private route: ActivatedRoute,

    private router: Router,
  ) {

    this.userInactive.subscribe(() => {
      console.log('test2b');
      this.autologout();
    }
    );

  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    console.log('isLoggedIn ', this.isLoggedIn);
    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.tokenStorageService.setLoggedUser(user);
      console.log('saved user ', user);
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');
      //this.username = user.username;
    }

    this.eventBusSub = this.eventBusService.on('logout', () => {
      this.logout();
    });
    

    this.subscription.add(this.authService.currentUser.subscribe(value => {
      if (value === null || Object.keys(value).length === 0) {
        clearTimeout(this.userActivity);
        if (this.fromEventSub) { this.fromEventSub.unsubscribe(); }

      } else {
    
        if (this.fromEventSub) { this.fromEventSub.unsubscribe(); }

        this.fromEventSub = merge(
          fromEvent(window, 'scroll'),
          fromEvent(window, 'mousemove'),
          fromEvent(window, 'touchstart'),
          fromEvent(window, 'touchmove'),
          fromEvent(window, 'mousewheel'),
          fromEvent(window, 'keydown')
        ).pipe(
          throttleTime(3000), // emits once, then ignores subsequent emissions for 300ms, repeat...
          tap(() => this.resetTimeOut())
        ).subscribe();

      }
    }));


  }

  ngOnDestroy(): void {
    if (this.eventBusSub)
      this.eventBusSub.unsubscribe();
  }

  logout(): void {
    this.authService.logout();
    //window.location.reload();
  }

  autologout(): void {
    this.authService.logout();
    this.router.navigate(['/'], { relativeTo: this.route, queryParams: { msgTop: 'expiredSession' }, replaceUrl: false, skipLocationChange: false });
  }


  // This will check if the user is inactive on Pulse window(500ms delay)
  /*
  @HostListener('window:mousemove')
  //@HostListener('window:keydown')
  @HostListener('window:touchstart')
  @HostListener('window:touchmove')
  @HostListener('window:scroll')
  @HostListener('window:mousewheel')  
  @debounce(500)
*/
  resetTimeOut() {
    console.log('test2a');
    clearTimeout(this.userActivity);
    this.setSessionTimeOut();
  }


  // This will check if user is idle for 30 minutes
  setSessionTimeOut() {
    this.userActivity = setTimeout(

      () => {
        clearTimeout(this.userActivity);
        this.userInactive.next(undefined)
      },
      1000 * 60*30

    );
  }
}

