import { HttpBackend, HttpClient } from '@angular/common/http';
import {
  Translation,
  TranslocoLoader, 
  TranslocoModule,
  provideTransloco
} from '@ngneat/transloco';
import { Inject, Injectable, NgModule, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http: HttpClient;
  constructor(private handler: HttpBackend,
    @Inject(PLATFORM_ID)
    private platformId: string) {
    this.http = new HttpClient(handler);
  }
  getTranslation(lang: string) {
    if (isPlatformBrowser(this.platformId)) {
      
      if(lang.startsWith('/')) {
        lang = lang.substring(1);
      }
      
   //  return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
     return import(`../../assets/i18n/${lang}.json`).then(res => res.default);
    }
    else {
      console.log('translation3', this.platformId);

    return this.http.get<Translation>(`${environment.baseUrl}/assets/i18n/${lang}.json`);
    }

  }
}

@NgModule({
  imports: [

  ],
  exports: [TranslocoModule],
  providers: [

    provideTransloco({
      config: {
        availableLangs: ['en', 'fr'],
        defaultLang: 'en',
        fallbackLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        missingHandler: {
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true
        },
        flatten: {
          aot: environment.production
        },
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader
    }),
    provideTranslocoMessageformat(),
    //  { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule { }
