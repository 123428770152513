import { Component, OnInit, Inject, afterNextRender, PLATFORM_ID } from '@angular/core';
import { CommonModule, DOCUMENT, UpperCasePipe, isPlatformBrowser } from '@angular/common';
import { EventData } from 'src/app/service/event.class';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Globals } from '../globals';
import { EventBusService } from '../service/event-bus,service';
import { Subscription } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { TranslocoModule } from '@ngneat/transloco';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [ButtonModule,TranslocoModule,UpperCasePipe,DialogModule,InputSwitchModule, FormsModule,CommonModule,RouterModule],
  selector: 'app-cookie-settings',
  templateUrl: './cookie-settings.component.html',
  styleUrls: ['./cookie-settings.component.scss']
})
export class CookieSettingsComponent implements OnInit {

  isBrowser=false;
  // based on https://medium.com/swlh/angular-include-google-tag-manager-with-analytics-bd24fb74b4fc
  public constructor(@Inject(DOCUMENT) private document: Document,
  @Inject(PLATFORM_ID) private platformId: string,
    private gtmService: GoogleTagManagerService,
    private eventBusService: EventBusService,) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) {
      afterNextRender(() => {
        this.eventBusSub = this.eventBusService.on('cookieSettings', () => {
          console.log('cookieSettingsemitted');
          this.privacyCenter();
        });
    
    
        const analyticsConsent = this.getCookie('analyticsConsent');
        if (analyticsConsent === 'granted' || analyticsConsent === 'denied') {
          this.showBanner = false;
        } else {
          this.showBanner = true;
        }
        if (analyticsConsent === 'granted') {
          this.analyticsCookies = true;
        } else {
          this.analyticsCookies = false;
        }
    
        this.initialAnalyticsCookies = this.analyticsCookies;
    
        const functionalConsent = this.getCookie('functionalConsent');
        if (functionalConsent === 'granted') {
          this.functionalCookies = true;
        } else {
          this.functionalCookies = false;
        }
    
        this.updateConsent();
      });
    }

     }

  public showBanner = false;
  cookiePreferenceDialog = false;
  essentialCookies = true;
  analyticsCookies = false;
  functionalCookies = false;
  initialAnalyticsCookies = false;

  eventBusSub?: Subscription;
  
  ngOnInit(): void {
    if(!this.isBrowser) {
      return;
    }
    this.eventBusSub = this.eventBusService.on('cookieSettings', () => {
      console.log('cookieSettingsemitted');
      this.privacyCenter();
    });


    const analyticsConsent = this.getCookie('analyticsConsent');
    if (analyticsConsent === 'granted' || analyticsConsent === 'denied') {
      this.showBanner = false;
    } else {
      this.showBanner = true;
    }
    if (analyticsConsent === 'granted') {
      this.analyticsCookies = true;
    } else {
      this.analyticsCookies = false;
    }

    this.initialAnalyticsCookies = this.analyticsCookies;

    const functionalConsent = this.getCookie('functionalConsent');
    if (functionalConsent === 'granted') {
      this.functionalCookies = true;
    } else {
      this.functionalCookies = false;
    }

    this.updateConsent();
  }

  private getCookie(cname: string) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  private setCookie(cname: string, cvalue: string, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    this.document.cookie = cname + '=' + cvalue + ';path=/;' + expires;
  }

  private updateConsent() {

    Globals.functionalCookies = this.functionalCookies;
    Globals.analyticsCookies = this.analyticsCookies;

    const gtmTag = {
      event: 'consentUpdate',
    };
    this.gtmService.pushTag(gtmTag);

    if (Globals.hotjarLoaded === false && this.analyticsCookies) {
      this.initialAnalyticsCookies = this.analyticsCookies;
      Globals.hotjarLoaded = true;
      setTimeout(() => {
        const gtmTag = {
          event: 'consentHotjar',
        };
        this.gtmService.pushTag(gtmTag);
      }, 500);

    }

  }

  deleteHotjarCookies(): void {

    const cookiesArray = document.cookie.split(";");


    for (let i = 0; i < cookiesArray.length; i++) {
      //remove if any extra space
      const cookie = cookiesArray[i].trim();
      //to get the cookie name
      const cookieName = cookie.split("=")[0];

      // If the prefix of the cookie's name matches the one specified(i.e search), remove it
      if (cookieName.indexOf("_hj") === 0) {

        // Remove the cookie
        document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
      }
    }
  };



  public acceptAllCookies() {
    this.setCookie('analyticsConsent', 'granted', 365);
    this.setCookie('functionalConsent', 'granted', 365);
    this.analyticsCookies = true;
    this.functionalCookies = true;
    this.showBanner = false;
    this.updateConsent();
    this.cookiePreferenceDialog = false;

  }

  close() {
    if (this.initialAnalyticsCookies && !this.analyticsCookies) {
      this.deleteHotjarCookies();
      document.location.reload();
    }

    this.cookiePreferenceDialog = false;
  }


  public updateAnalyticsCookie() {
    this.getDomain();
    if (this.analyticsCookies === true) {
      this.setCookie('analyticsConsent', 'granted', 365);
    } else {
      this.setCookie('analyticsConsent', 'denied', 365);
      this.document.cookie = '_ga=; path=/;expires = Thu, 01 Jan 1970 00:00:00 GMT;' + this.getDomain() + ';';
      this.document.cookie = '_gid=; path=/;expires = Thu, 01 Jan 1970 00:00:00 GMT;' + this.getDomain() + ';';

    }
    this.updateConsent();
  }

  public updateFunctionalCookie() {

    if (this.functionalCookies === true) {
      this.setCookie('functionalConsent', 'granted', 365);
    } else {
      this.setCookie('functionalConsent', 'denied', 365);
    }
    this.updateConsent();
  }

  getDomain() {
    let domain = window.location.origin;
    domain = domain.substring(domain.indexOf('.'));
    console.log('domain ', domain);
    return 'domain=' + domain;

  }

  public letmeChoose() {
    this.setCookie('analyticsConsent', 'denied', 365);
    this.setCookie('functionalConsent', 'denied', 365);
    this.document.cookie = '_ga=; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/; ' + this.getDomain() + ';';
    this.document.cookie = '_gid=; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/;' + this.getDomain() + ';';
    this.analyticsCookies = false;
    this.functionalCookies = false;
    this.showBanner = false;
    this.cookiePreferenceDialog = true;
  }


  public privacyCenter() {

    this.showBanner = false;
    this.cookiePreferenceDialog = true;

  }

  openPrivacy(): void {
    window.open('/privacy', 'privacy');

  }
}
