
<p-toast position="center" key="toastMain" [preventOpenDuplicates]="true"></p-toast>
<div class="layout-container" (click)="onWrapperClick()"
    [ngClass]="{'layout-menu-horizontal': app.horizontal,
                'layout-menu-active': menuActive,
                'layout-top-small': app.topbarSize === 'small',
                'layout-top-medium': app.topbarSize === 'medium',
                'layout-top-large': app.topbarSize === 'large', 'p-ripple-disabled': !app.ripple, 'p-input-filled': app.inputStyle === 'filled'}"
    [class]="app.topbarColor + ' ' + app.menuColor">

    <div class="layout-top">
        <app-buyer-topbar></app-buyer-topbar>

        <div class="layout-topbar-separator"></div>

        <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="layout-content">
        <p-messages key="msgTop"></p-messages>
<div *ngIf="loggedUser?.previousUserId" class="text-center text-orange-500  bg-white text-3xl ">
    <ng-container *transloco="let t;">{{t('loginAs', {previousLastName: loggedUser.previousLastName, previousFirstName: loggedUser.previousFirstName,
        email: loggedUser.email, firstName: loggedUser.firstName, lastName: loggedUser.lastName
    })}}</ng-container>
</div>
        <router-outlet></router-outlet>
    </div>



    <div class="layout-mask" *ngIf="menuActive" [@mask-anim]="'visible'"></div>
    
    <app-footer></app-footer>
    

    <div class="layout-config-mask" *ngIf="configDialogActive" [@mask-anim]="'visible'"></div>

</div>