import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Globals } from '../globals';
import { LoginUserModel } from '../model/shared.model';
import { EventBusService } from './event-bus,service';
import { EventData } from './event.class';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorage } from './local-storage';
import { SessionStorage } from './session-storage';
const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const PUBLICTOKEN_KEY = 'pub-token';
const DEVICETOKEN_KEY = 'deviceToken';


@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  isBrowser=false;

  constructor(    @Inject(PLATFORM_ID) private platformId: string,
   private eventBusService: EventBusService,
   private localStorage:  LocalStorage,
   private sessionStorage:  SessionStorage) {

    this.isBrowser = isPlatformBrowser(platformId);
   }

  signOut(): void {
    //this.sessionStorage.clear();
    this.sessionStorage.removeItem(TOKEN_KEY);
    this.sessionStorage.removeItem(REFRESHTOKEN_KEY);
    this.sessionStorage.removeItem(USER_KEY);
    this.sessionStorage.removeItem('cartId');
    this.sessionStorage.removeItem('orderId');
    this.sessionStorage.removeItem('newOrg');
    this.sessionStorage.removeItem('newItem');
    this.sessionStorage.removeItem('newCampaign');
  }

  public saveToken(token: string): void {
    this.sessionStorage.removeItem(TOKEN_KEY);
    this.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public savePublicToken(token: string): void {
    this.localStorage.removeItem(PUBLICTOKEN_KEY);
    this.localStorage.setItem(PUBLICTOKEN_KEY, token);
  }


  public getToken(): string | null {
    return this.sessionStorage.getItem(TOKEN_KEY);
  }

  public getPublicToken(): string | null {
    return this.localStorage.getItem(PUBLICTOKEN_KEY);
  }

  public saveRefreshToken(token: string): void {
    this.sessionStorage.removeItem(REFRESHTOKEN_KEY);
    this.sessionStorage.setItem(REFRESHTOKEN_KEY, token);
  }

  public saveDeviceToken(userId: string, token: string): void {
    const jsonStr= this.localStorage.getItem(DEVICETOKEN_KEY);
    console.log('deviceTokenstr',jsonStr);
    console.log('deviceTokenstruserId',userId);
    if(token==null) return;
    if(jsonStr!=null) {
    const deviceToken=JSON.parse(jsonStr);

    deviceToken[userId]= token;
    this.localStorage.setItem(DEVICETOKEN_KEY, JSON.stringify(deviceToken));
    } else {

      const deviceToken = {[userId]: token};
      this.localStorage.setItem(DEVICETOKEN_KEY, JSON.stringify(deviceToken));
    }
    


    
  }

  public getRefreshToken(): string | null {
    return this.sessionStorage.getItem(REFRESHTOKEN_KEY);
  }


  public saveUser(user: LoginUserModel): void {
    this.sessionStorage.removeItem(USER_KEY);
    delete user.accessToken;
    delete user.refreshToken;

    this.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  
  public getUser(): any {
    if(this.isBrowser) {
    const user = this.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
  }
    return {};
  }

  public setLoggedUser(user: LoginUserModel): void {

  Globals.loggedUser = Globals.getEmptyUser();

  
  Globals.loggedUser.firstName = user.firstName;
  Globals.loggedUser.lastName =user.lastName;
  Globals.loggedUser.email =user.email;
  Globals.loggedUser.accessToken = user.accessToken;
  Globals.loggedUser.refreshToken = user.refreshToken;
  Globals.loggedUser.tenantId= user.tenantId;
  Globals.loggedUser.roles = user.roles;
  Globals.loggedUser.userId = user.userId;
  Globals.loggedUser.lang = user.lang;
  Globals.loggedUser.previousFirstName = user.previousFirstName;
  Globals.loggedUser.previousLastName = user.previousLastName;
  Globals.loggedUser.previousRoles = user.previousRoles;
  Globals.loggedUser.previousUserId = user.previousUserId;

  //const eventData:EventData= new EventData('loggedUser', Globals.loggedUser);
  //this.eventBusService.emit(eventData);
  }
}