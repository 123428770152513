import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, of, switchMap, take, throwError } from 'rxjs';
import { Globals } from '../globals';
import { AuthService } from '../service/auth.service';
import { TokenStorageService } from '../service/token-storage.service';
import { URL_REFRESH_TOKEN } from '../service/urls';
import { MessageService } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private accessTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private translocoService: TranslocoService,
    private tokenStorageService: TokenStorageService,
    private route: ActivatedRoute,
    private router: Router

  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    console.log('JwtInterceptor here ', request.url);
    if (request.url.startsWith(Globals.domainAPI) && !request.url.endsWith(URL_REFRESH_TOKEN)) {
      if (request.headers.get('Authorization') === null) {
        const currentUser = this.authService.currentUserValue;
        if (currentUser &&  this.tokenStorageService.getToken()) {
          const tenantId = currentUser.tenantId;

          if (tenantId == undefined) {

            this.authService.logout();
          }
          console.log('JwtInterceptor use access token');
          request = request.clone({
            setHeaders: {
              Authorization: `${this.tokenStorageService.getToken()}`
            }
          });
        } else {


          if (Globals.publicToken !== '') {
          }
          console.log('JwtInterceptor use public token');
          request = request.clone({
            setHeaders: {
              Authorization: `${Globals.publicToken}`
            }
          });
        }
      }
    }
    //return next.handle(request);
    return next.handle(request).pipe(catchError(error => {
      console.log('next.handle ', request.url);
      if (error instanceof HttpErrorResponse && request.url.includes('/refreshToken')
        && error.status === 401) {
        this.isRefreshing = false;
        this.authService.logout();
        this.messageService.add({ key: 'msgTop', severity: 'info', summary: '', detail: this.translocoService.translate('expiredSession') });
        this.router.navigate(['/'], { relativeTo: this.route, queryParams: { msg: 'expiredSession' }, replaceUrl: false, skipLocationChange: false });

      }
      if (error instanceof HttpErrorResponse && !request.url.includes('api/loginUserNotUser') && error.status === 401) {
        return this.handle401Error(request, next);
      }
      if (error instanceof HttpErrorResponse && error.status === 403) {
        //this.authService.logout();
        this.messageService.add({ key: 'toastMain', severity: 'error', summary: '', detail: this.translocoService.translate('unauthorized'), life: 3000 });
        this.router.navigate(['/'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });

      }
      return throwError(() => error);

    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    console.log('jwtInterceptor refresh token here');
    console.log('jwtInterceptor isRefreshing', this.isRefreshing);
    if (!this.isRefreshing) {

      this.isRefreshing = true;
      this.accessTokenSubject.next(null);
      const refreshToken = this.tokenStorageService.getRefreshToken();
      console.log('jwtInterceptor refreshToken', refreshToken);
      let tokenUsed: string | null = '';
      if (request.headers.get('Authorization') != null) {
        tokenUsed = request.headers.get('Authorization');
        console.log('jwtInterceptor tokenUsed', tokenUsed);

      }

      if (refreshToken && tokenUsed != Globals.publicToken) {
        console.log('jwtInterceptor refreshtoken private', refreshToken);
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((newToken: any) => {
            console.log('jwtInterceptor new access & refresh token updated is ', newToken);

            this.tokenStorageService.saveToken(newToken.accessToken);
            this.tokenStorageService.saveRefreshToken(newToken.refreshToken);
            this.accessTokenSubject.next(newToken.accessToken);
            this.isRefreshing = false;
            return next.handle(this.addTokenHeader(request, newToken.accessToken));
          }),
          catchError((err) => {
            console.log('new user token err', err);

            this.isRefreshing = false;
            this.authService.logout();
            this.messageService.add({ key: 'msgTop', severity: 'info', summary: '', detail: this.translocoService.translate('expiredSession') });
            //this.router.navigateByUrl('/');
            //this.router.navigate(['/']);
            this.router.navigate(['/'], { relativeTo: this.route, queryParams: { msg: 'expiredSession' }, replaceUrl: false, skipLocationChange: false });

            //                this.messageService.add({ key:'toastMain', severity: 'error', summary: this.translocoService.translate('expiredSession'), detail: '' });
            //             
            //            window.open('/');
            //   this.router.navigateByURL([ '/'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });

            return of(err);
            //return throwError(() => err);
          })
        );
      } else {
        //public token load new
        console.log('jwtInterceptor refresh public token', refreshToken);
        return this.authService.refreshPublicToken().pipe(
          switchMap((newToken: any) => {
            console.log('jwtInterceptor updated  public token is ', newToken);

            this.tokenStorageService.savePublicToken(newToken.token);
            this.accessTokenSubject.next(newToken.token);
            this.isRefreshing = false;
            return next.handle(this.addTokenHeader(request, newToken.token));
          }),
          catchError((err) => {
            console.log('guest token err', err);
            this.isRefreshing = false;

            this.tokenStorageService.signOut();
            return throwError(err);
          })
        );

      }
    }
    return this.accessTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    request = request.clone({
      setHeaders: {
        Authorization: `${token}`
      }
    });

    return request;
  }
}

