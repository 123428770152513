<div class="layout-footer">
    <div class="grid">
        <div class="text-center col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6  md:text-left">
            <img src="assets/layout/images/kmdwhitepath.svg" alt="sapphire-layout" />
            <br>


            <div class="layout-footer-appname">
                <ng-container *transloco="let t">{{ t('donateForYourCause' ) }}</ng-container>
            </div><br />
            <ng-container *transloco="let t">{{ t('karmaMileDonationInc')}}</ng-container><br />
            2040 Marcel Laurin #240<br />
            Saint-Laurent, Qc, H4R 1J9
        </div>
        <div class="text-center col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 md:text-right">
            <br /><br />
            <a href="/privacy"><ng-container *transloco="let t">{{ t('privacyPolicy')}}</ng-container></a><br /><br />
            <a href="/terms"><ng-container *transloco="let t">{{ t('termsConditions')}}</ng-container></a><br /><br />
            <span class="cursor-pointer link-color" (click)="showCookieSettings()"><ng-container *transloco="let t">{{
                    t('privacySettings')}}</ng-container></span><br /><br />





        </div>





    </div>
    <div class="grid">
        <div class="col-12 text-center">

            <span (click)="openCountryDialog()" class="cursor-pointer link-color" *ngIf="false">
                {{selectedRegion.label}} &nbsp;| </span>
            &nbsp;<span class="cursor-pointer link-color"
                (click)="openLanguageDialog()">{{selectedLanguage.label}}</span>
        </div>
    </div>
    <!--
    <div class="grid">
        <div class="col-12 text-center">
            <p-dropdown [options]="regionList" [(ngModel)]="selectedRegion" optionLabel="label"   (onChange)="changeRegion()">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedRegion">
                        <span [class]="'fi fi-' + selectedRegion.value.toLowerCase()"></span>
                        
                        <div>{{ selectedRegion.label }}</div>
                    </div>
                </ng-template>
                <ng-template let-region pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <span [class]="'fi fi-' + region.value.toLowerCase()"></span>
                        
                        <div>{{ region.label }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
          &nbsp;&nbsp;
          <p-dropdown [options]="languageList" [(ngModel)]="selectedLanguage" optionLabel="label"   (onChange)="changeLanguage()">
           
        </p-dropdown>
         </div>
          </div>
    -->
    <!--
     
            <span *ngIf="selectedLanguage=='fr'">
            <a routerLink="[]"  (click)="changeLanguage()" ><ng-container *transloco="let t">{{ t('english' ) }}</ng-container></a>
            &nbsp;
            <ng-container *transloco="let t">{{ t('french' ) }}</ng-container>
        </span>

        <span *ngIf="selectedLanguage=='en'">
            <ng-container *transloco="let t">{{ t('english' ) }}</ng-container>
                    &nbsp;
            <a routerLink="[]"  (click)="changeLanguage()" ><ng-container *transloco="let t">{{ t('french' ) }}</ng-container></a>
            
            
        </span>
   

    </div>
          -->


    <div class="grid">
        <div class="col-12 text-center">
            <span>Ⓒ 2024 - <ng-container *transloco="let t">{{ t('allRightsReserved' ) }}</ng-container></span>
        </div>
    </div>
</div>
<p-dialog [header]="'selectLanguage' | transloco" [modal]="true" [closable]="true" closeIcon="pi pi-times"
    [(visible)]="languageDialog" [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden', 'width':'500px','max-width': '98vw','min-width': '20vw',
             'max-height': '80vh'}">



    <br />

    <div class="grid">
        <div class="col-6 text-center">
            <span class="text-900 text-2xl">
                <span class="cursor-pointer link-color" (click)="setLanguage('en')">English</span>
            </span>
        </div>
        <div class="col-6 text-center">
            <span class="text-900 text-2xl">
                <span class="cursor-pointer link-color" (click)="setLanguage('fr')">Français</span>
            </span>
        </div>
    </div>

</p-dialog>
<p-dialog [header]="'selectRegion' | transloco" [modal]="true" [closable]="true" closeIcon="pi pi-times"
    [(visible)]="countryDialog" [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden', 'width':'500px','max-width': '98vw','min-width': '20vw',
             'max-height': '80vh'}">



    <br />

    <div class="grid">
        <div class="text-left col-12 md:col-6 ">
            <span class="text-900 text-2xl">
                <span [routerLink]="" class="cursor-pointer link-color" (click)="setRegion('CA')"> <span
                        [class]="'fi fi-ca'"></span>&nbsp; <ng-container *transloco="let t">{{ t('canada' )
                        }}</ng-container></span>
            </span>
        </div>
        <div class="text-left col-12 md:col-6 ">
            <span class="text-900 text-2xl">
                <span class="cursor-pointer link-color" (click)="setRegion('US')"> <span
                        [class]="'fi fi-us'"></span>&nbsp; <ng-container *transloco="let t">{{ t('unitedStates' )
                        }}</ng-container></span>
            </span>
        </div>
        <!--
        <div class="text-left col-12 md:col-6 ">
            <span class="text-900 text-2xl">
                <span  class="cursor-pointer link-color"  (click)="setRegion('FR')"> <span [class]="'fi fi-fr'"></span>&nbsp; <ng-container
                        *transloco="let t">{{ t('france' ) }}</ng-container></span>
            </span>
        </div>

        <div class="text-left col-12 md:col-6 ">
            <span class="text-900 text-2xl">
                <span  class="cursor-pointer link-color"  (click)="setRegion('ES')"> <span [class]="'fi fi-es'"></span>&nbsp; <ng-container
                        *transloco="let t">{{ t('spain' ) }}</ng-container></span>
            </span>
        </div>
        -->
    </div>

</p-dialog>