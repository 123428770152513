<div class="grid">
  <div class="col-12 lg:col-6 lg:col-offset-3">

    <form [formGroup]="form">
      <div class="surface-card p-4 shadow-2 border-round w-full">
        <div class="text-center mb-5">
          <div class="text-900 text-3xl font-medium mb-3"><ng-container
              *transloco="let t;">{{t('phone')}}</ng-container> </div>

        </div>

        <div class="text-center mb-5">
          <div class="text-900 text-base font-medium mb-3"><ng-container
              *transloco="let t;">{{t('addPhoneKeepSafe')}}</ng-container></div>

        </div>

        <app-intl-phone-input [value]="inputPhone" [defaultIsoCountry]="defaultIsoCountry" [fullWidth]="true"
          [inputPhoneInvalid]="inputPhoneInvalid" (phoneChange)="phoneChangeEmitter($event)"
          *ngIf="defaultIsoCountry!==null && loaded()"></app-intl-phone-input>



        <p-messages [(value)]="signInPageMsgs"></p-messages>
        <br />
        <div class="grid  align-items-center">
          <div class="col-12">
            <button pButton pRipple [label]="'sendCode' | transloco" class="w-full"
              (click)="verifyNumber()"></button>
          </div>
        </div>


      </div>


    </form>
  </div>
</div>
<p-dialog [header]="'emailChanged' | transloco" [modal]="true" [closable]="true" closeIcon="pi pi-times"
  (onHide)="closeValidateEmailDialog()" [(visible)]="validateEmailDialog" [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden', 'max-width': '100vw','min-width': '20vw',
             'max-height': '80vh'}">

  <ng-container *transloco="let t;">{{t('weSentEmail')}}</ng-container>
  <b>{{email}}</b>.<br /><br />
  <ng-container *transloco="let t;">{{t('clickOnLinkToValidateEmail')}}</ng-container>
  <br /><br /><br />

  <br />


</p-dialog>

<p-dialog [modal]="true" [closable]="false" closeIcon="" [(visible)]="confirmPhoneDialog"
  [contentStyle]="{'max-width': '99vw', 'width':'500px'}" [styleClass]="'customDialog'">
  <ng-template pTemplate="header">

    <div class="grid">
      <div class="col-11">

        <span class="blue-title">
          <ng-container *transloco="let t;">{{t('confirmPhone') | uppercase}}</ng-container>
        </span>
      </div>
      <div class="col-1 text-right">
        <a [routerLink]="[]" (click)="closeConfirmPhoneDialog()" class="text-lg" data-id="closeConfirmPhoneDialog">

          <i class="pi pi-times"></i>
        </a>


      </div>
      <div class="blue-line"></div>
    </div>



  </ng-template>
  <br>
  <div class="grid">
    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 align-center">

      <ng-container *transloco="let t;">{{t('toMakeSurePhoneIsYours')}}</ng-container>
      <br><br>
      <span class="p-input-icon-right">
        <input input="text" pInputText readonly value="{{verifyPhone}}">



        <i class="pi pi-pencil" (click)="closeConfirmPhoneDialog()" [title]="'edit' | transloco"></i>



      </span>


    </div>
  </div>





  <br><br>


  <div class="grid">


    <div class="col-6">

      <button type="button" pButton (click)="closeConfirmPhoneDialog()" data-id="closeConfirmPhoneDialog"
        [label]="'cancel' | transloco | uppercase"></button>
    </div>
    <div class="col-6 align-right">

      <button type="button" pButton (click)="verifyNumber()" data-id="getCode"
        [label]="'getCode' | transloco | uppercase"></button>
    </div>




  </div>

</p-dialog>

<p-dialog [header]="'awaitingVerification' | transloco" [modal]="true" [closable]="true"
  [(visible)]="smsVerificationDialog" [contentStyle]="{'max-width': '99vw', 'width':'500px'}">

  <br>
  <div class="grid">
    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 text-center">

      <ng-container *transloco="let t;">{{t('codeHasBeenSentTo',{phone:verifyPhone})}}</ng-container>
      <br><br>
      <form [formGroup]="otpForm">
        <input #otpRef type="text" pInputText formControlName="otp" type="text" autocomplete="one-time-code"
          inputmode="numeric" pattern="[0-9]*" (keyup)="shareUtils.numberMobile($event)"
          (keydown)="otpValidation($event)" (keydown.enter)="verifyOTPCode()">
        <br>
        <span *ngIf="otpForm.get('otp')!.invalid  && otpForm.get('otp')!.hasError('invalid') && (otpForm.get('otp')!.dirty ||
			otpForm.get('otp')!.touched)" class="text-red-600">
          <ng-container *transloco="let t;">{{t('invalidCode')}}</ng-container>
        </span>
      </form>
    </div>
  </div>





  <br><br>


  <div class="grid">


    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 text-center">

      <button type="button" pButton (click)="verifyOTPCode()" data-id="proceedAndSecure"
        [label]="'proceedAndSecure' | transloco | uppercase"></button>
    </div>
    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 text-center">
      <u><a [routerLink]="[]" (click)="closeSmsVerificationDialog()" data-id="changePhoneNumber">
          <ng-container *transloco="let t;">{{t('changePhoneNumber')}}</ng-container>
        </a></u>
    </div>

    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 text-center">

      <button type="button" pButton (click)="resentSMSCode()" data-id="resentSMS" [disabled]="isResentSMSBtnDisabled">
        <span *ngIf="time>0"><ng-container *transloco="let t;">{{t('resendSMS',{time: time})}}</ng-container></span>
        <span *ngIf="time<=0"><ng-container *transloco="let t;">{{t('resendSMS',{time: ''})}}</ng-container></span>
      </button>
    </div>
  </div>

</p-dialog>


<p-dialog [showHeader]="false" [modal]="true" [closable]="false" closeIcon="" [(visible)]="mobileAlreadyVerifiedDialog"
  [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden', 'max-width': '80vw',
		   'max-height': '80vh'}">
  <br>
  <div class="grid">
    <div class="col-12 text-center">
      

  <ng-container *transloco="let t;">{{t('phoneAlreadyVerified') }}</ng-container>
  <br/>
  <br/>



    <button type="button" pButton icon="pi pi-check" (click)="closeMobileAlreadyVerifiedDialog()"
      [label]="'ok' | transloco | uppercase"></button>

</div>
</div>

</p-dialog>
<p-dialog [showHeader]="false" [modal]="true" [closable]="false" closeIcon="" [(visible)]="cantValidatedSMSDialog"
  [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden', 'max-width': '80vw',
		   'max-height': '80vh'}">
  <br>
  <div class="grid">
    <div class="col-12">
      <span class="blue-title">
        <b>
          <ng-container *transloco="let t;">{{t('phoneVerificationError') | uppercase}}</ng-container>
        </b>
      </span>

    </div>
    <div class="blue-line"></div>
  </div>




  <p-footer>

    <button type="button" pButton icon="pi pi-check" (click)="cantVerifiedSMS()"
      [label]="'ok' | transloco | uppercase"></button>


  </p-footer>
</p-dialog>