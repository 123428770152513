<span [ngClass]="fullWidth ? 'phonecontainer':''" >
	<p-dropdown [options]="allCountries" appendTo="body" [(ngModel)]="selectedCountry" (onChange)="onPressvalidatePhone()"  [autofocusFilter]="true"
		styleClass="countrycode-dropdown "  [filterBy]="'name,dialCode'" [filterFields]="['name','dialCode']" [showClear]="false"  [filter]="true" optionLabel="dialCode"
	
		[emptyFilterMessage]="'notFound' | transloco">
		<ng-template pTemplate="filter" let-options="options">
			<div class="flex">
				<div class="p-inputgroup" (click)="$event.stopPropagation()">
					<input type="text" pInputText [placeholder]="'search' | transloco" [(ngModel)]="filterValue" class="w-11rem"
						(keyup)="options.filter($event)">
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="selectedItem">
			<div class="country-item country-item-value" *ngIf="selectedCountry">
				<img [class]="'flag  ' + selectedCountry.iso2.toUpperCase()" />&nbsp;+{{selectedCountry.dialCode}}
			</div>

		</ng-template>

		<ng-template let-country pTemplate="item">



			<div class="country-item">
				<img [class]="'flag ' + country.iso2.toUpperCase()" /> +{{country.dialCode}}
				<div>{{country.name}}</div>
			</div>
		</ng-template>
	</p-dropdown>
	<span class="inputcontainer  ">
		<input #phoneRef pInputText type="text" [(ngModel)]="phone" (focus)="onFocusInput()"
			(keydown.enter)="emitEnter($event)" (input)="onPressvalidatePhone()" (change)="onPressvalidatePhone()"
			class="phone-element " [style.background-image]="'url(' + srcPhoneUrl + ')'">


	</span>
</span>
<span *ngIf="inputPhoneInvalid " class="red">
	<br>

	<ng-container *transloco="let t;">{{t('invalidPhoneNumber')}}
	</ng-container>

</span>