import { ThisReceiver } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppMainComponent } from './app.main.component';
import { Globals } from './globals';
import { AuthService } from './service/auth.service';
import { EventBusService } from './service/event-bus,service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit, OnDestroy {

    model: any[]=[];
    eventBusSub?: Subscription;

    constructor(public appMain: AppMainComponent,
        private eventBusService: EventBusService,
        private translocoService: TranslocoService,
        private authService: AuthService) {}

    ngOnInit() {
        this.updateMenu(0);
        this.eventBusSub = this.eventBusService.on('itemsInCart', (value: number) => {
            console.log('emitted cart value ', value);
        this.updateMenu(value);


        });
    }

    ngOnDestroy(): void {
        if (this.eventBusSub)
            this.eventBusSub.unsubscribe();
    }

    updateMenu(nbItem: number): void {
        this.model = [

            {
                label:this.translocoService.translate('home'), icon: 'pi pi-fw pi-home', routerLink: ['/'],
                visible: true
            },
            {
                label: this.translocoService.translate('explore'),  icon: 'pi pi-fw pi-gift', routerLink: ['/explore'],
                visible: true
                /*
                items: [
                    {label: 'Start a campaign', icon: 'pi pi-fw pi-user-plus', routerLink: ['/campaign/new']},
                    {label: 'My campaigns', icon: 'pi pi-fw pi-user-minus',routerLink: ['/campaign']},
                    {label: 'Settings', icon: 'pi pi-fw pi-user-minus',routerLink: ['/campaign/settings']}
                    
                ]
                */
            },
            {
                label: 'Organization',  icon: 'pi pi-fw pi-shopping-cart', routerLink: ['/org'],
                visible: false
       
            },
            {
                label: 'Volunteer', icon: 'pi pi-fw pi-shopping-cart', routerLink: ['/volunter'],
                visible: false,
                items: [
                    {label: 'My items', icon: 'pi pi-fw pi-user-plus', routerLink: ['/volunteer/items']},
                    {label: 'Add new item', icon: 'pi pi-fw pi-user-plus', routerLink: ['/volunteer/addItem']},
                    {label: 'preferences', icon: 'pi pi-fw pi-user-plus', routerLink: ['/volunteer/preferences']},
                    
                    
                    {
                        label: 'Listings', icon: 'pi pi-fw pi-shopping-cart', routerLink: ['listings']
                    },
                    
                    
                ]

            },
            
            {
                label:this.translocoService.translate('shop'),  icon: 'pi pi-fw pi-shopping-bag', routerLink: ['/shop']
            },
           
            {
                label: 'Cart', badge: nbItem,badgeStyleClass:'-mr-4', icon: 'pi pi-fw pi-shopping-cart', routerLink: ['/cart'],
                visible: false
            },
            {
                label: 'Admin',  icon: 'pi pi-fw pi-shopping-cart', routerLink: ['/admin'],
                visible: Globals.loggedUser?.roles?.includes('admin') ?? false,
            },
            
      
        ];
    }

    logout(): void {
        this.authService.logout();
        
    }
}
