import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppMainComponent } from 'src/app/app.main.component';
import { EventBusService } from 'src/app/service/event-bus,service';
import { EventData } from 'src/app/service/event.class';
import { Globals } from '../../globals';
import { AuthService } from '../../service/auth.service';
import { HttpParams } from '@angular/common/http';
import { URL_ADMINUSER_SWITCHBACKUSER } from 'src/app/service/urls';
import { LoginUserModel } from 'src/app/model/shared.model';
import { HttpService } from 'src/app/service/http.service';
import { ShareUtils } from 'src/app/service/shareutils';



@Component({
  selector: 'app-buyer-topbar',
  styleUrls: ['./buyer-topbar.component.scss'],
  templateUrl: './buyer-topbar.component.html'
})
export class BuyerTopBarComponent implements OnInit, OnDestroy {

  selectedLanguage = Globals.webSiteLang;
  firstName = '';
  lastName = '';
  signInDialog = false;
  pictureURL = '';
  itemsInCart = '';
  site = Globals.site;
  loggedUser = Globals.loggedUser;
  languageList: SelectItem[] = [];

  private subscription: Subscription = new Subscription();
  eventBusSub?: Subscription;

  constructor(public app: AppMainComponent,
    private route: ActivatedRoute,
    private router: Router,
    private eventBusService: EventBusService,
    public authService: AuthService,
    private shareUtils: ShareUtils,
    private httpSvc: HttpService,
    private translocoService: TranslocoService,
    @Inject(TRANSLOCO_SCOPE) private scope: any,
  ) { }

  ngOnInit(): void {


    if (Globals.loggedUser != undefined) {
      this.firstName = Globals.loggedUser.firstName == undefined ? '' : Globals.loggedUser.firstName;
      this.lastName = Globals.loggedUser.lastName == undefined ? '' : Globals.loggedUser.lastName;
      this.pictureURL = Globals.loggedUser.pictureURL == undefined ? '' : Globals.loggedUser.pictureURL;



    }
    this.subscription.add(this.translocoService.selectTranslate('translationLoaded', {}, this.scope).subscribe(value => {
      this.languageList = [
        { label: this.translocoService.translate('english'), value: 'en' },

        { label: this.translocoService.translate('french'), value: 'fr' }
      ];
    }));
    this.subscription.add(this.authService.currentUser.subscribe(value => {

      if (Globals.loggedUser != undefined) {
        this.firstName = Globals.loggedUser.firstName == undefined ? '' : Globals.loggedUser.firstName;
        this.lastName = Globals.loggedUser.lastName == undefined ? '' : Globals.loggedUser.lastName;
        this.pictureURL = Globals.loggedUser.pictureURL == undefined ? '' : Globals.loggedUser.pictureURL;

      } else {
        this.firstName = '';
        this.lastName = '';
        this.pictureURL = '';
      }
    }));

    this.eventBusSub = this.eventBusService.on('itemsInCart', (value: number) => {
      console.log('emitted cart value ', value);
      this.itemsInCart = String(value);

    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  changeLanguage(): void {

    localStorage.setItem('language', this.selectedLanguage);

    window.location.reload();
  }

  logout(): void {
    this.authService.logout();

  }


  switchBackUser() {

    const params = new HttpParams();

    this.subscription.add(this.httpSvc.get<LoginUserModel>(URL_ADMINUSER_SWITCHBACKUSER, params).subscribe({
      next:
        (data) => {
          console.log('newData', data);

          this.authService.updateUser(data);

          window.location.reload();
          this.router.navigate(['/'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });       

          
        },
      error:
        err => {

          console.log('data err', err);


        }
    }
    ));
  }


  goToBank(event: Event): void {
    event?.preventDefault();
    this.router.navigate(['/bank'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }

  goToOrders(event: Event): void {
    event?.preventDefault();
    this.router.navigate(['/orders'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }



  goToVoucher(event: Event): void {
    event?.preventDefault();
    this.router.navigate(['/volunteer/voucher'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }


  goToSignIn(): void {
    this.router.navigate(['/signIn'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }
  goToHome(): void {
    this.router.navigate(['/'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }




  goToProfile(event: Event): void {
    event?.preventDefault();
    this.router.navigate(['/profile'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }



  goToCampaigns(event: Event): void {
    event?.preventDefault();
    this.router.navigate(['/campaign'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }

  goToCart(event: Event): void {
    event?.preventDefault();
    this.router.navigate(['/cart'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }

  goToOrganizations(event: Event): void {
    event?.preventDefault();
    this.router.navigate(['/org'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }


  goToItems(event: Event): void {
    event?.preventDefault();
    this.router.navigate(['/volunteer/items'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }

  signIn(): void {
    this.eventBusService.emit(new EventData('signInRestart', true));
    if(this.shareUtils.isMobile()) {
    this.goToSignIn();
    } else {
    this.signInDialog = true;
    }
  }



  action(nextAction: string): void {
    console.log('nextAction', nextAction);
    if (nextAction != 'emailNotValidated') {
      this.signInDialog = false;
    }
  }
}
