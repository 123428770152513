import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppMainComponent } from './app.main.component';
import { StagingBannerComponent } from './buyer/staging-banner/staging-banners.component';
import { UserDashBoardComponent } from './user-dashboard/user-dashboard.component';


@NgModule({
    imports: [
        RouterModule.forRoot([
          {path: 'staging', component: StagingBannerComponent},
          
            {
              
                path: 'admin',
                loadChildren: () => import('./admin/admin-module/admin.module').then(mod => mod.AdminModule)

              },
              {
                path: 'org',
                loadChildren: () => import('./org/org-module/org.module').then(mod => mod.OrgModule)

              },           
              {
                path: 'volunteer',
                loadChildren: () => import('./seller/seller-module/seller.module').then(mod => mod.SellerModule)

              },                   
              {
                path: 'campaign',
                loadChildren: () => import('./raiser/raiser-module/raiser.module').then(mod => mod.RaiserModule)

              },                 
            {
                path: '', component: AppMainComponent,
                loadChildren: () => import('./buyer/buyer-module/buyer.module').then(mod => mod.BuyerModule)
            },

            /*
            {path: 'error', component: AppErrorComponent},
            {path: 'access', component: AppAccessdeniedComponent},
           
            {path: 'login', component: AppLoginComponent},

            
            */            
            {path: '**', redirectTo:'/notFound'},

        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
