import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { Globals } from 'src/app/globals';
import { AuthService } from 'src/app/service/auth.service';
import { EventBusService } from 'src/app/service/event-bus,service';
import { HttpService } from 'src/app/service/http.service';
import { ShareUtils } from 'src/app/service/shareutils';
import { TokenStorageService } from 'src/app/service/token-storage.service';


@Component({
  selector: 'app-staging-banner',
  templateUrl: './staging-banner.component.html',
  styleUrls: ['./staging-banner.component.scss']
})
export class StagingBannerComponent implements OnInit, OnDestroy {

  @Output() category = new EventEmitter<string>();

  site=Globals.site;
  stagingToken='';
  lang = Globals.webSiteLang;
  private subscription: Subscription = new Subscription();

  constructor(
    private tokenStorage: TokenStorageService,
    fb: UntypedFormBuilder,
    private authService: AuthService,
    
    private httpSvc: HttpService,
    public shareUtils: ShareUtils,
    private route: ActivatedRoute,
    private eventBusService: EventBusService,
    private translocoService: TranslocoService,
    private router: Router
  ) {



  }

  ngOnInit(): void {
    console.log('started');
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    
  }

  checkStagingPassword(): void {
    localStorage.setItem("stagingToken", this.stagingToken);
    window.location.reload();
  }
}

