<form [formGroup]="signInForm">
    <div class="surface-card p-4 shadow-2 border-round w-full">
        <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">
                <ng-container *transloco="let t;">{{t('signUp')}}</ng-container>
            </div>
            <span class="text-600 font-medium line-height-3">
                <ng-container *transloco="let t;">{{t('alreadyHaveAccount')}}</ng-container>
            </span>
            <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="signIn()">
                <ng-container *transloco="let t;">{{t('signIn')}}</ng-container>
            </a>
        </div>

        <div>
            <label for="firstName" class="block text-900 font-medium mb-2">
                <ng-container *transloco="let t;">{{t('firstName')}}</ng-container>
            </label>
            <input type="firstName" pInputText class="w-full mb-3" autofocus formControlName="firstName"
                (keydown.space)="$event.preventDefault();">

            <label for="lastName" class="block text-900 font-medium mb-2">
                <ng-container *transloco="let t;">{{t('lastName')}}</ng-container>
            </label>
            <input type="lastName" pInputText class="w-full mb-3" autofocus formControlName="lastName"
                (keydown.space)="$event.preventDefault();">

            <label for="emailMobile" class="block text-900 font-medium mb-2">
                <ng-container *transloco="let t;">{{t('email')}}</ng-container>
            </label>
            <input type="emailMobile" pInputText class="w-full mb-3" autofocus formControlName="emailMobile"
                (keydown.space)="$event.preventDefault();">




            <label for="password" class="block text-900 font-medium mb-2">
                <ng-container *transloco="let t;">{{t('password')}}</ng-container>
            </label>
			<span class="p-input-icon-right">
                <input [type]="isPasswordFieldType()? 'password' : 'text'" pInputText class="w-full mb-3" 
                    formControlName="password" (blur)="checkComplexityPassword()"
                    (paste)="checkComplexityPassword()">
                <i class="pi pi-eye" *ngIf="isPasswordFieldType" (click)="togglePasswordFieldType()"
                    [title]="'showPassword' | transloco"></i>
                <i class="pi pi-eye-slash" *ngIf="!isPasswordFieldType"
                    (click)="togglePasswordFieldType()" [title]="'hidePassword' | transloco"></i>


            </span>

            <span *ngIf="signInForm.get('password')?.invalid  && (signInForm.get('password')?.dirty ||
                signInForm.get('password')?.touched) && (signInForm.get('password')?.hasError('length') ||
                signInForm.get('password')?.hasError('lowercase') ||
                signInForm.get('password')?.hasError('uppercase') ||
                signInForm.get('password')?.hasError('number') ||
                signInForm.get('password')?.hasError('symbol'))" class="red">
                <ng-container *transloco="let t;">{{t('passwordMusMeetRequirements')}}</ng-container>
            </span>

            <p-messages [(value)]="signInPageMsgs"></p-messages>


            <button pButton pRipple [label]="'continue' | transloco" class="w-full" (click)="signUp()"></button>
        </div>
    </div>
</form>