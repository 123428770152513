import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ReturnStatement } from '@angular/compiler';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output, PLATFORM_ID, ViewChild, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { accounts } from 'google-one-tap';
import { Message } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { Subscription, first } from 'rxjs';
import { Globals } from 'src/app/globals';
import { BuyerViewCartModel, LoginMethodModel, LoginUserModel, QueryResultModel } from 'src/app/model/shared.model';
import { AuthService } from 'src/app/service/auth.service';
import { EventBusService } from 'src/app/service/event-bus,service';
import { EventData } from 'src/app/service/event.class';
import { HttpService } from 'src/app/service/http.service';
import { LocalStorage } from 'src/app/service/local-storage';
import { ShareUtils } from 'src/app/service/shareutils';
import { TokenStorageService } from 'src/app/service/token-storage.service';
import { URL_BUYER_CART, URL_IS_EMAIL_EXIST, URL_RESEND_CHANGE_EMAIL_TOKEN, URL_RESET_LINK, URL_SIGN_UP_USER, URL_VERIFY_EMAIL_OTP } from 'src/app/service/urls';
import { environment } from 'src/environments/environment';
import { UserEditPhoneComponent } from "../../user-edit-phone/user-edit-phone.component";
import { DialogModule } from 'primeng/dialog';
import { ContentComponent } from 'src/app/content/content.component';


@Component({
    standalone: true,
    selector: 'app-share-sign-in',
    templateUrl: './share-signin.component.html',
    styleUrls: ['./share-signin.component.scss'],
    imports: [ReactiveFormsModule, DialogModule, MessagesModule, ButtonModule, TranslocoModule, CommonModule, InputTextModule, CheckboxModule, UserEditPhoneComponent, ContentComponent]
})
export class ShareSignInComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {

  @Output() action = new EventEmitter<string>();
  @Input() role: string = 'user';
  @Input() email: string = '';
  @Input() token: string = '';

  rememberMe = false;
  signInForm: FormGroup;
  private subscription: Subscription = new Subscription();
  signInPageMsgs: Message[] = [];
  step = 'start';
  //step = 'verifyEmailCode';
  resetLinkSent = false;
  googleOneTapLoaded = false;
  isPasswordFieldType = true;
  twofa='';
  termsDialog =signal(false);
  privacyDialog = signal(false);
  redirectURL='';
  
  googleClientId = signal(environment.googleClientId);
  phoneSuffix=signal('');
  @ViewChild('gbtn') gbtn!: ElementRef;

  //eventBusSub?: Subscription;
  isBrowser = false;

  constructor(
    private tokenStorage: TokenStorageService,
    fb: FormBuilder,
    private localStorage:  LocalStorage,
    private authService: AuthService,
    private httpSvc: HttpService,
    private eventBusService: EventBusService,
    public shareUtils: ShareUtils,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
    private ngZone: NgZone,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.signInForm = fb.group({
      email: [null, [
        Validators.required,
        shareUtils.removeSpaces,
        shareUtils.emailValidator,
      ]],
      username: [null, [
        Validators.required,
        shareUtils.removeSpaces,
        shareUtils.emailValidator,
      ]],
      passcode: ['', Validators.required],
      emailCode: ['', Validators.required],
      password: ['', Validators.required],
      rememberDevice: [false],
      
      newPassword1: ['', [Validators.required,

      shareUtils.symbolValidator,
      shareUtils.numberValidator,
      shareUtils.uppercaseValidator,
      shareUtils.lowercaseValidator,
      shareUtils.lengthValidator]],
      firstName: new FormControl('', Validators.required),
      lastName: ['', Validators.required],



    });



  }

  ngOnInit(): void {

    this.subscription.add(
      this.eventBusService.on('signInRestart', (value: boolean) => {

        this.step = 'start';        
        this.resetLinkSent = false;
        this.signInForm.controls['password'].setValue('');



      }));

    if (this.email != '') {

      this.signInForm.controls['email'].setValue(this.email);
    }

    this.subscription.add(this.eventBusService.on('signInEmail', (value: string) => {


      this.signInForm.controls['email'].setValue(value);

    }));

    this.route.queryParams.subscribe(params => {
      
      this.redirectURL = params['redirect'];
      console.log('redirectURLa', this.redirectURL);
      
    });

  }

  ngOnDestroy(): void {



    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public ngAfterViewChecked(): void {

    if (!this.isBrowser) {
      return;
    }

    const elementExists = !!document.getElementById('gbtn');

    if (elementExists && !this.googleOneTapLoaded) {


      const gAccounts: accounts = google.accounts;


      gAccounts.id.initialize({
        client_id: environment.googleClientId,
        ux_mode: 'popup',
        cancel_on_tap_outside: true,
        callback: ({ credential }) => {
          this.ngZone.run(() => {
            this._loginWithGoogle(credential);
          });
        },
      });

      console.log('gbtn', document.getElementById('gbtn'));


      let width = 0;
      if (document.getElementById('signInCtnBtn') != undefined) {
        width = document.getElementById('signInCtnBtn')!.offsetWidth;
      }





      gAccounts.id.renderButton(document.getElementById('gbtn') as HTMLElement, {
        type: "standard",
        theme: "outline",
        size: 'large',
        shape: "rectangular",
        logo_alignment: "left",
        width: width
      });
      //google.accounts.id.prompt();
      this.googleOneTapLoaded = true;
    }

  }

  ngAfterViewInit() {
    return;
    const gAccounts: accounts = google.accounts;


    gAccounts.id.initialize({
      client_id: environment.googleClientId,
      ux_mode: 'popup',
      cancel_on_tap_outside: true,
      callback: ({ credential }) => {
        this.ngZone.run(() => {
          this._loginWithGoogle(credential);
        });
      },
    });



  }

  private _loginWithGoogle(token: string) {
    const params: any = {};
    params.token = token;
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    params.cartId = sessionStorage.getItem('cartId');
    params.orderId = sessionStorage.getItem('orderId');
    params.role = this.role;
    this.subscription.add(this.authService.loginGoogleUser(params)
      .pipe(first())
      .subscribe(
        {
          next:
            (data) => {
              console.log('data', data);


              this.action.emit('signedIn');


            },
          error:
            (err) => {

              console.log('data err', err);
              if (err.status === 400) {
                if (err.error.messages[0] === 'systemOffLineForMaintenance') {
                  this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('systemOffLineForMaintenance'));
                }
              }


              this.clearMessage();


            }
        }
      ));
  }


  signIn(): void {
    this.signInForm.controls['email'].setValue(this.signInForm.value.email.toLowerCase());
    if (this.step==='start') {
      this.signInForm.controls['password'].setValue('');
      this.signInForm.controls['passcode'].setValue('');
      this.signInForm.controls['password'].setErrors(null);
      this.signInForm.controls['passcode'].setErrors(null);
      this.signInForm.controls['passcode'].markAsPristine();
      this.signInForm.controls['passcode'].markAsUntouched();
      this.signInForm.controls['password'].markAsPristine();
      this.signInForm.controls['password'].markAsUntouched();

      this.signInForm.controls['password'].updateValueAndValidity();
      this.signInForm.controls['passcode'].updateValueAndValidity();
      
      
    }
    this.handleSignIn();
  }

  verifyTOTP(): void {
if (this.signInForm.value.passcode.trim()==='') {
  this.signInForm.controls['passcode'].markAsTouched();
  this.signInForm.controls['passcode'].markAsDirty();
  this.signInForm.controls['passcode'].setErrors({ 'required': true });
    return;
}
this.handleSignIn();
  }

  switchToSMS(): void {
    this.signInForm.controls['passcode'].setValue('');
    this.signInForm.controls['passcode'].setErrors(null);
    this.signInForm.controls['passcode'].markAsPristine();
    this.signInForm.controls['passcode'].markAsUntouched();
    this.signInForm.controls['passcode'].updateValueAndValidity();
    this.twofa='sms';
    this.handleSignIn();
  }

  handleSignIn(): void {
    if(this.step=='signIn') {
      this.signInForm.controls['passcode'].setValue('');
    }

    const params: any = {};
    params.email = this.signInForm.value.email;
    params.password = this.signInForm.value.password;
    params.passcode = this.signInForm.value.passcode; 
    params.emailCode = this.signInForm.value.emailCode;
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    params.cartId = sessionStorage.getItem('cartId');
    params.orderId = sessionStorage.getItem('orderId');
    params.role = this.role;
    params.token = this.token; //token from url
    params.step = this.step;
    params.lang= Globals.webSiteLang;
    params.twofa= this.twofa;
    params.rememberDevice = this.signInForm.value.rememberDevice;
    params.deviceToken = this.localStorage.getItem('deviceToken');
    this.subscription.add(this.authService.login(params)
      .pipe(first())
      .subscribe(
        {
          next:
            (data) => {
              console.log('data', data);
              /*
              this.tokenStorage.saveToken(data['accessToken'] as string);
              
              Globals.loggedUser = Globals.getEmptyUser();
    
              //Globals.loggedUser.email = data['email'];
              Globals.loggedUser.firstName = data['firstName'];
              Globals.loggedUser.lastName = data['lastName'];
              Globals.loggedUser.accessToken = data['accessToken'];
              Globals.loggedUser.refreshToken = data['refreshToken'];
              Globals.loggedUser.tenantId = data['tenantId'];
              Globals.loggedUser.roles = data['roles'];
    */
              //this.getCartId();

              if (Globals.loggedUser.roles.includes('admin')) {
                this.goToAdmin();
              }
              else {
                //this.step='addPhone';
                this.action.emit('signedIn');
              }

              const findStr = (arr: string[], str: string) => arr.some((e: string) => e.toLowerCase().search(str.toLowerCase()) !== -1)

              const keepSameURL = ['/order/completed', '/cart', '/checkout'];

              console.log('aftersignin', this.router.url);
              console.log('redirectURL', this.redirectURL);
             
           

               if(this.redirectURL!==undefined) {
                this.router.navigateByUrl(this.redirectURL);

              }
              else if(data.mobileVerified===false) {
                this.router.navigate(['/profile/phone'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
             }
              else if (!findStr(keepSameURL, this.router.url)) {

                this.router.navigate(['/profile'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });

              }
            },
          error:
            (err) => {

              console.log('data err', err);
              if (err.status === 400) {
                if (err.error.messages[0] === 'systemOffLineForMaintenance') {
                  this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('systemOffLineForMaintenance'));
                }
                else if (err.error.messages[0] === 'emailNotValidated') {
                  console.log('emailNotValidated2');
                  this.action.emit('emailNotValidated');
                  this.verifyEmailCodeStep();
                }
                else if (err.error.messages[0] === 'emailInvalidCode') {
                  console.log('emailNotValidated2');
                  this.action.emit('emailInvalidCode');
                  this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('invalidCode'));
                }
                else if (err.error.messages[0] === 'maxTriesReached') {
                   
                  this.action.emit('maxTriesReached');    
                  this.router.navigate(['/'], { relativeTo: this.route, queryParams: { msgTop: 'tooManyAttempts' }, replaceUrl: false, skipLocationChange: false });
                }
                
                else if (err.error.messages[0] === 'userDisabled') {                  
                  this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('accountDisabled'));
                }
                else if (err.error.messages[0] === '2faAuthRequired') {
                  console.log('2faAuthRequired');
                  this.step='2faAuthRequired';
                }
                else if (err.error.messages[0] === '2faSMSRequired') {
                  console.log('2faSMSRequired');
                  this.step='2faSMSRequired';
                  this.phoneSuffix.set(err.error.phone);
                }
                else if (err.error.messages[0] === '2faAuthInvalidCode' || err.error.messages[0] === '2faSMSInvalidCode') {
                  this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('invalidCode'));
                }
                else {
                  this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('emailPasswordWrong'));
                }

              } else {
                this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('emailPasswordWrong'));

              }

              this.clearMessage();


            }
        }
      ));
  }

  showMessage(msgType: string, msgSummary: string = '', msgDetail: string = ''): void {
    this.signInPageMsgs = [];
    this.signInPageMsgs.push(
      {
        severity: msgType,
        summary: msgSummary,
        detail: msgDetail
      });
  }

  clearMessage(vTimeOut: number = 2000): void {
    setTimeout(() => {
      this.signInPageMsgs = [];
    }, vTimeOut);
  }

  reloadPage(): void {
    window.location.reload();
  }

  signUp(): void {
    let valid = true;
    if (this.signInForm.controls['firstName'].errors && this.signInForm.controls['firstName'].invalid) {
      this.signInForm.controls['firstName'].markAsTouched();
      this.signInForm.controls['firstName'].markAsDirty();
      this.signInForm.controls['firstName'].setErrors({ 'invalid': true });
      valid = false;
    }

    if (this.signInForm.controls['lastName'].errors && this.signInForm.controls['lastName'].invalid) {
      this.signInForm.controls['lastName'].markAsTouched();
      this.signInForm.controls['lastName'].markAsDirty();
      this.signInForm.controls['lastName'].setErrors({ 'invalid': true });
      valid = false;
    }

    if (this.signInForm.controls['newPassword1'].errors && this.signInForm.controls['newPassword1'].invalid) {
      valid = false;
    }

    if (valid) { this.handleSignUp(); }


  }

  handleSignUp(): void {
    const params: any = {};
    params.email = this.signInForm.value.email;
    params.firstName = this.signInForm.value.firstName;
    params.lastName = this.signInForm.value.lastName;
    params.password = this.signInForm.value.newPassword1;
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    params.role = this.role;
    params.token = this.token; //token from url
    params.language = Globals.webSiteLang;
    params.region = Globals.region;
    this.subscription.add(this.httpSvc.post<LoginUserModel>(URL_SIGN_UP_USER, params).subscribe(
      {
        next:
          (data) => {
            console.log('data', data);
            this.authService.updateUser(data);

            this.action.emit('signedUp');
          },
        error:
          (err) => {

            console.log('data err', err);
            if (err.status === 400) {
              if (err.error.messages[0] === 'systemOffLineForMaintenance') {
                this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('systemOffLineForMaintenance'));
              }

              else if (err.error.messages[0] === 'emailInUse') {
                this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('emailInUse'));
              }
              else if (err.error.messages[0] === 'emailNotValidated') {
                this.signInForm.controls['password'].setValue(this.signInForm.value.newPassword1);
                //this.action.emit('newAccountEmailValidation');
                this.verifyEmailCodeStep();
              }
              else {
                this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('emailPasswordWrong'));
              }

            } else {
              this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('emailPasswordWrong'));

            }

            this.clearMessage();


          }
      }
    ));
  }
  forgotPassword(): void {
    this.resetLinkSent = false;
    this.step = 'reset';
    //this.router.navigate([ '/resetLink'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }

  sendEmailValidation(): void {
    this.step = 'sendEmailValidationToken';
  }
  


    
  verifyEmailCodeStep(): void {
    this.step = 'verifyEmailCode';
  }
  newAccountEmailValidation(): void {
    this.step = 'newAccountEmailValidation';
  }

  getCartId(): void {
    const params: any = {};

    params.lang = Globals.webSiteLang;
    params.cartId = sessionStorage.getItem('cartId');


    let itemIndex = 0;
    this.subscription.add(this.httpSvc.get<QueryResultModel>(URL_BUYER_CART, params).subscribe({
      next:
        (data) => {

          console.log('cardata', data);


          for (let i = 0; i < data.objects.length; i++) {
            const cart: BuyerViewCartModel = data.objects[i];

            sessionStorage.setItem('cartId', cart.cartId);
            this.eventBusService.emit(new EventData('itemsInCart', cart.totalQuantity));

          }


        },
      error:
        err => {

          console.log('data err.status', err.status);


        }
    }
    ));
  }



  validateEmail(): boolean {

    if (this.signInForm.controls['email'].errors && this.signInForm.controls['email'].errors?.['required']) {
      this.signInForm.controls['email'].markAsTouched();
      this.signInForm.controls['email'].markAsDirty();
      this.signInForm.controls['email'].setErrors({ 'required': true });
      return false;
    } else if (this.signInForm.controls['email'].errors && this.signInForm.controls['email'].invalid) {
      this.signInForm.controls['email'].markAsTouched();
      this.signInForm.controls['email'].markAsDirty();
      this.signInForm.controls['email'].setErrors({ 'invalid': true });
      return false;
    }
    return true;
  }

  isEmailExist(): void {

  this.backToStart();

    if (!this.validateEmail()) {
      return;
    }

    const params: any = {};
    params.email = this.signInForm.value.email;


    let itemIndex = 0;
    this.subscription.add(this.httpSvc.post<LoginMethodModel>(URL_IS_EMAIL_EXIST, params).subscribe({
      next:
        (data) => {

          console.log('email exist', data);

          if (data.identityProvider == 'google') {
            this.signInForm.controls['email'].markAsTouched();
            this.signInForm.controls['email'].markAsDirty();
            this.signInForm.controls['email'].setErrors({ 'googleIdentity': true });

          }
          else {
            this.step = 'signIn';
          }



        },
      error:
        (err) => {

          console.log('data err', err);
          if (err.status === 404) {
            this.step = 'signUp';

          }

        }
    }
    ));
  }
  backToStart(): void {
    this.step = 'start';
    this.signInForm.controls['password'].setValue('');
    this.signInForm.controls['passcode'].setValue('');
    this.signInForm.controls['password'].setErrors(null);
    this.signInForm.controls['passcode'].setErrors(null);
    this.signInForm.controls['passcode'].markAsPristine();
    this.signInForm.controls['passcode'].markAsUntouched();
    this.signInForm.controls['password'].markAsPristine();
    this.signInForm.controls['password'].markAsUntouched();

    this.signInForm.controls['password'].updateValueAndValidity();
    this.signInForm.controls['passcode'].updateValueAndValidity();
  }



  sendResetLink(): void {
    const params: any = {};
    params.email = this.signInForm.value.email;
    this.subscription.add(this.httpSvc.post<LoginUserModel>(URL_RESET_LINK, params).subscribe(
      {
        next:
          (data) => {
            console.log('data', data);

            this.resetLinkSent = true;


          },
        error:
          (err) => {

            console.log('data err', err);

            this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('emailNotFound'));
            this.clearMessage();


          }
      }
    ));
  }

  goToAdmin(): void {
    this.router.navigate(['/admin'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }


  resendChangeEmailToken(): void {
    const params: any = {};
    params.email = this.signInForm.value.email
    params.lang = Globals.webSiteLang;

    this.subscription.add(this.httpSvc.post<LoginUserModel>(URL_RESEND_CHANGE_EMAIL_TOKEN, params).subscribe(
      {
        next:
          (data) => {
            console.log('data', data);
            this.resetLinkSent = true;
          },
        error:
          (err) => {

            console.log('data err', err);
            if (err.status === 400 && err.error.messages[0] !== undefined) {

              this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate(err.error.messages[0]));

            }
            this.clearMessage();
          }
      }
    ));
  }

  togglePasswordFieldType(): void {
    this.isPasswordFieldType = !this.isPasswordFieldType;
  }

  closeResendEmailValidationDialog(): void {
    this.action.emit('close');
    return;
  }

  otpValidation(event: any): boolean {
    return this.shareUtils.keyPressNumbers(6, event);
  }
  

  verifyEmailCode(): void {
  if (this.signInForm.value.emailCode.trim()==='') {
    this.signInForm.controls['emailCode'].markAsTouched();
    this.signInForm.controls['emailCode'].markAsDirty();
    this.signInForm.controls['emailCode'].setErrors({ 'required': true });
      return;
  }
  this.handleSignIn();

  }

}
