import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { signalSetFn } from '@angular/core/primitives/signals';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Message } from 'primeng/api';
import { first, Subscription } from 'rxjs';
import { Globals } from 'src/app/globals';
import { LoginUserModel } from 'src/app/model/shared.model';
import { AuthService } from 'src/app/service/auth.service';
import { HttpService } from 'src/app/service/http.service';
import { ShareUtils } from 'src/app/service/shareutils';
import { TokenStorageService } from 'src/app/service/token-storage.service';
import { URL_SIGN_UP_USER } from 'src/app/service/urls';

@Component({
  selector: 'app-share-sign-up',
  templateUrl: './share-signup.component.html',
  styleUrls: ['./share-signup.component.scss']
})
export class ShareSignUpComponent implements OnInit, OnDestroy {

  @Output() action = new EventEmitter<string>();
  @Input()  role: string='';
  
  rememberMe = false;
  signInForm: UntypedFormGroup;
  private subscription: Subscription = new Subscription();
  signInPageMsgs: Message[] = [];
  isPasswordFieldType =signal(true);

  constructor(
    private tokenStorage: TokenStorageService,
    fb: UntypedFormBuilder,
    private authService: AuthService,
    private httpSvc: HttpService,
    public shareUtils: ShareUtils,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
    private router: Router
  ) {
    this.signInForm = fb.group({
      emailMobile: ['',  Validators.required],
      password: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],

    });

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
  
  signUp(): void {
    this.handleSignIn();
  }

  signIn(): void {
    console.log('signIn now');
    this.action.emit('signIn');    
  }

  handleSignIn(): void {
    const params: any = {};
    params.email = this.signInForm.value.emailMobile;
    params.firstName = this.signInForm.value.firstName;
    params.lastName = this.signInForm.value.lastName;
    params.password = this.signInForm.value.password;
    params.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    params.role='buyer';
    params.language = Globals.webSiteLang;
    params.region = Globals.region;
    this.subscription.add(this.httpSvc.post<LoginUserModel>(URL_SIGN_UP_USER, params).subscribe(
      {
      next:
      (data) => {
          console.log('data', data);
          this.tokenStorage.saveToken(data.accessToken as string);
          
          Globals.loggedUser = Globals.getEmptyUser();
          Globals.loggedUser.firstName = data.firstName;
          Globals.loggedUser.lastName = data.lastName;
          //Globals.loggedUser.accessToken = data.accessToken;
          //Globals.loggedUser.refreshToken = data.refreshToken;
          Globals.loggedUser.tenantId = data.tenantId;
          Globals.loggedUser.roles = data.roles;

     this.router.navigate([ '/profile'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });

        },
      error:
        (err) => {

          console.log('data err', err);
          if (err.status === 400) {
            if (err.error.messages[0] === 'systemOffLineForMaintenance') {
              this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('systemOffLineForMaintenance'));
            }

            else {
              this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('emailPasswordWrong'));
            }

          } else {
            this.showMessage(Globals.MSG_SEVERITY_ERROR, '', this.translocoService.translate('emailPasswordWrong'));

          }

          this.clearMessage();


        }
      }
      ));
  }

  showMessage(msgType: string, msgSummary: string = '', msgDetail: string = ''): void {
    this.signInPageMsgs = [];
    this.signInPageMsgs.push(
      {
        severity: msgType,
        summary: msgSummary,
        detail: msgDetail
      });
  }

  clearMessage(vTimeOut: number = 2000): void {
    setTimeout(() => {
      this.signInPageMsgs = [];
    }, vTimeOut);
  }

  reloadPage(): void {
    window.location.reload();
  }

  togglePasswordFieldType(): void {
    this.isPasswordFieldType.set(!this.isPasswordFieldType());
  }

  checkComplexityPassword(): void {

  
  }
 
}
