<div class="container">
  <div class="centered-element">


    <div class="grid text-center ">
      <div class="col-12">
        <img src="assets/layout/images/kmdcomfullblack.svg" />
        <br />
        Donate for your cause
        <br />
        <br />
        <input type="password" pInputText placeholder="Password" [(ngModel)]="stagingToken" />

        <br />
        <br />
        <button pButton type="button" class="ui-button" (click)="checkStagingPassword()"
          [label]="'login' | transloco | uppercase"></button>
      </div>
    </div>
  </div>

</div>