import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { Globals } from '../globals';
import { GuestConfigModel } from '../model/shared.model';
import { ShareUtils } from './shareutils';
import { TokenStorageService } from './token-storage.service';
import { URL_PUBLIC_CONFIGURATION, URL_PUBLIC_TENANT_TOKEN } from './urls';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorage } from './local-storage';
import { SessionStorage } from './session-storage';



export interface ConfigObject {

  token: string;
}


@Injectable({
  providedIn: 'root'
})
export class StartupService {


  isBrowser=false;


  constructor(
    private shareUtils: ShareUtils,
    private translocoService: TranslocoService,
    private tokenStorageService: TokenStorageService,
    
    private localStorage:  LocalStorage,
    private sessionStorage:  SessionStorage,
    private router: Router,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: string
    // @Inject('BASE_URL') private originUrl: string
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

   }


  public loadConfigurationData(): Promise<any> {
    console.log('environment.local',environment.local, this.isBrowser);
    
      if(!environment.local && !this.isBrowser) {
        Globals.domainAPI = 'https://stagingapi.donaqo.com';
        Globals.tenantId = 'donaqostaging';
        Globals.websiteURL='https://staging.donaqo.com';
      }

    else if(environment.local && !this.isBrowser) {
      Globals.domainAPI = 'https://dev-api.karmamiles.com';
      Globals.tenantId = 'donaqo';
      Globals.websiteURL='https://dev.karmamiles.com';
      Globals.site='donaqo';
    }
    else if (window.location.hostname === 'donaqo.com') {
      Globals.domainAPI = 'https://api.donaqo.com';
      Globals.tenantId = 'donaqo';
      Globals.websiteURL='https://api.donaqo.com';
      Globals.site='donaqo';
    }
    else if (window.location.hostname === 'karmamiles.com') {
      Globals.domainAPI = 'https://api.karmamiles.com';
      Globals.tenantId = 'donaqo';
      Globals.websiteURL='https://api.karmamiles.com';
      Globals.site='karmamiles';
    }
    else if (window.location.hostname === 'questimed.com') {
      Globals.domainAPI = 'https://api.questimed.com';
      Globals.tenantId = 'donaqo';
      Globals.websiteURL='https://api.questimed.com';
      Globals.site='questimed';
    }
    else if (window.location.hostname === 'localhost') {
      Globals.domainAPI = 'http://localhost:80';
      Globals.tenantId = 'donaqo';
      Globals.websiteURL='http://localhost';
      Globals.site='donaqo';

    } else if (window.location.hostname === 'dev.karmamiles.com') {
      Globals.domainAPI = 'https://dev-api.karmamiles.com';
      Globals.tenantId = 'donaqo';
      Globals.websiteURL='https://dev.karmamiles.com';
      Globals.site='donaqo';

    }
    else if (window.location.hostname === 'dev.donaqo.com') {
      Globals.domainAPI = 'https://devbe.donaqo.com';
      Globals.tenantId = 'donaqo';
      Globals.websiteURL='https://dev.donaqo.com';
      Globals.site='donaqo';

    }
    else if (window.location.hostname === 'staging.donaqo.com') {
      Globals.domainAPI = 'https://stagingapi.donaqo.com';
      Globals.tenantId = 'donaqostaging';
      Globals.websiteURL='https://staging.donaqo.com';
      Globals.site='donaqo';
    }


    const httpOptions = {
      headers: new HttpHeaders({

        'Content-Type': 'application/json'


      })

    };
  let stagingToken;

    const params: any = {};
    params.domain = Globals.domainAPI;
    params.tenantId = Globals.tenantId;
    if(this.isBrowser) {
      stagingToken = this.localStorage.getItem('stagingToken');
      //params.url = window.location.href;
      params.publicToken = this.tokenStorageService.getPublicToken();
  }
  else {
    stagingToken='give.now';    
    params.publicToken = '';
  }

  if(params.publicToken==='undefined')
  {
    params.publicToken='';
  }
  
  console.log('testdata1', params);
    params.stagingToken = stagingToken;
    return new Promise<void>((resolve, reject) => {
      firstValueFrom(
        this.http.post<ConfigObject>(Globals.domainAPI + URL_PUBLIC_TENANT_TOKEN, JSON.stringify(params), httpOptions))
        .then(data => {
          console.log('testdata', data);

          Globals.publicToken = data.token;

          this.tokenStorageService.savePublicToken(data.token);
          this.sessionStorage.removeItem('initialLoadFailed');


          resolve();
        },
        err => {
          console.log('testlog',err);
          this.router.navigate(['/staging'], { queryParams: null, replaceUrl: false, skipLocationChange: true });
          resolve();
        }
        )
        .catch(() => {
          reject(`Could not load configuration data. Please try again later.1` + JSON.stringify(params));
        });
    });
   
  }

  loadConfigurationsPublic(): Promise<any> {

    return new Promise<void>((resolve, reject) => {
      firstValueFrom(
        this.http.get<GuestConfigModel>(Globals.domainAPI + URL_PUBLIC_CONFIGURATION))

        .then(data => {
          console.log('app config log', data);
          Globals.config.paypalClientId = data.paypalClientId;
          Globals.config.environmentTest = data.environmentTest;

          this.sessionStorage.removeItem('initialLoadFailed');
          resolve();
        })
        .catch((response: any) => {
          console.log('responselog', response);
          resolve();
          //reject(`Could not load configuration data. Please try again later.2`);

        });
    });

  }

  loadLocalToken(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (this.isBrowser) {
      const stagingToken = this.localStorage.getItem('stagingToken');
      if (!stagingToken) {
        //this.router.navigate(['/staging'], { queryParams: null, replaceUrl: false, skipLocationChange: true });
        resolve();
      } else {
        resolve();
      }
    } else {
      resolve();
    }
    })
  }


  loadLanguage(): Promise<any> {
    
    
 
    console.log('storagelang', this.localStorage.getItem('language'));
    console.log('defaultlang', Globals.config.defaultLang);
    if (Globals.functionalCookies === true) {
      if (this.localStorage.getItem('language') == null || this.localStorage.getItem('language') === undefined || this.localStorage.getItem('language') === 'undefined'
        || this.localStorage.getItem('language') === '') {

        Globals.webSiteLang = Globals.config.defaultLang;
        this.localStorage.setItem('language', Globals.webSiteLang);
        this.sessionStorage.removeItem('language');

      } else {
        Globals.webSiteLang = this.localStorage.getItem('language') ?? Globals.config.defaultLang;
        this.sessionStorage.removeItem('language');
      }
    } else if (this.sessionStorage.getItem('language') == null || this.sessionStorage.getItem('language') === undefined || this.sessionStorage.getItem('language') === 'undefined'
      || this.sessionStorage.getItem('language') === '') {
      Globals.webSiteLang = Globals.config.defaultLang;
      this.localStorage.removeItem('language');
      this.sessionStorage.setItem('language', Globals.webSiteLang);
    } else {
      this.localStorage.removeItem('language');
      Globals.webSiteLang = this.sessionStorage.getItem('language') ?? Globals.config.defaultLang;
    }

    Globals.webSiteLangModel = this.shareUtils.convertToKeyOfLang(Globals.webSiteLang);
    this.translocoService.setActiveLang(Globals.webSiteLang);
    return this.translocoService.load(Globals.webSiteLang).toPromise();

  }


  loadCurrency(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      console.log('storagecurrency', this.localStorage.getItem('currency'));
      console.log('defaultCurrency', Globals.config.defaultCurrency);
      if (Globals.functionalCookies === true) {
        if (this.localStorage.getItem('currency') == null || this.localStorage.getItem('currency') === undefined || this.localStorage.getItem('currency') === 'undefined'
          || this.localStorage.getItem('currency') === '') {

          Globals.currency = Globals.config.defaultCurrency;
          this.localStorage.setItem('currency', Globals.currency);
          this.sessionStorage.removeItem('currency');

        } else {
          Globals.currency = this.localStorage.getItem('currency') ?? Globals.config.defaultCurrency;
          this.sessionStorage.removeItem('currency');
        }
      } else if (this.sessionStorage.getItem('currency') == null || this.sessionStorage.getItem('currency') === undefined || this.sessionStorage.getItem('currency') === 'undefined'
        || this.sessionStorage.getItem('currency') === '') {
        Globals.currency = Globals.config.defaultCurrency;
        this.localStorage.removeItem('currency');
        this.sessionStorage.setItem('currency', Globals.currency);
      } else {
        this.localStorage.removeItem('currency');
        Globals.currency = this.sessionStorage.getItem('currency') ?? Globals.config.defaultCurrency;
      }
      resolve();
    })
  }


  loadRegion(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      
      console.log('storageregion', this.localStorage.getItem('region'));
      console.log('defaultregion', Globals.config.defaultRegion);
      if (Globals.functionalCookies === true) {
        if (this.localStorage.getItem('region') == null || this.localStorage.getItem('region') === undefined || this.localStorage.getItem('region') === 'undefined'
          || this.localStorage.getItem('region') === '') {

          Globals.region = Globals.config.defaultRegion;
          this.localStorage.setItem('region', Globals.region);
          this.sessionStorage.removeItem('region');

        } else {
          Globals.region = this.localStorage.getItem('region') ?? Globals.config.defaultRegion;
          this.sessionStorage.removeItem('region');
        }
      } else if (this.sessionStorage.getItem('region') == null || this.sessionStorage.getItem('region') === undefined || this.sessionStorage.getItem('region') === 'undefined'
        || this.sessionStorage.getItem('region') === '') {
        Globals.region = Globals.config.defaultRegion;
        this.localStorage.removeItem('region');
        this.sessionStorage.setItem('region', Globals.region);
      } else {
        this.localStorage.removeItem('region');
        Globals.region = this.sessionStorage.getItem('region') ?? Globals.config.defaultRegion;
      }
      Globals.currency = this.shareUtils.getCurrencyFromRegion(Globals.region);
      resolve();
    })
  }


  public loadConfigs(): Promise<any> {
    return this.loadLanguage().then(
      () =>
        this.loadRegion().then(
          () =>
            this.loadLocalToken().then(
              () =>
                this.loadConfigurationData().then(
                  () =>

                    this.loadConfigurationsPublic()
                )

            )
        )
    );
  }

}

