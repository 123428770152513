import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Globals } from '../globals';
import { catchError, Observable, of, throwError } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({
    //   timeout: `${10}`,
    'Content-Type': 'application/json'

    // 'Authorization': EMAIL_TOKEN
  })

};

const httpFullOptions = {

  headers: new HttpHeaders({

    //   timeout: `${10}`,
    'Content-Type': 'application/json'

    // 'Authorization': EMAIL_TOKEN
  }),
  observe: 'response' as 'response'

};




const httpGetOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable()
export class HttpService {

  constructor(private http: HttpClient) { }


  addDomainToURL(url: string) {
    console.log("Globals.domainAPI  url", url);
    if (url.startsWith('http')) {
      return url;
    } else {
      console.log("Globals.domainAPI service ",Globals.domainAPI);
      return Globals.domainAPI + url;
    }
  }

  makeHttpPost(url: string, params?: Object) {
    url = this.addDomainToURL(url);
    return this.http.post(url, JSON.stringify(params), httpOptions);
  }




  post<T>(url: string, params?: Object) {
    url = this.addDomainToURL(url);

    return this.http.post<T>(url, JSON.stringify(params), httpOptions).pipe(
      catchError(this.handleError<T>(url,{ params } ))
    );
  }

  patch<T>(url: string, params?: Object) {
    url = this.addDomainToURL(url);

    return this.http.patch<T>(url, JSON.stringify(params), httpOptions).pipe(
      catchError(this.handleError<T>(url,{ params } ))
    );
  }

  put<T>(url: string, params?: Object) {
    url = this.addDomainToURL(url);

    return this.http.put<T>(url, JSON.stringify(params), httpOptions).pipe(
      catchError(this.handleError<T>(url,{ params } ))
    );
  }

  delete<T>(url: string, params?: HttpParams): Observable<T> {
    url = this.addDomainToURL(url);
    return this.http.delete<T>(url, { params }).pipe(
      catchError(this.handleError<T>(url,{ params } ))
    );
  }


  makeHttpPostWithToken(url: string, token: string, params?: Object) {
    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    url = this.addDomainToURL(url);
    return this.http.post(url, JSON.stringify(params), httpOptionsToken);
  }

  makeHttpPostFullResponse(url: string, params?: Object) {
    url = this.addDomainToURL(url);
    return this.http.post(url, JSON.stringify(params), httpFullOptions);

  }

  makeHttpPostFullResponseWithToken(url: string, token: string, params?: Object) {
    const httpFullOptionsToken = {

      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
      observe: 'response' as 'response'
    };
    url = this.addDomainToURL(url);
    return this.http.post(url, JSON.stringify(params), httpFullOptionsToken);
  }

  makeHttpPatchFullResponseWithToken(url: string, token: string, params?: Object) {
    const httpFullOptionsToken = {

      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      }),
      observe: 'response' as 'response'
    };
    url = this.addDomainToURL(url);
    return this.http.patch(url, JSON.stringify(params), httpFullOptionsToken);
  }

  makeHttpPostPromise(url: string, params?: Object) {
    url = this.addDomainToURL(url);
    return this.http.post(url, JSON.stringify(params), httpOptions).toPromise();
  }

  makeHttpGetPromise(url: string) {
    url = this.addDomainToURL(url);
    return this.http.get(url).toPromise();
  }

  makeHttpGetOld(url: string) {
    url = this.addDomainToURL(url);
    return this.http.get(url, httpOptions);
  }

  makeHttpGet(url: string, params?: HttpParams) {
    url = this.addDomainToURL(url);
    return this.http.get(url, { params });
  }

  get<T>(url: string, params?: HttpParams): Observable<T> {
    url = this.addDomainToURL(url);
    return this.http.get<T>(url, { params }).pipe(
      catchError(this.handleError<T>(url,{ params } ))
    );
  }



  makeHttpGetWithToken(url: string, token: string, params?: HttpParams) {
    const httpOptionsToken = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });
    url = this.addDomainToURL(url);
    const options = { params: params, headers: httpOptionsToken };
    return this.http.get(url, options);
  }


  makeHttpGetToPromise(url: string, params?: HttpParams) {
    url = this.addDomainToURL(url);
    return this.http.get(url, { params }).toPromise();
  }



  


 

  makeHttpDelete(url: string, params?: HttpParams) {
    url = this.addDomainToURL(url);
    return this.http.delete(url, { params });
  }


  makeHttpPatch(url: string, params?: Object) {
    url = this.addDomainToURL(url);
    return this.http.patch(url, JSON.stringify(params), httpOptions);
  }

  makeHttpPatchWithToken(url: string, token: string, params?: Object) {
    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    url = this.addDomainToURL(url);
    return this.http.patch(url, JSON.stringify(params), httpOptionsToken);
  }



  private handleError<T>(operation = 'operation', params?: Object, result?: T) {
    return (error: any): Observable<T> => {
      let errorMessage = '';
      // TODO: send the error to remote logging infrastructure
      console.log("mycatchError", error); // log to console instead
      console.log("mycatchError Params", params); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }

      console.log("mycatchError", error); // log to console instead
      console.log("mycatchError Params", params); // log to console instead

      return throwError(() => {
        return error;
      });

      // Let the app keep running by returning an empty result.
      //return of(result as T);
    };
  }


  
}
