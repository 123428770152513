import { Component, HostListener, Input, input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Globals } from '../globals';
import { ContentModel } from '../model/shared.model';
import { AuthService } from '../service/auth.service';
import { HttpService } from '../service/http.service';
import { ShareUtils } from '../service/shareutils';
import { TokenStorageService } from '../service/token-storage.service';
import { URL_CONTENT } from '../service/urls';

@Component({
  standalone: true,
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnDestroy {

  htmlContent:SafeHtml='';
  @Input() category: string | null='';
  @Input() section: string | null ='';
  
  
  private subscription: Subscription = new Subscription();

  constructor(
    private tokenStorage: TokenStorageService,
    fb: UntypedFormBuilder,
    private httpSvc: HttpService,
    private messageService: MessageService,
    private authService: AuthService,
    public shareUtils: ShareUtils,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private activatedroute:ActivatedRoute,
    
  ) {
  


  }

  ngOnInit(): void {
    console.log('this.activatedroute.snapshot.data',this.activatedroute.snapshot.data);
    if (this.activatedroute.snapshot.data['category']) {
      
      this.category=this.activatedroute.snapshot.data['category'];
      this.section='';
      this.loadContent();
    }
    else {
    this.subscription.add(this.activatedroute.paramMap.subscribe(params => { 
      console.log('thisparam1a', params.get('category'));
      if(params.get('category')!=null) {
        console.log('thisparam1b', params.get('category'));
      this.category= params.get('category');
      this.section= params.get('section')!=null?params.get('section'):'';
      this.loadContent();
      } else {
        this.loadContent();
      }
 
  }));
}
  }    

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadContent(): void {
console.log('thiscontent', this.category);
  if(this.category==null) {
    this.category='home';
    this.section='';
}
    const params: any = {};
    params.category= this.category;
    params.section= this.section;
    params.lang = Globals.webSiteLang;
    this.subscription.add(this.httpSvc.get<ContentModel>(URL_CONTENT, params).subscribe(
      {
        next:
          (data) => {
      console.log('datacontent',data);
            //this.htmlContent= data.content;
            this.htmlContent  = this.sanitizer.bypassSecurityTrustHtml(data.content);

          },
        error:
          () => {
            this.messageService.add({ severity: 'error', summary: this.translocoService.translate('errorLoadingData'), detail: '' });
          }
      }
    ));

  }

  @HostListener('document:click', ['$event'])
  public handleClick(event: Event): void {
   if (event.target instanceof HTMLAnchorElement) {
     const element = event.target as HTMLAnchorElement;
     console.log('classname', element.className);
     if (element.className.includes('routerlink')) {
       event.preventDefault();
       const route = element?.getAttribute('href');
       
       if (route) {
        console.log('classname href ', route);
         //this.router.navigateByUrl(route);
         this.router.navigate([`/${route}`]);
         //this.router.navigate(['/${route}'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
       }
     }
   }
}
}

