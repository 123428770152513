import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Globals } from '../globals';
import { ShareUtils } from '../service/shareutils';
import { EventData } from '../service/event.class';
import { EventBusService } from '../service/event-bus,service';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { URL_USER_LANGUAGE_PREF } from '../service/urls';
import { HttpService } from '../service/http.service';
import { AuthService } from '../service/auth.service';
import { CommonModule } from '@angular/common';



@Component({
  standalone: true,
  imports: [DialogModule,TranslocoModule,RouterModule,FormsModule, CommonModule],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  selectedLanguage: SelectItem = { label: '', value: Globals.webSiteLang };
  languageList: SelectItem[] = [];
  regionList: SelectItem[] = [];
  selectedRegion: SelectItem = { label: '', value: '' };
  site = Globals.site;
  languageDialog=false;
  countryDialog=false;

  private subscription: Subscription = new Subscription();


  constructor(
    public translocoService: TranslocoService,
    private route: ActivatedRoute,
    private shareUtils: ShareUtils,
    private authService: AuthService,
    private httpSvc: HttpService,
    private router: Router,
    private eventBusService: EventBusService,
  ) {
    console.log(this.translocoService.translate('welcomeBack'));

  }

  ngOnInit(): void {
    this.subscription.add(this.translocoService.selectTranslate('translationLoaded').subscribe(value => {
      this.languageList = [
        { label: this.translocoService.translate('english'), value: 'en' },

        { label: this.translocoService.translate('french'), value: 'fr' }
      ];

      const langageIndex = this.shareUtils.getIndexFromValueOfSelectItemList(Globals.webSiteLang, this.languageList);
          this.selectedLanguage = this.languageList[langageIndex];

      this.regionList = this.shareUtils.getRegionList();
      
      
      this.selectedRegion = this.regionList[0];

      const regionIndex = this.shareUtils.getIndexFromValueOfSelectItemList(Globals.region, this.regionList);
          this.selectedRegion = this.regionList[regionIndex];


    }));
    console.log(this.translocoService.translate('welcomeBack'));
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
  goToSell(): void {
    this.router.navigate(['/volunteer'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }

  goToShop(): void {
    this.router.navigate(['/'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }

  goToOrg(): void {
    this.router.navigate(['/org'], { relativeTo: this.route, queryParams: null, replaceUrl: false, skipLocationChange: false });
  }
 
  public showCookieSettings(): void {
    const eventData:EventData= new EventData('cookieSettings',true);
    this.eventBusService.emit(eventData); 
    console.log('emmitedcookiesettings');
}

openLanguageDialog(): void {
  this.languageDialog=true;
}

openCountryDialog(): void {
  this.countryDialog=true;
}

setLanguage(lang:string): void {
  this.updatePref(lang);
  /*
    localStorage.setItem('language', lang);
    window.location.reload();
    */
}




updatePref(lang: string): void {
  if(!this.authService.isLoggedIn()) {
    localStorage.setItem('language',lang);
    window.location.reload();
    return;
  }


  const params: any = {};  
  params.language= lang;
  
  
  this.subscription.add(this.httpSvc.post(URL_USER_LANGUAGE_PREF, params).subscribe(
    {
      next:
        () => {
          localStorage.setItem('language',lang);
          window.location.reload();
    
        },
      error:
        (err) => {

          localStorage.setItem('language', this.selectedLanguage.value);
          window.location.reload();

        }
    }
  ));


}

  setRegion(region: string): void {
    localStorage.setItem('region', region);
    sessionStorage.removeItem('cartId');
    window.location.reload();
  }
}
