
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AsYouType, CountryCode, ParseError, parsePhoneNumber, PhoneNumber } from "libphonenumber-js";
import { Subscription } from "rxjs";
import { PhoneCountryCode } from "src/app/helpers/country-code";
import { PhoneCountry } from "../model/shared.model";
import { EventBusService } from "../service/event-bus,service";
import { CommonModule } from "@angular/common";
import { TranslocoModule } from "@ngneat/transloco";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { FormsModule } from "@angular/forms";





@Component({
  standalone: true,
  imports:[CommonModule,TranslocoModule,DropdownModule, InputTextModule, FormsModule],
  selector: 'app-intl-phone-input',
  templateUrl: './intl-phone-input.component.html',
  styleUrls: ['./intl-phone-input.component.scss'],
  providers: [
    PhoneCountryCode
  ]
})



export class IntlPhoneInputComponent implements OnInit, OnDestroy {

  @Input() value: PhoneNumber | undefined = undefined;
  @Input() fullWidth=false;
  @Input() focusInput=false;
  @Input() defaultIsoCountry='';
  
  @Output() phoneChange: EventEmitter<PhoneNumber> = new EventEmitter<PhoneNumber>();
  @Output() eventEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() e164Emitter: EventEmitter<string> = new EventEmitter<string>();


  @Input() inputPhoneInvalid = false;

  selectedCountry: PhoneCountry | undefined = {
    areaCodes: undefined,
    dialCode: '',
    flagClass: '',
    iso2: '',
    name: '',
    placeHolder: '',
    priority: 0,
  };

  preferredCountriesInDropDown: Array<PhoneCountry> = [];

  allCountries: Array<PhoneCountry> = [];


  countries = [];
  //selectedCountryISO: PhoneCountry;
  filterValue = '';
  srcPhoneUrl = '';
  preferredCountries: Array<string> = ['us', 'ca'];

  phone = '';

  private subscription: Subscription = new Subscription();
  
  constructor(private countryCodeData: PhoneCountryCode,
    private eventBusService: EventBusService
    )
  // If this is not set, ngx-bootstrap will try to use the bs3 CSS (which is not what we've embedded) and will
  // Add the wrong classes and such


  {

  }
  @ViewChild('phoneRef') phoneElement!: ElementRef;

  ngOnInit(): void {
    console.log('hello');
    this.init();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  init(): void {
    this.fetchCountryData();
    if(this.defaultIsoCountry!=null && this.defaultIsoCountry!='') {
      this.defaultIsoCountry= this.defaultIsoCountry.toLocaleLowerCase();
      console.log('defaultIsoCountry', this.defaultIsoCountry);
    if(!this.preferredCountries.includes(this.defaultIsoCountry))  {
      this.preferredCountries.unshift(this.defaultIsoCountry);
    } else {
      const tmp=this.preferredCountries[0];
      this.preferredCountries[0]= this.preferredCountries[1];
      this.preferredCountries[1]=tmp;

    }
  }
    if (this.preferredCountries.length) {
      this.updatePreferredCountries();
      this.selectedCountry = this.allCountries[0];
    }

    console.log('initvalue', this.value);
    this.setInitValue();
    this.subscription.add(this.eventBusService.on('intlPhoneInput', (value:boolean) => {
      console.log('intlPhoneInput ', value);
      if(value) {this.focusInputPhone();}

    }));
  }



  setInitValue(): void {
    if (this.value && this.value.country) {
      this.updateSelectedCountry(this.value.country);
      this.validatePhone(this.value.formatInternational());
    }

  }

  private updatePreferredCountries() {
    if (this.preferredCountries.length) {
      this.preferredCountriesInDropDown = [];
      this.preferredCountries.forEach((iso2) => {
        const preferredCountry = this.allCountries.filter((c) => {
          return c.iso2 === iso2;
        });

      if(preferredCountry[0]!=undefined) {
        this.preferredCountriesInDropDown.push(preferredCountry[0]);
  }
      });

      this.allCountries = this.preferredCountriesInDropDown.concat(this.allCountries);
      for (let i = 2; i < this.allCountries.length; i++) {
        if (this.allCountries[i].iso2 === this.preferredCountries[0] || this.allCountries[i].iso2 === this.preferredCountries[1]) {
          this.allCountries.splice(i, 1);
        }
    }
    }
  }

  protected fetchCountryData(): void {
    this.allCountries = [];

    this.countryCodeData.allCountries.forEach((c) => {
      const country: PhoneCountry = {
        name: c[0].toString(),
        iso2: c[1].toString(),
        dialCode: c[2].toString(),
        priority: +c[3] || 0,
        areaCodes: (c[4] as string[]) || undefined,
        flagClass: c[1].toString(),
        placeHolder: '',
      };


      this.allCountries.push(country);
    });

  }


  onPressvalidatePhone(): void {
    console.log('this.selectedCountry', this.selectedCountry);
    console.log('this.phone', this.phone);
    //console.log('this.phone', this.selectedCountry.iso2.toUpperCase() as CountryCode);

    this.validatePhone(this.phone);

  }

  focusInputPhone(): void {
    console.log('focusintlPhoneNow');
    setTimeout(() => { this.phoneElement.nativeElement.focus(); }, 100);
  }

  validatePhone(phone: string): void {
    let valid = false;
    try {
      const phoneNumber = parsePhoneNumber(phone, this.selectedCountry!.iso2.toUpperCase() as CountryCode);


      this.phoneChange.emit(phoneNumber);
      if (phoneNumber && phoneNumber.country) {


        console.log('phoneNumber', phoneNumber);

        this.updateSelectedCountry(phoneNumber.country);
        valid = phoneNumber.isValid();
        if (phone.length === 4 || phone.length === 6) {
          this.phone.slice(0, -1);
          //this.phonethis.commPrefForm.controls['phone'].setValue(phone);
        } else {
        //  
        if(valid) {
        this.phone = new AsYouType(this.selectedCountry!.iso2.toUpperCase() as CountryCode).input(phoneNumber.formatNational());
        }
        else {

        this.phone = new AsYouType(this.selectedCountry!.iso2.toUpperCase() as CountryCode).input(phone);
        }
        }
      }

      if (valid) {
        this.inputPhoneInvalid = false;
        this.srcPhoneUrl = 'assets/images/checkmark.png';
      }
      else {
        this.srcPhoneUrl = 'assets/images/crosscheck.png';
      }

    } catch (error) {
      if (error instanceof ParseError) {
        // Not a phone number, non-existent country, etc.
        console.log('nophone');
        this.phoneChange.emit(undefined);
        this.srcPhoneUrl = '';
      }


    }
  }

  emitEnter(event: any): void {
    event.preventDefault();
    this.eventEmitter.emit('enter');
  }


  onFocusInput(): void {
    this.eventEmitter.emit('focus');
  }

  private updateSelectedCountry(country: string) {

    console.log('country', country);
    if (country == null) return;
    this.selectedCountry = this.allCountries.find((c) => {
      return c.iso2.toLowerCase() === country.toLowerCase();
    });

  }

  
 

}