import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { SelectItem, TreeNode } from 'primeng/api';
import { Globals } from '../globals';
import { LangModel, RaiserCampaignModel, ViewCampaignModel } from '../model/shared.model';
import { EventBusService } from './event-bus,service';
import { EventData } from './event.class';
import { TranslocoService } from '@ngneat/transloco';



@Injectable({
  providedIn: 'root'
})

export class ShareUtils {


  constructor(
    private eventBusService: EventBusService,
    private translocoService: TranslocoService,

  ) { }

  randPassword(): string {

    const upper = 1;
    const lower = 1;
    const number = 1;
    const symbol = 1;
    const either = 6;

    const chars = [
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      "abcdefghijklmnopqrstuvwxyz",
      "0123456789",
      "!@#$%^&*(){}[]=<>/,.~?",
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*(){}[]=<>/,.~?" // either
    ];

    return [upper, lower, number, symbol, either].map(function (len, i) {
      return Array(len).fill(chars[i]).map(function (x: string) {
        return x[Math.floor(Math.random() * x.length)];
      }).join('');
    }).concat().join('').split('').sort(function () {
      return 0.5 - Math.random();
    }).join('');
  }

  public noWhitespaceValidator(control: UntypedFormControl): ValidationErrors | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


  public downLoadFile(data: Blob, type: string, fileName: string): void {

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  public b64toBlob(b64Data: string, contentType: string, sliceSize?: number): Blob {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


  addMonths(mydate: Date, months: number): Date {
    const date = new Date(mydate.getTime());
    const d = mydate.getDate();
    date.setMonth(date.getMonth() + months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  addDays(mydate: Date, days: number): Date {
    const date = new Date(mydate.getTime());
    date.setDate(date.getDate() + days);
    return date;
  }

  parseNumber(value: string): number {
    const locales = navigator.language;
    const example = Intl.NumberFormat(locales).format(1.1);
    const orig = value;
    const cleanPattern = new RegExp("[^-+0-9" + example.charAt(1) + "]", 'g');
    const cleaned = orig.replace(cleanPattern, '');
    const normalized = cleaned.replace(example.charAt(1), '.');
    return parseFloat(normalized);
  }

  smoothScrollToTop(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);

    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {

        window.scrollTo(0, pos - 100); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  /*
  removeNewlinesOnPaste(event: ClipboardEvent, control: AbstractControl, str: string ): void {
    if (!str) { str = event.clipboardData.getData('text/plain'); }
    str = str.replace(/\s{2,}/g, ' ');
    str = str.replace(/\t/g, ' ');
    str = str.toString().trim().replace(/(\r\n|\n|\r)/g, '');
    control.setValue(str);
    event.preventDefault();
  }
*/


  public emailValidator(control: UntypedFormControl): ValidationErrors | null {
    const isValid = RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$').test(control.value);
    return isValid ? null : { 'invalid': true };
  }

  public symbolValidator(control: UntypedFormControl): ValidationErrors | null {
    const isValid = RegExp(/[-!$%^&*()_+|~=#\\@`{}\[\]:";'<>?,.\/]/).test(control.value);
    return isValid ? null : { 'symbol': true };
  }

  public numberValidator(control: UntypedFormControl): ValidationErrors | null {
    const isValid = RegExp('(?=.*[0-9])').test(control.value);
    return isValid ? null : { 'number': true };
  }

  public uppercaseValidator(control: UntypedFormControl): ValidationErrors | null {
    const isValid = RegExp('(?=.*[A-Z])').test(control.value);
    return isValid ? null : { 'uppercase': true };
  }

  public lowercaseValidator(control: UntypedFormControl): ValidationErrors | null {
    const isValid = RegExp('(?=.*[a-z])').test(control.value);
    return isValid ? null : { 'lowercase': true };
  }

  public lengthValidator(control: UntypedFormControl): ValidationErrors | null {
    const isValid = RegExp('.{8,}').test(control.value);
    return isValid ? null : { 'length': true };
  }


  public matchFieldValidator(controlName: string, matchingControlName: string) {

    return (formGroup: UntypedFormGroup): void => {

      const control = formGroup.controls[controlName];

      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['match']) {

        return;

      }

      if (control.value !== matchingControl.value) {

        matchingControl.setErrors({ match: true });

      } else {

        matchingControl.setErrors(null);

      }

    }

  }



  removeSpaces(c: UntypedFormControl): ValidationErrors | null {
    if (c && c.value) {
      const removedSpaces = c.value.split(' ').join('');
      c.value !== removedSpaces && c.setValue(removedSpaces);
    }
    return null;
  }


  keyPressNumbers(maxLength: number, event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((event.target.selectionEnd - event.target.selectionStart) > 0) {
      return true;
    }

    if (charCode == 8 || charCode == 9 || charCode == 46 || charCode == 37 || charCode == 39 || charCode == 35 || charCode == 36 || charCode == 45 || charCode == 144
      || charCode == 27 || charCode == 10 ||

      ((event.keyCode == 65 || event.keyCode == 86 || event.keyCode == 67) && (event.ctrlKey === true || event.metaKey === true))
    ) {
      return true;
    } else if (((charCode > 47 && charCode < 58) || (charCode >= 96 && charCode <= 105)) && event.target.value.length < maxLength) {

      return true;

    }

    return false;
  }

  numberMobile(event: any): boolean {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
    return false;
  }

  selectNodes(tree: TreeNode[], checkedNodes: TreeNode[], data: string): void {

    // Iterate through each node of the tree and select nodes
    let count = tree.length;
    for (const node of tree) {
      console.log('node', node);
      // If the current nodes key is in the list of keys to select, or it's parent is selected then select this node as well
      if (data.includes(node.data) || (node.parent != undefined && checkedNodes.includes(node.parent))) {
        checkedNodes.push(node);
        count--;
      }

      // Look at the current node's children as well
      if (node.children)
        this.selectNodes(node.children, checkedNodes, data);
    }

    // Once all nodes of a tree are looked at for selection, see if only some nodes are selected and make this node partially selected
    if (tree.length > 0 && tree[0].parent) tree[0].parent.partialSelected = (count > 0 && count != tree.length);
  }

  convertToKeyOfLang(lang: string) {
    let selectedLangModel: keyof LangModel = 'en';

    if (lang == 'en') {
      selectedLangModel = 'en';
    } else if (lang == 'fr') {
      selectedLangModel = 'fr';
    }
    else if (lang == 'es') {
      selectedLangModel = 'es';
    }
    return selectedLangModel;
  }

  getLabelSelectItem(items: SelectItem[], value: string): string {
    for (let i = 0; i < items.length; i++) {
      if (items[i].value === value) {
        return items[i].label as string;
      }

    }

    return '';
  }

  removeCart(): void {
    this.eventBusService.emit(new EventData('itemsInCart', 0));
    sessionStorage.removeItem('cartId');
  }


  getStepFromIndex(index: number, stepList: SelectItem[]): string {
    const step = stepList[index].label;
    if (step == undefined) { return ''; }
    return step;
  }

  getIndexFromStep(step: string, stepList: SelectItem[]): number {
    for (let i = 0; i < stepList.length; i++) {
      console.log('value ', stepList[i].value);
      if (stepList[i].label == step) {
        return stepList[i].value;
      }
    }
    return -1;
  }

  getIndexFromValueOfSelectItemList(value: string, stepList: SelectItem[]): number {
    for (let i = 0; i < stepList.length; i++) {
      console.log('value ', stepList[i].value);
      if (stepList[i].value === value) {
        return i;
      }
    }
    return -1;
  }

  getConvertedPrice(price: number, base: string, quote: string): number {
    const rate = Globals.xrates[quote] as number;

    return price / rate;
  }


  getCurrencyFromRegion(region: string): string {
    let currency = 'CAD';

    switch (region) {
      case 'CA':
        currency = 'CAD';
        break;
      case 'US':
        currency = 'USD';
        break;
      case 'FR':
        currency = 'EUR';
        break;

    }
    return currency;
  }

  roundDecimal(value: number): number {
    return Math.round(Math.round(value * 1000) / 10) / 100;
  }


  setDefaultPhotoIfNone(data: ViewCampaignModel): ViewCampaignModel {

    if (!data['photo']) {
      data['photo'] = Globals.getEmptySellerFile();
    }
    return data;
  }
  setRaiserDefaultPhotoIfNone(data: RaiserCampaignModel): RaiserCampaignModel {

    if (!data['photo']) {
      data['photo'] = Globals.getEmptySellerFile();
    }
    return data;
  }

  getRegionList(): SelectItem[] {
    const regionList = [
      { label: this.translocoService.translate('canada'), value: 'CA' },
      //    { label: this.translocoService.translate('unitedStates'), value: 'US' },
      //        { label: this.translocoService.translate('france'), value: 'FR' }
    ];
    return regionList;
  }

  getCurrencyList(): SelectItem[] {
    const currencyList = [

      { label: this.translocoService.translate('canadianDollars'), value: 'CAD' },
      //{ label: this.translocoService.translate('org.usDollars'), value: 'USD' },
      //{ label: this.translocoService.translate('org.euro'), value: 'EUR' },
    ];
    return currencyList;
  }

  isMobile() {
    return window.innerWidth <= 1024;
}

}