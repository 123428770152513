<form [formGroup]="signInForm" (keydown.enter)="$event.preventDefault()">
    <div class="w-full" [hidden]="step!=='start'">
        <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">
                <ng-container *transloco="let t;">{{t('signIn')}}</ng-container>
            </div>
            <span class="text-600 font-medium line-height-3"><ng-container
                    *transloco="let t;">{{t('dontHaveAccountSignUp')}}</ng-container></span>
        </div>

        <div>
            <label for="email" class="block text-900 font-medium mb-2">Email</label>
            <input type="email" pInputText class="w-full mb-3" autocomplete="username" formControlName="email"
                (keydown.space)="$event.preventDefault();" (keyup.enter)="isEmailExist()">



            <span class="text-pink-500 text-sm" *ngIf="signInForm.get('email')?.invalid && signInForm.get('email')?.hasError('required') && 
                (signInForm.get('email')?.dirty  ||  signInForm.get('email')?.touched)">
                <ng-container *transloco="let t;">{{t('emailRequired')}}</ng-container>
            </span>
            <span class="text-pink-500 text-sm" *ngIf="signInForm.get('email')?.invalid && !signInForm.get('email')?.hasError('required') && signInForm.get('email')?.hasError('invalid') && 
                  (signInForm.get('email')?.dirty  ||  signInForm.get('email')?.touched)">
                <ng-container *transloco="let t;">{{t('emailAddressInvalid')}}</ng-container>
            </span>
            <span class="text-pink-500 text-sm" *ngIf="signInForm.get('email')?.invalid && !signInForm.get('email')?.hasError('required') && signInForm.get('email')?.hasError('googleIdentity') && 
            (signInForm.get('email')?.dirty  ||  signInForm.get('email')?.touched)">
                <ng-container *transloco="let t;">{{t('alreadyUseGoogle')}}</ng-container>
            </span>
            <span inert>
                <input type="password" pInputText class="data-hidden" formControlName="password" autocomplete="current-password">
            </span>
            <p-messages [(value)]="signInPageMsgs"></p-messages>

            <br />
            <button id='signInCtnBtn' pButton pRipple [label]="'continue' | transloco" class="w-full"
                (click)="isEmailExist()"></button>
        </div>
        <br />
        <div class="text-center mb-5">
            <div class="text-400 text-xl font-light mb-3">
                <ng-container *transloco="let t;">{{t('or')}}</ng-container>
            </div>

        </div>

        <div class="GoogleLoginButtonContainer">
            <div #gbtn id="gbtn"></div>
        </div>
        <div class="text-center mb-5">
            <!--
            <p-button styleClass="p-button-outlined w-full">
                <img alt="logo" src="assets/images/googleicon.png" style="width: 1.5rem" />
                <span class="ml-2 font-bold">
                    <ng-container *transloco="let t;">{{t('continueGoogle')}}</ng-container>
                </span>
            </p-button>
            -->

            <br /><br />
            <!--
            <p-button styleClass="p-button-outlined w-full">
                <img alt="logo" src="assets/images/appleicon.png" style="width: 1.5rem" />
                <span class="ml-2 font-bold">
                    <ng-container *transloco="let t;">{{t('continueApple')}}</ng-container>
                </span>
            </p-button>


            <br /><br />
            <p-button styleClass="p-button-outlined w-full">

                <img alt="logo" src="assets/images/f_logo_RGB-Blue_72.png" style="width: 1.5rem" />
                <span class="ml-2 font-bold">
                    <ng-container *transloco="let t;">{{t('continueFacebook')}}</ng-container>
                </span>

            </p-button>

            <br /><br />
        -->
        </div>
    </div>

    <div class="w-full" *ngIf="step==='signIn'">
        <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">
                <ng-container *transloco="let t;">{{t('signIn')}}</ng-container>
            </div>
            <span class="text-600 font-medium line-height-3">{{signInForm.value.email}}</span>

            <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="backToStart()">
                <ng-container *transloco="let t;">{{t('notYou')}}</ng-container>
            </a>

        </div>

        <div>
            <label for="password" class="block text-900 font-medium mb-2"><ng-container
                    *transloco="let t;">{{t('password')}}</ng-container></label>
            <span class="p-input-icon-right w-full mb-3">
                <input [type]="isPasswordFieldType? 'password' : 'text'" pInputText class="w-full" autocomplete="current-password"
                    formControlName="password" (keydown.enter)="signIn()">
                <i class="pi pi-eye" *ngIf="isPasswordFieldType" (click)="togglePasswordFieldType()"
                    [title]="'showPassword' | transloco"></i>
                <i class="pi pi-eye-slash" *ngIf="!isPasswordFieldType" (click)="togglePasswordFieldType()"
                    [title]="'hidePassword' | transloco"></i>

            </span>
            <span class="text-pink-500 text-sm" *ngIf="signInForm.get('password')?.invalid && signInForm.get('password')?.hasError('required') && 
                (signInForm.get('password')?.dirty  ||  signInForm.get('password')?.touched)">
                <ng-container *transloco="let t;">{{t('passwordRequired')}}</ng-container>
            </span>
            <div class="flex align-items-center justify-content-between mb-6">
                <div class="flex align-items-center">
                    <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="backToStart()">
                        <ng-container *transloco="let t;">{{t('back')}}</ng-container>
                    </a>
                </div>
                <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                    (click)="forgotPassword()">Forgot password?</a>
            </div>


            <p-messages [(value)]="signInPageMsgs"></p-messages>


            <button pButton pRipple label="Sign In" icon="pi pi-user" class="w-full" (click)="signIn()"></button>
        </div>
    </div>

    <div class="w-full" *ngIf="step==='2faAuthRequired'">
        <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">
                <ng-container *transloco="let t;">{{t('twoFactorAuthentication')}}</ng-container>
            </div>
            <span class="text-600 font-medium line-height-3">{{signInForm.value.email}}</span>

            <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="backToStart()">
                <ng-container *transloco="let t;">{{t('notYou')}}</ng-container>
            </a>

        </div>

        <div>
            <label for="passCode" class="block text-900 font-medium mb-2"><ng-container
                    *transloco="let t;">{{t('enterCodeFromAuthApp')}}</ng-container></label>
            <span class="p-input-icon-right w-full mb-3">
                <input type="text" pInputText class="w-full" formControlName="passcode" (keydown.enter)="verifyTOTP()">

            </span>
            <span class="text-pink-500 text-sm" *ngIf="signInForm.get('passcode')?.invalid && signInForm.get('passcode')?.hasError('required') && 
                (signInForm.get('passcode')?.dirty  ||  signInForm.get('passcode')?.touched)">
                <ng-container *transloco="let t;">{{t('passcodeRequired')}}</ng-container>
            </span>


            <div class="flex align-items-center justify-content-between mb-6 mt-3">
                <div class="flex align-items-center">
                    <p-checkbox [binary]="true" formControlName="rememberDevice"></p-checkbox>&nbsp;<ng-container
                        *transloco="let t;">{{t('rememberDevice')}}</ng-container>
                </div>
                <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                    (click)="switchToSMS()"><ng-container *transloco="let t;">{{t('tryAnotherWay')}}</ng-container></a>
            </div>


            <p-messages [(value)]="signInPageMsgs"></p-messages>


            <button pButton pRipple label="Sign In" icon="pi pi-user" class="w-full" (click)="verifyTOTP()"></button>
        </div>
    </div>

    <div class="w-full" *ngIf="step==='2faSMSRequired'">
        <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">
                <ng-container *transloco="let t;">{{t('twoFactorAuthentication')}}</ng-container>
            </div>
            <span class="text-600 font-medium line-height-3">{{signInForm.value.email}}</span>

            <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="backToStart()">
                <ng-container *transloco="let t;">{{t('notYou')}}</ng-container>
            </a>

        </div>

        <div>
            <label for="passCode" class="block text-900 font-medium mb-2"><ng-container
                    *transloco="let t;">{{t('codeHasBeenSentTo',{phone: phoneSuffix()})}}</ng-container></label>
            <span class="p-input-icon-right w-full mb-3">
                <input type="text" pInputText class="w-full" formControlName="passcode" (keydown.enter)="verifyTOTP()">

            </span>
            <span class="text-pink-500 text-sm" *ngIf="signInForm.get('passcode')?.invalid && signInForm.get('passcode')?.hasError('required') && 
                (signInForm.get('passcode')?.dirty  ||  signInForm.get('passcode')?.touched)">
                <ng-container *transloco="let t;">{{t('passcodeRequired')}}</ng-container>
            </span>

            <div class="flex align-items-center justify-content-between mb-6 mt-3">
                <div class="flex align-items-center">
                    <p-checkbox [binary]="true" formControlName="rememberDevice"></p-checkbox>&nbsp;<ng-container
                        *transloco="let t;">{{t('rememberDevice')}}</ng-container>
                </div>
                <!--
                <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                    (click)="forgotPassword()"><ng-container *transloco="let t;">{{t('tryAnotherWay')}}</ng-container></a>
                    -->
            </div>


            <p-messages [(value)]="signInPageMsgs"></p-messages>


            <button pButton pRipple label="Sign In" icon="pi pi-user" class="w-full" (click)="verifyTOTP()"></button>
        </div>
    </div>
    <div class="w-full" *ngIf="step==='signUp'">
        <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">
                <ng-container *transloco="let t;">{{t('signUp')}}</ng-container>
            </div>
            <span class="text-600 font-medium line-height-3">{{signInForm.value.email}}</span>

            <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="backToStart()">
                <ng-container *transloco="let t;">{{t('notYou')}}</ng-container>
            </a>

        </div>

        <div>
            <label for="firstName" class="block text-900 font-medium mb-2">
                <ng-container *transloco="let t;">{{t('firstName')}}</ng-container>
            </label>
            <input type="firstName" pInputText class="w-full mb-3" formControlName="firstName">

            <label for="lastName" class="block text-900 font-medium mb-2">
                <ng-container *transloco="let t;">{{t('lastName')}}</ng-container>
            </label>
            <input type="lastName" pInputText class="w-full mb-3" formControlName="lastName">


            <label for="password" class="block text-900 font-medium mb-2">
                <ng-container *transloco="let t;">{{t('password')}}</ng-container>
            </label>
            <span class="p-input-icon-right w-full mb-3">
                <input [type]="isPasswordFieldType? 'password' : 'text'" pInputText class="w-full"
                    formControlName="newPassword1" autocomplete="new-password">
                <i class="pi pi-eye" *ngIf="isPasswordFieldType" (click)="togglePasswordFieldType()"
                    [title]="'showPassword' | transloco"></i>
                <i class="pi pi-eye-slash" *ngIf="!isPasswordFieldType" (click)="togglePasswordFieldType()"
                    [title]="'hidePassword' | transloco"></i>

            </span>
            <!--
    <span *ngIf="signInForm.get('newPassword1')?.invalid  && (signInForm.get('newPassword1')?.dirty ||
    signInForm.get('newPassword1')?.touched) && (signInForm.get('newPassword1')?.hasError('length') ||
    signInForm.get('newPassword1')?.hasError('lowercase') ||
    signInForm.get('newPassword1')?.hasError('uppercase') ||
    signInForm.get('newPassword1')?.hasError('number') ||
    signInForm.get('newPassword1')?.hasError('symbol'))" class="text-red-500">
    <ng-container *transloco="let t;">{{t('passwordMusMeetRequirements')}}</ng-container>
</span> -->
            <div class="grid">
                <div
                    class="col-10 col-offset-1 sm:col-10 sm:col-offset-1 md:col-8 md:col-offset-2 lg:col-8 lg:col-offset-2 xl:col-8 xl:col-offset-2">
                    <ng-container *transloco="let t;">{{t('yourPasswordMustHave')}}</ng-container><br>
                    <ul>
                        <li>
                            <ng-container *transloco="let t;">{{t('atLeast8Characters')}}</ng-container>&nbsp;
                            <i class="pi pi-times text-red-500"
                                *ngIf="signInForm.get('newPassword1')?.hasError('length')"></i><i
                                class="pi pi-check text-green-500"
                                *ngIf="!signInForm.get('newPassword1')?.hasError('length')"></i>
                        </li>
                        <li>
                            <ng-container *transloco="let t;">{{t('aLowerCaseLetter')}}</ng-container>&nbsp; <i
                                class="pi pi-times text-red-500"
                                *ngIf="signInForm.get('newPassword1')?.hasError('lowercase')"></i><i
                                class="pi pi-check text-green-500"
                                *ngIf="!signInForm.get('newPassword1')?.hasError('lowercase')"></i>
                        </li>
                        <li>
                            <ng-container *transloco="let t;">{{t('aUpperCaseLetter')}}</ng-container>&nbsp; <i
                                class="pi pi-times text-red-500"
                                *ngIf="signInForm.get('newPassword1')?.hasError('uppercase')"></i><i
                                class="pi pi-check text-green-500"
                                *ngIf="!signInForm.get('newPassword1')?.hasError('uppercase')"></i>
                        </li>
                        <li>
                            <ng-container *transloco="let t;">{{t('aNumber')}}</ng-container>&nbsp; <i
                                class="pi pi-times text-red-500"
                                *ngIf="signInForm.get('newPassword1')?.hasError('number')"></i><i
                                class="pi pi-check text-green-500"
                                *ngIf="!signInForm.get('newPassword1')?.hasError('number')"></i>
                        </li>
                        <li>
                            <ng-container *transloco="let t;">{{t('aSymbol')}}</ng-container>&nbsp; <i
                                class="pi pi-times text-red-500"
                                *ngIf="signInForm.get('newPassword1')?.hasError('symbol')"></i><i
                                class="pi pi-check text-green-500"
                                *ngIf="!signInForm.get('newPassword1')?.hasError('symbol')"></i>
                        </li>
                    </ul>
                </div>
            </div>


            <p-messages [(value)]="signInPageMsgs"></p-messages>

            <ng-container *transloco="let t;">{{t('bySignUp')}}</ng-container>&nbsp;
            <span class="cursor-pointer link-color" (click)="termsDialog.set(true)"><ng-container *transloco="let t"
                    >{{ t('termsConditions')}}</ng-container></span>

            <ng-container *transloco="let t;"> {{t('and')}} </ng-container>

<span class="cursor-pointer link-color" (click)="privacyDialog.set(true)"><ng-container *transloco="let t" >
    {{ t('privacyPolicy')}}</ng-container></span>
            <br /><br />
            <button pButton pRipple [label]="'signUp' | transloco" class="w-full" (click)="signUp()"></button>
        </div>
     
    </div>

    <div class="w-full" *ngIf="step==='reset'">

        <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">
                <ng-container *transloco="let t;">{{t('resetPassword')}}</ng-container>
            </div>
            <span class="text-600 font-medium line-height-3">{{signInForm.value.email}}</span>

            <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="backToStart()">
                <ng-container *transloco="let t;">{{t('notYou')}}</ng-container>
            </a>

        </div>


        <div>




            <p-messages [(value)]="signInPageMsgs"></p-messages>

            <span *ngIf="resetLinkSent">
                <div class="text-900font-medium mb-3">
                    <ng-container *transloco="let t;">{{t('weSentPasswordResetLink', {email:signInForm.value.email
                        })}}</ng-container><br />
                    <ng-container *transloco="let t;">{{t('clickEmailLinkToReset')}}</ng-container>

                </div>

            </span>

            <span *ngIf="!resetLinkSent">
                <button pButton pRipple [label]="'sendResetLink' | transloco" class="w-full"
                    (click)="sendResetLink()"></button>
            </span>


        </div>
    </div>
    <div class="w-full" *ngIf="step==='sendEmailValidationToken'">

        <div class="text-center mb-5">
            <div class="text-900 text-2xl font-medium mb-3">
                <ng-container *transloco="let t;">{{t('emailValidationRequired')}}</ng-container>
            </div>
            <span *ngIf="!resetLinkSent">
                <div class="text-900 font-medium mb-3">
                    <ng-container *transloco="let t;">{{t('doWantSentEmailValidationLink')}}</ng-container>
                    <b>{{signInForm.value.email}}</b> ?


                </div>
            </span>
        </div>


        <div>




            <p-messages [(value)]="signInPageMsgs"></p-messages>

            <span *ngIf="resetLinkSent">

                <div class="text-900 font-medium mb-3">
                    <ng-container *transloco="let t;">{{t('weSentEmail')}}</ng-container>
                    <b>{{signInForm.value.email}}</b>.<br /><br />
                    <ng-container *transloco="let t;">{{t('clickOnLinkToValidateEmail')}}</ng-container>

                </div>
            </span>

            <span *ngIf="!resetLinkSent">
                <div class="grid">
                    <div class="col-6">
                        <button pButton pRipple [label]="'yes' | transloco" class="w-full"
                            (click)="resendChangeEmailToken()"></button>
                    </div>
                    <div class="col-6">
                        <button pButton pRipple [label]="'no' | transloco" class="w-full"
                            (click)="closeResendEmailValidationDialog()"></button>
                    </div>
                </div>
            </span>




        </div>
    </div>

    <div class="w-full" *ngIf="step==='verifyEmailCode'">

        <div class="grid">
            <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 text-center">

                    <div class="text-900 text-2xl font-medium mb-3">
                        <ng-container *transloco="let t;">{{t('emailValidation')}}</ng-container>
                    </div>

              <ng-container *transloco="let t;">{{t('codeHasBeenSentTo',{phone:signInForm.value.email})}}</ng-container>
              <br><br>
              
                <input #otpRef type="text" pInputText formControlName="emailCode" type="text" autocomplete="one-time-code"
                  inputmode="numeric" pattern="[0-9]*" (keyup)="shareUtils.numberMobile($event)"
                  (keydown)="otpValidation($event)" (keydown.enter)="verifyEmailCode()">
                <br>
                <span *ngIf="signInForm.get('emailCode')!.invalid  && signInForm.get('emailCode')!.hasError('invalid') && (signInForm.get('emailCode')!.dirty ||
                    signInForm.get('emailCode')!.touched)" class="text-red-600">
                  <ng-container *transloco="let t;">{{t('invalidCode')}}</ng-container>
                </span>
                <span class="text-pink-500 text-sm" *ngIf="signInForm.get('emailCode')?.invalid && signInForm.get('emailCode')?.hasError('required') && 
                (signInForm.get('emailCode')?.dirty  ||  signInForm.get('emailCode')?.touched)">
                <ng-container *transloco="let t;">{{t('required')}}</ng-container>
            </span>
            </div>
          </div>
        <div class="text-center mb-5" [ngClass]="{'hidden':resetLinkSent}">
            <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="resendChangeEmailToken()">
                <ng-container *transloco="let t;">{{t('resendEmail')}}</ng-container>
            </a>
        </div>
    
  

        <div>




            <p-messages [(value)]="signInPageMsgs"></p-messages>



            
                <div class="grid">
                       <div class="col-12">
                        <button pButton pRipple [label]="'verify' | transloco" class="w-full"
                            (click)="verifyEmailCode()"></button>
                    </div>
                </div>
            




        </div>
    </div>

    <div class="w-full" *ngIf="step==='newAccountEmailValidation'">

        <div class=" mb-5">
            <div class="text-900 text-2xl font-medium mb-3">
                <ng-container *transloco="let t;">{{t('emailValidation')}}</ng-container>
            </div>

        </div>


        <div>




            <p-messages [(value)]="signInPageMsgs"></p-messages>



            <div class="text-900 font-medium mb-3">
                <ng-container *transloco="let t;">{{t('weSentEmail')}}</ng-container>
                <b>{{signInForm.value.email}}</b>.<br /><br />
                <ng-container *transloco="let t;">{{t('clickOnLinkToValidateEmail')}}</ng-container>

            </div>










        </div>
    </div>
    <div class="w-full" *ngIf="step==='addPhone'">
        <app-user-edit-phone></app-user-edit-phone>
    </div>

</form>

<p-dialog [modal]="true" [closable]="true" closeIcon="pi pi-times" (onHide)="termsDialog.set(false)"
    [visible]="termsDialog()" [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden', 'max-width': '100vw','min-width': '80vw',
             'max-height': '99vh'}">
    <app-content category="terms"></app-content>
    <p-footer>
        <button pButton pRipple [label]="'close' | transloco" (click)="termsDialog.set(false)"></button>
    </p-footer>
</p-dialog>
<p-dialog [modal]="true" [closable]="true" closeIcon="pi pi-times" (onHide)="privacyDialog.set(false)"
    [visible]="privacyDialog()" [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden', 'max-width': '100vw','min-width': '80vw',
             'max-height': '99vh'}">
    <app-content category="privacy"></app-content>
    <p-footer>
        <button pButton pRipple [label]="'close' | transloco" (click)="privacyDialog.set(false)"></button>
    </p-footer>
</p-dialog>